import {RequestParams} from "./generated/http-client.ts";
import {setAuthenticationAccordingToValues} from "./apiTokenHelper.ts";

export interface ICookieTokenConfig {
    get useHttpOnlyCookie(): boolean;

    get token(): ISessionJwtTokens | null;

    withAuthentication(params: RequestParams): RequestParams;

    /**
     * If remember me is enabled, credentials : include should be sent in order for the browser to save the token
     * THIS METHOD SHOULD ONLY BE USED ON LOGIN AND CREATE USER ACTION (initial retrieval or JWT token)
     */
    setParamsOnLoginOnly(rememberMe: boolean, params: RequestParams): RequestParams;
}

/**
 * If no cookie used, (remember me = false)
 * this is the local information that keep jwt token info
 */
export interface ISessionJwtTokens {
    access_token: string;
    refresh_token: string;
}

class CookieTokenConfig implements ICookieTokenConfig {
    _useHttpOnlyCookie: boolean = false;
    _token: ISessionJwtTokens | null = null;

    get useHttpOnlyCookie(): boolean {
        return this._useHttpOnlyCookie;
    }

    get token(): ISessionJwtTokens | null {
        return this._token;
    }

    /**
     * Set params of the query according to the current authentication setup
     * @param params
     */
    withAuthentication(params: RequestParams): RequestParams {
        return setAuthenticationAccordingToValues(
            this._useHttpOnlyCookie,
            this._token?.access_token,
            params)
    }

    /**
     * If remember me is enabled, credentials : include should be sent in order for the browser to save the token
     * THIS METHOD SHOULD ONLY BE USED ON LOGIN AND CREATE USER ACTION (initial retrieval or JWT token)
     */
    setParamsOnLoginOnly(rememberMe: boolean, params: RequestParams): RequestParams {
        return rememberMe
            ? {credentials: "include", ...params}
            : params;
    }
}

export default class LoginHolder {
    private static readonly instance: CookieTokenConfig = new CookieTokenConfig();

    static get loginData(): ICookieTokenConfig {
        return LoginHolder.instance;
    }

    static UseExplicitToken(token: ISessionJwtTokens) {
        LoginHolder.instance._token = token;
        LoginHolder.instance._useHttpOnlyCookie = false;
    }

    static UseImplicitCookieToken() {
        LoginHolder.instance._token = null;
        LoginHolder.instance._useHttpOnlyCookie = true;
    }

    static UseNone() {
        LoginHolder.instance._token = null;
        LoginHolder.instance._useHttpOnlyCookie = false;
    }
}
