import {IServer, WrapperServer} from "./wrappers/WrapperServer.ts";
import {IAccount, WrapperAccount} from "./wrappers/WrapperAccount.ts";
import LoginHolder from "./LoginDataHolder.ts";

interface ProxyServer {
    server: IServer
    account: IAccount
}

export default class api {
    /**
     * The API main server is the initial server that is contacted to get the specific Api server.
     * The url is always the same (the VetCore central server).
     * Its purpose is to give the url of the apiProxyServer that serve as the local responder.
     *
     * It could be the same URL, but it allows to use a local proxy server in order to work independently
     * of the central server if requested by the establishment (in a local network for instance).
     *
     * In order to ease synchronisation, the central Server and the local server use the same API code and are able to answer the same queries
     */
    private static _centralServer: IServer | null = null;

    public static get centralServer() {
        if (!this._centralServer) {
            throw new Error("API Central Server not initialized");
        }
        return this._centralServer;
    }

    private static _server: IServer | null = null;
    /**
     * The proxy api status (proxy server is the real responder - see _mainServerStatus explanation above)
     */
    public static get server() {
        if (!this._server) {
            throw new Error("API Proxy not initialized");
        }
        return this._server;
    }

    private static _account: IAccount | null = null;
    /**
     * The proxy api status (proxy server is the real responder - see _mainServerStatus explanation above)
     */
    public static get account() {
        if (!this._account) {
            throw new Error("API Account not initialized");
        }
        return this._account;
    }

    public static initializeApi(mainServer: IServer, proxyServer: ProxyServer) {
        this._centralServer = mainServer
        this._server = proxyServer.server
        this._account = proxyServer.account
    }

    /**
     * returns true if the proxy server is different from the central one.
     * It means that the current configuration use a proxy server
     */
    public static useAProxyServer() {
        return this._server?.baseUrl != this._centralServer?.baseUrl;
    }
}

/**
 * Helper method that reinitialize APIs form a proxy url (and keep the main server Url
 * @param url
 * @constructor
 */
export function UpdateProxyServerFromUrl(url: string) {
    api.initializeApi(
        api.centralServer,
        {
            server: new WrapperServer(LoginHolder.loginData, {baseUrl: url}),
            account: new WrapperAccount(LoginHolder.loginData, {baseUrl: url}),
        }
    )
}

/**
 * Helper method that initialize APIs from a main server url and a proxy server url
 */
export function InitializeApiFromUrl(urlMainServer: string, urlProxy: string) {
    api.initializeApi(
        new WrapperServer(LoginHolder.loginData, {baseUrl: urlMainServer}),
        {
            server: new WrapperServer(LoginHolder.loginData, {baseUrl: urlProxy}),
            account: new WrapperAccount(LoginHolder.loginData, {baseUrl: urlProxy}),
        }
    )
}
