import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../stores/sliceNames.ts";
import ITabIndexedState, {getOrDefault, removeTabState, updateTabState} from "../../ui/tab_panels/tabIndexedState.ts";

interface IStatisticCounter {
    tabUuid: string;
    currentCount: number;
}

export interface IStatisticState {
    count: number;
}

const counterSelector = (state: ITabIndexedState<IStatisticState>, tabUuid: string) => getOrDefault(state, tabUuid).count;

const initialState: ITabIndexedState<IStatisticState> = {
    initialState: {count: 0},
    tabStates: []
};

const statisticsSlice = createSlice({
    name: SliceNames.STATISTICS,
    initialState: initialState,
    reducers: {
        // WARNING : Register this action in registeredRemoveTabsSlices in tabNotifierMiddleware.ts
        cleanStatisticsTabs : (state, action: PayloadAction<string>) => {
            removeTabState(state, action.payload);
        },
        incrementStatistics: (state, action: PayloadAction<IStatisticCounter>) => {
            updateTabState(state, action.payload.tabUuid, o => {
                o.count = action.payload.currentCount;
            });
        }
    },
    selectors: {
        counterStat: counterSelector,
    }
});

export const {incrementStatistics, cleanStatisticsTabs} = statisticsSlice.actions;
export const {counterStat} = statisticsSlice.selectors;

const statisticSliceReducer = statisticsSlice.reducer;
export default statisticSliceReducer;
