export class Constants {
    public static readonly MAX_EMAIL_SIZE: number = 100;
    public static readonly MAX_PASSWORD_SIZE: number = 200;
    public static readonly MAX_FIRST_NAME_SIZE: number = 80;
    public static readonly MAX_LAST_NAME_SIZE: number = 80;
    public static TABS_COUNT_THRESHOLD: number = 8;
    public static MIDDLE_BUTTON_MOUSE_NUMBER: number = 1;
    public static SPLITTER_MIN_SIZE_PX: number = 400;
    public static GLOBAL_TOOLTIP_ID = "global-tooltip-id";
}
