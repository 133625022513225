import {availableLocales, AvailableLocaleType, localStorageLocalizationKey, normalizeLocale} from "../../lib/navigationLocalized.ts";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import {FC, useEffect, useState} from "react";
import {DropdownCore} from "../controls/DropdownCore.tsx";
import {ColorProps} from "../colors/colorConfiguration.tsx";

const LocaleSwitcher: FC<ColorProps> = ({className, color}) => {
    const {t, i18n} = useTranslationFile(TranslationFile.Translation);
    const [selectedLocale, setSelectedLocale] = useState<AvailableLocaleType>();

    useEffect(() => {
        // Étape 2 : Initialiser `selectedLocale` avec la langue normalisée
        setSelectedLocale(normalizeLocale(i18n.language));
    }, [i18n.language]); // Utiliser i18n.language comme dépendance pour mettre à jour `selectedLocale` lors des changements de langue

    const updateLocaleAsync = async (newLocale: AvailableLocaleType) => {
        localStorage.setItem(localStorageLocalizationKey, newLocale);
        await i18n.changeLanguage(newLocale);
        setSelectedLocale(newLocale);
    };

    return (
        <DropdownCore
            color={color}
            className={className}
            onSelectionChanged={(o) => updateLocaleAsync(o)}
            translateItem={(o => t(`LocaleSwitcher.${o}`))}
            availableItems={availableLocales}
            selectedItem={selectedLocale}/>
    )
};


export default LocaleSwitcher;
