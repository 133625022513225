import {useCallback, useEffect, useState} from "react";

const useBreakpoint = (breakpoint: number) => {
    // Function to retrieve the initial state
    const getInitialState = useCallback(() =>
            typeof window !== "undefined"
                ? window.innerWidth >= breakpoint
                : false
        , [breakpoint]);

    const [isAbove, setIsAbove] = useState(getInitialState);

    useEffect(() => {
        // If you are not in a browser environment
        if (typeof window === "undefined") return;

        const mediaQuery = window.matchMedia(`(min-width: ${breakpoint}px)`);

        // debounce with timer
        let timeoutId: number;

        const updateTarget = (e: MediaQueryListEvent | MediaQueryList) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setIsAbove(e.matches);
            }, 200); // adjust delay
        };
        updateTarget(mediaQuery);

        mediaQuery.addEventListener("change", updateTarget);
        return () => {
            clearTimeout(timeoutId);
            mediaQuery.removeEventListener("change", updateTarget);
        };
    }, [breakpoint, isAbove]);

    return isAbove;
};

export default useBreakpoint;
