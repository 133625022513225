export interface IFormInputs {
    email: string;
    password: string;
    password_confirmation: string;
    first_name: string;
    last_name: string;
    remember_me: boolean;
}

export default class FormsHelper {

    /**
     * trim email first name and last name to correctly format expected data
     * @param data
     */
    static formatFormData(data: IFormInputs) : IFormInputs {
        return {
            password_confirmation: data.password_confirmation,
            email: data.email.trim(),
            password: data.password,
            first_name: data.first_name.trim(),
            last_name: data.last_name.trim(),
            remember_me: data.remember_me
        };
    }
}
