import React from "react";
import {HiBars3, HiMagnifyingGlass} from "react-icons/hi2";
import NotificationsAlert from "../../components/notifications/notificationsAlert.tsx";
import ProfilBadge from "../profil/ProfilBagde.tsx";
import {NotificationVcs} from "../../components/notifications/notificationVcs.tsx";
import DivCore from "../controls/divCore.tsx";
import EstablishmentSwitcher from "../settings/EstablishmentSwitcher.tsx";
import WaitingRoomIndicator from "./WaitingRoomIndicator.tsx";

interface TopHeaderBarProps {
    notifications : NotificationVcs[],
    onOpenSidebar: () => void,
}

const TopHeaderBar: React.FC<TopHeaderBarProps> = (
    {
        notifications,
        onOpenSidebar,
    }) => {
    return (
        <DivCore className="sticky top-0 z-30 flex h-16 shrink-0 items-center gap-x-4
        border-b border-theme-border dark:border-theme-border-dark
        text-theme-OnBg dark:text-theme-OnBg-dark
        px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" onClick={onOpenSidebar} className="-m-2.5 p-2.5 lg:hidden">
                <span className="sr-only">Open sidebar</span>
                <HiBars3 aria-hidden="true" className="h-6 w-6"/>
            </button>

            {/* Separator */}
            <div aria-hidden="true" className="h-6 w-px bg-theme-border dark:bg-theme-border-dark lg:hidden"/>

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <form action="#" method="GET" className="relative flex flex-1">
                    <label htmlFor="search-field" className="sr-only">
                        Search
                    </label>
                    <HiMagnifyingGlass
                        aria-hidden="true"
                        className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-theme-border-dark dark:text-theme-border"
                    />
                    <input
                        id="search-field"
                        name="search"
                        type="search"
                        placeholder="Search..."
                        className="block h-full w-full border-0 py-0 pl-8 pr-0
                         placeholder:text-theme-OnBg dark:placeholder:text-theme-OnBg-dark
                         focus:ring-0 sm:text-sm
                         bg-theme-bgBase dark:bg-theme-bgBase-dark"
                    />
                </form>

                <div className="flex items-center gap-x-4 lg:gap-x-6">
                    <EstablishmentSwitcher/>

                    <WaitingRoomIndicator/>

                    <NotificationsAlert notifications={notifications}/>
                    {/* Separator */}
                    <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-theme-border lg:dark:bg-theme-border-dark " aria-hidden="true"/>

                    {/* Profile dropdown */}
                    <ProfilBadge/>
                </div>
            </div>
        </DivCore>);
};


export default TopHeaderBar;
