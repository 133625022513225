import {Button} from "../../ui/catalyst_ui/button.tsx";
import {useAppDispatch, useAppSelector} from "../../stores/reduxHooks.ts";
import {counterStat, incrementStatistics} from "./statisticsSlice.ts";
import ITabDefinition from "../tabDefinition.ts";
import {FC} from "react";

const Statistics: FC<ITabDefinition> = ({tabUuid}) => {

    const dispatch = useAppDispatch();
    const counter = useAppSelector(o => counterStat(o, tabUuid));
    return (
        <>
            <header>REPORT ET STATISTIQUE (tab = {tabUuid})</header>
            <p>Compteur = {counter}</p>
            <Button onClick={() => dispatch(incrementStatistics(
                {
                    currentCount: (counter + 1) % 13,
                    tabUuid: tabUuid
                }))}>
                CLICK TO INCREMENT
            </Button>
            <div className="m-8 h-20 w-24 bg-pink-400 sm:bg-pink-900 md:border-purple-500 lg:bg-green-500 xl:bg-red-500 2xl:bg-amber-500">

            </div>

            <div className="@lg:underline">
                Container queries :
            </div>

            <div className="m-8 h-20 w-24 bg-pink-400 @sm:bg-pink-900 @md:border-purple-500 @lg:bg-green-500 @xl:bg-red-500
             @2xl:bg-amber-500 @3xl:bg-cyan-400 @4xl:bg-emerald-400
             @5xl:bg-yellow-200 @6xl:bg-slate-400  @7xl:bg-violet-800 ">
            </div>


            <div className="@lg:underline">
                @sm = pink, @md = purple, @lg = green, @xl = red, @2xl = amber
                @3xl = cyan, @4xl = emerald, @5xl = yellow, @6xl = slate, @7xl = dark-violet
            </div>

            <div className="my-4">
                @7xl == 2xl
            </div>
            <div className="my-4">
                @6xl == xl
            </div>
            <div className="my-4">
                @5xl == lg
            </div>
            <div className="my-4">
                @4xl == md
            </div>
            <div className="my-4">
                @3xl == sm
            </div>
        </>
    );
};
export default Statistics;

