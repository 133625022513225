import React from "react";
import {HiBell, HiBellAlert} from "react-icons/hi2";
import {getMaxNotificationLevel, getNotificationsColor, NotificationVcs} from "./notificationVcs.tsx";
import {useAppDispatch} from "../../stores/reduxHooks.ts";
import {addError, ErrorLevel} from "../../ui/errors/errorSlice.ts";

interface NotificationsAlertProps {
    notifications: NotificationVcs[];
}

const NotificationsAlert: React.FC<NotificationsAlertProps> = ({notifications}) => {
    const color = getNotificationsColor(getMaxNotificationLevel(notifications))
    const dispatch = useAppDispatch();
    const writeErrorTest = () => {
        dispatch(addError({code: 836, level: ErrorLevel.Major, messageTranslationKey: "error_code_title"}))
    }
    return (
        <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500 opacity-80 hover:opacity-100 relative">
            <span className="sr-only">View notifications</span>
            {notifications.length > 0
                ? <HiBellAlert className={`h-8 w-8 ${color} `} aria-hidden="true" onClick={writeErrorTest}/>
                : <HiBell className="h-8 w-8 text-theme-disabled dark:text-theme-disabled-dark" aria-hidden="true"/>}
        </button>
    );
}

export default NotificationsAlert;
