import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react";
import {HiChevronDown} from "react-icons/hi2";
import {HiOutlineUserCircle} from "react-icons/hi";
import React from "react";
import {IUserInfos, selectIsLoggedIn, selectUserInfo} from "../../pages/signin/signInSlice.ts";
import {useDispatch, useSelector} from "react-redux";
import {focusOrCreateSecondaryTab} from "../tab_panels/panelSlice.ts";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import {logOutAsync} from "../../api/apiUsers.ts";
import {addError, ErrorLevel} from "../errors/errorSlice.ts";
import {COLOR_BG_THEME_BGBASE} from "../colors/colorConfiguration.tsx";
import {DropdownDivider} from "../catalyst_ui/dropdown.tsx";
import {Avatar} from "../catalyst_ui/avatar.tsx";
import {Destinations} from "../navigation/destinations.ts";

const userNavigation = [
    Destinations.Account,
    Destinations.Settings,
];

const ProfilBadge: React.FC = () => {
    const userInfo = useSelector(selectUserInfo);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const {t} = useTranslationFile(TranslationFile.Navigation);
    const dispatch = useDispatch();

    const onLogOutRequested = async () => {
        try {
            await logOutAsync(dispatch); // login out will navigate to main screen
            console.log("log out succeed");
        } catch (error) {
            console.log(`log out error : ${error}`);
            dispatch(addError({
                code: 977,
                level: ErrorLevel.Warning,
                messageTranslationKey: "error_log_out"
            }));
        }
    };
    return (
        <Menu as="div" className="relative">
            <MenuButton className="-m-1.5 flex items-center p-1.5">
                <span className="sr-only">Open user menu</span>
                {isLoggedIn ?
                    <Avatar initials={getUserInitials(userInfo)} className="size-10 text-white font-semibold"
                            style={{backgroundColor: userInfo?.userColor}}/>
                    : <HiOutlineUserCircle className="h-10 w-10 text-theme-border dark:text-theme-border-dark" aria-hidden="true"/>
                }
                <span className="hidden lg:flex lg:items-center bg">
                    <span className="ml-4 text-sm font-semibold leading-6 text-theme-OnBg dark:text-theme-OnBg-dark
                     truncate max-w-48 xl:max-w-80 2xl:max-w-96" aria-hidden="true">
                        {userInfo?.userFirstName ?? "John"} {userInfo?.userLastName ?? "Doe"}
                    </span>
                    <HiChevronDown className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                </span>
            </MenuButton>
            <MenuItems
                transition
                className={`absolute right-0 z-10 mt-2.5 w-32 origin-top-right
                 ring-theme-border/50 
                 dark:ring-theme-border-dark/50 
                  rounded-md py-2 shadow-lg ring-1 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in
                  
                   ${COLOR_BG_THEME_BGBASE}`}
            >
                {userNavigation.map((item) => (
                    <MenuItem key={item.navigationKey}>
                        <button
                            onClick={() => dispatch(focusOrCreateSecondaryTab(item))}
                            className="w-full text-left px-3 py-1 font-semibold leading-6 block text-sm data-[focus]:bg-theme-hoverNeutral dark:data-[focus]:bg-theme-hoverNeutral-dark">
                            {t(item.titleTranslationKey)}
                        </button>
                    </MenuItem>
                ))}
                <DropdownDivider/>
                <MenuItem key="log_out">
                    <button
                        onClick={onLogOutRequested}
                        className="block w-full text-left px-3 py-1 text-sm font-bold leading-6
                        text-notification-error dark:text-notification-error-dark
                         data-[focus]:bg-theme-hoverNeutral dark:data-[focus]:bg-theme-hoverNeutral-dark">
                        {t("log_out")}
                    </button>
                </MenuItem>
            </MenuItems>
        </Menu>
    );
};
export default ProfilBadge;

export const getUserInitials = (userInfo: IUserInfos | null) => {
    return getUserInitialsFromFirstAndLastName(userInfo?.userFirstName, userInfo?.userLastName);
};

export const getUserInitialsFromFirstAndLastName = (userFirstName: string | undefined, userLastName: string | undefined) => {
    const firstNameInitial = userFirstName?.[0] ?? "";
    const lastNameInitial = userLastName?.[0] ?? "";
    return `${firstNameInitial}${lastNameInitial}`.toUpperCase();
};

