import React from "react";
import {FiAlertCircle} from "react-icons/fi";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import {ErrorLevel} from "./errorSlice.ts";

interface ErrorItemProps {
    messageTranslationKey: string;
    translationParams?: { key: string; value: string }[];
    errorCode: number;
    errorLevel: ErrorLevel;
}

const ErrorItem: React.FC<ErrorItemProps> = (
    {
        translationParams,
        messageTranslationKey,
        errorCode,
        errorLevel,
    }) => {

    const {t} = useTranslationFile(TranslationFile.Errors);
    return (
        <>
            <li className="flex justify-between gap-x-6 py-5">
                <div className="flex items-center min-w-0 gap-x-4">
                    <FiAlertCircle className="h-10 w-10 flex-none rounded-full text-red-400"/>
                    <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                            {t("error_code_title")} {errorCode}</p>
                        <p className="mt-1 mr-2 text-xs leading-5 text-gray-500">
                            {(translationParams !== undefined && translationParams.length !== 0)
                                ? t(messageTranslationKey, ConvertToDictionary(translationParams))
                                : t(messageTranslationKey)}
                        </p>
                    </div>
                </div>
                <div className="items-center mt-1 flex flex-row gap-x-1.5">
                    <ErrorLevelLabel errorLevel={errorLevel}/>
                </div>
            </li>
        </>
    );
};

function ConvertToDictionary(translationParams : { key: string; value: string }[]) {
    return translationParams?.reduce((acc, param) => {
        acc[param.key] = param.value;
        return acc;
    }, {} as Record<string, string>)
}


interface ErrorLevelLabelProps {
    errorLevel: ErrorLevel;
}

const ErrorLevelLabel: React.FC<ErrorLevelLabelProps> = ({errorLevel}) => {

    const {t} = useTranslationFile(TranslationFile.Errors);
    switch (errorLevel) {
        case ErrorLevel.Critical :
            return <>
                <div className="flex-none rounded-full bg-red-800/20 p-1">
                    <div className="h-1.5 w-1.5 rounded-full bg-red-800"/>
                </div>
                <p className="text-xs leading-5 text-gray-500">{t("critical_label")}</p>
            </>;
        case ErrorLevel.Major :
            return <>
                <div className="flex-none rounded-full bg-red-500/20 p-1">
                    <div className="h-1.5 w-1.5 rounded-full bg-red-500"/>
                </div>
                <p className="text-xs leading-5 text-gray-500">{t("major_label")}</p>
            </>;
        default :
            return <>
                <div className="flex-none rounded-full bg-yellow-500/20 p-1">
                    <div className="h-1.5 w-1.5 rounded-full bg-yellow-500"/>
                </div>
                <p className="text-xs leading-5 text-gray-500">{t("warning_label")}</p>
            </>;
    }
};

export default ErrorItem;
