import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../stores/sliceNames.ts";
import ITabIndexedState, {getOrDefault, removeTabState, updateTabState} from "../../ui/tab_panels/tabIndexedState.ts";

export interface IProductsCounter {
    tabUuid: string;
    currentCount: number;
}

export interface IProductsState {
    count: number;
}

const counterSelector = (state: ITabIndexedState<IProductsState>, tabUuid: string) => getOrDefault(state, tabUuid).count;

const initialState: ITabIndexedState<IProductsState> = {
    initialState: {count: 0},
    tabStates: []
};

const productsSlice = createSlice({
    name: SliceNames.PRODUCT,
    initialState: initialState,
    reducers: {
        // WARNING : Register this action in registeredRemoveTabsSlices in tabNotifierMiddleware.ts
        cleanProductTabs : (state, action: PayloadAction<string>) => {
            removeTabState(state, action.payload);
        },
        incrementProduct: (state, action: PayloadAction<IProductsCounter>) => {
            updateTabState(state, action.payload.tabUuid, o => ({
                ...o,
                count: action.payload.currentCount,
            }));
        }
    },
    selectors: {
        counterProduct: counterSelector,
    }
});

export const {incrementProduct, cleanProductTabs} = productsSlice.actions;
export const {counterProduct} = productsSlice.selectors;

const productsSliceReducer = productsSlice.reducer;
export default productsSliceReducer;
