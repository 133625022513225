import {FC} from "react";
import ITabDefinition from "../../tabDefinition.ts";
import {HeadingCore} from "../../../ui/controls/HeadingCore.tsx";
import ButtonCore from "../../../ui/controls/buttonCore.tsx";
import {removeTab} from "../../../ui/tab_panels/panelSlice.ts";
import {CiCircleCheck, CiCircleRemove} from "react-icons/ci";
import {COLOR_PANEL_KIND_PRIMARY_COLORS, COLOR_PANEL_KIND_SECONDARY_COLORS} from "../../../ui/colors/colorConfiguration.tsx";
import {useAppDispatch} from "../../../stores/reduxHooks.ts";
import {extractRouteParam, RouteParam} from "../../../routes.ts";
import {Operation} from "../../../lib/Operation.ts";

const AnimalRecord: FC<ITabDefinition> = ({panelKind, tabUuid, href}) => {
    const primaryColor = COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind];
    const secondaryColor = COLOR_PANEL_KIND_SECONDARY_COLORS[panelKind];
    const operation = extractRouteParam(href)
    const dispatch = useAppDispatch();
    return (
        <div className="flex flex-col h-full">
            <HeadingCore colorKind={primaryColor}>Nouvel animal</HeadingCore>
            {
                operation.get(RouteParam.Operation) === Operation.Add
                    ? <div className="bg-blue-400 flex flex-col flex-1"></div>
                    : <div className="bg-pink-400 flex flex-col flex-1"></div>
            }

            <div className="flex flex-row items-center justify-center gap-x-16 m-3">
                <ButtonCore className="flex flex-row"
                            onClick={() => {
                                dispatch(removeTab(tabUuid));
                            }}>
                    <CiCircleRemove className="h-6 w-6 @4xl:w-5 @4xl:h-5"/>
                    Annuler
                </ButtonCore>
                <ButtonCore className="flex flex-row"
                            onClick={() => {
                                // TODO SAVE
                                dispatch(removeTab(tabUuid));
                            }}
                            colorKind={secondaryColor}>
                    <CiCircleCheck className="h-6 w-6 @4xl:w-5 @4xl:h-5"/>
                    Valider
                </ButtonCore>
            </div>
        </div>
    );
};


export default AnimalRecord;
