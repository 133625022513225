import ITabDefinition from "../tabDefinition.ts";
import {FC} from "react";
import {HeadingCore} from "../../ui/controls/HeadingCore.tsx";
import {COLOR_PANEL_KIND_PRIMARY_COLORS} from "../../ui/colors/colorConfiguration.tsx";
import {useAppSelector} from "../../stores/reduxHooks.ts";
import {getWaitingCustomers} from "./waitingRoomSlice.ts";
import {DateAndTimeHelper} from "../../lib/helpers/DateAndTimeHelper.ts";
import {useTranslation} from "react-i18next";


const WaitingRoom: FC<ITabDefinition> = ({panelKind}) => {
    const primaryColor = COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind]
    const {i18n} = useTranslation();
    const use24hFormat = DateAndTimeHelper.uses24HourFormat(i18n.language)
    const waitingCustomers = useAppSelector(getWaitingCustomers);
    return (
        <>
            <div className="flex flex-col gap-3 ">
                <HeadingCore colorKind={primaryColor}>Salle d'attente</HeadingCore>
                <div className="flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                                        Client
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Animal
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Veterinaire
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Arrivée
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="bg-white">
                                {waitingCustomers.map((item) => (
                                    <tr key={item.customerUuid} className="even:bg-gray-50 hover:bg-mainPart-primaryLight">
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                            {item.name}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.animal}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.veterinary}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{DateAndTimeHelper.displayTime(item.arrival, use24hFormat)}</td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                            <a href="#" className="text-mainPart-primary100 hover:text-mainPart-primary50">
                                                Ouvre le dossier client
                                                <span className="sr-only">, {item.name}</span>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WaitingRoom;
