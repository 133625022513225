import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../stores/sliceNames.ts";
import {Time} from "../../lib/TypeUtils.ts";

export interface WaitingRoomCustomerPayload {
    name: string;
    animal: string;
    veterinary: string;
    customerUuid : string;
    arrival : Time;
    appointmentTime? : Time;
}

// the slice model of a waiting client
interface IWaitingRoomCustomer {
    name: string;
    animal: string;
    veterinary: string;
    customerUuid : string;
    arrival : Time;
    // appointment time may be undefined if no appointment was associated (urgency, ...)
    appointmentTime? : Time;
}

interface IWaitingRoomState {
    // list of client in the waiting room
    waitingCustomers: IWaitingRoomCustomer[];
}

const initialState: IWaitingRoomState = {
    waitingCustomers: [],
};


const waitingRoomSlice = createSlice({
    name: SliceNames.WAITING_ROOM,
    initialState: initialState,
    reducers: {
        addToWaitingRoom: (state, action: PayloadAction<WaitingRoomCustomerPayload>) => {
            const { customerUuid } = action.payload;
            // Vérifie si le client existe déjà
            const exists = state.waitingCustomers.some(o => o.customerUuid === customerUuid);

            if (!exists) {
                state.waitingCustomers.push(action.payload);
            }
        },
        removeFromWaitingRoom: (state, action: PayloadAction<string>) => {
            // TODO UNIT TESTS
            const customerUuid = action.payload;
            // Filtre uniquement si le client existe dans la liste
            state.waitingCustomers = state.waitingCustomers.filter(o => o.customerUuid !== customerUuid);
        },
    },
    selectors: {
        getWaitingCustomers: (state) => state.waitingCustomers,
    },
});

export const {addToWaitingRoom, removeFromWaitingRoom} = waitingRoomSlice.actions;
export const {getWaitingCustomers} = waitingRoomSlice.selectors;
const waitingRoomReducer = waitingRoomSlice.reducer;
export default waitingRoomReducer;
