import {combineReducers, UnknownAction} from "@reduxjs/toolkit";
import apiStatus from "../sagas/apiStatus/apiStatusSlice.ts";
import signIn, {logout} from "../pages/signin/signInSlice.ts";
import panels from "../ui/tab_panels/panelSlice.ts";
import statisticSliceReducer from "../pages/statistics/statisticsSlice.ts";
import planning from "../pages/planning/planningSlice.ts";
import errors from "../ui/errors/errorSlice.ts";
import account from "../pages/accounts/accountSlice.ts";
import hospitalization from "../pages/hospitalization/hospitalizationSlice.ts";
import products from "../pages/products/productsSlice.ts";
import financial from "../pages/financial/financialSlice.ts";
import help from "../pages/help/helpSlice.ts";
import settings from "../pages/settings/settingsSlice.ts";
import establishment from "../components/establishments/establishmentSlice.ts";
import waitingRoomReducer from "../pages/waiting_room/waitingRoomSlice.ts";
import appointmentSliceReducer from "../pages/appointments/appointmentSlice.ts";
import customersSliceReducer from "../pages/customers/dashboard/customerDashboardSlice.ts";
import animalRecordSliceReducer from "../pages/customers/animalRecord/animalRecordSlice.ts";
import customerRecordSliceReducer from "../pages/customers/customerRecord/customerRecordSlice.ts";


// redux persist that combine and wrap reducers
// THE NAME OF PARAMETER KEY (not the selectors type) need to match SliceNames string
const combinedReducer = combineReducers({
    apiStatus: apiStatus,
    signIn: signIn,
    panels: panels,
    statistics: statisticSliceReducer,
    planning: planning,
    errors: errors,
    account : account,
    hospitalization : hospitalization,
    products : products,
    financial : financial,
    customers_dashboard : customersSliceReducer,
    customer_record : customerRecordSliceReducer,
    animal_record : animalRecordSliceReducer,
    help : help,
    settings : settings,
    establishment : establishment,
    appointment : appointmentSliceReducer,
    waiting_room : waitingRoomReducer,
});
type ReducersRootState = ReturnType<typeof combinedReducer>

// we use a root reducer and THEN all combined reducer in order to
// be able to reset state on certains actions (like logout for instance)
export const rootReducer = (state : ReducersRootState | undefined, action: UnknownAction) => {
    // if logout, set the state as undefined to clear data
    if (logout.match(action)) {
        state = undefined;
    }
    return combinedReducer(state, action);
};

// if declared like this:
// export type VcsStoreType = ReturnType<typeof vcsStore.getState>
// using middleware leads to circular dependency as illustrated by Redux themselves (https://github.com/reduxjs/redux/issues/4267)
// instead, we should use the reducers combined like this:
export type RootState = ReturnType<typeof rootReducer>

