/*
Since these are actual variables, not types, it's important to define them in a separate file such as app/hooks.ts, not the store setup file.
This allows you to import them into any component file that needs to use the hooks, and avoids potential circular import dependency issues.
 */
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from "./rootState.ts";
import {AppDispatch} from "./appDispatch.ts";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
