import {PanelKind} from "./panelKind.ts";
import {IPanelPart, IPanelState, IPanelStateTabsArraySubset} from "./panelSlice.ts";
import {v4 as uuidv4} from "uuid";
import {last, lastOrDefault} from "../../lib/utilsVcs.ts";
import {Constants} from "../../lib/helpers/constants.ts";
import {Destinations} from "../navigation/destinations.ts";
import IDestination, {TabPolicy} from "../navigation/destinationHelper.ts";
import {extractRouteParam, RouteParam} from "../../routes.ts";
import {NavigationKey} from "../navigation/navigationKey.tsx";

/**
 * Create a new tab
 */
export function createNewTab(destination: IDestination): IPanelPart {
    return {
        id: uuidv4(),
        navigationKey: destination.navigationKey,
        isRootMenu: destination.isRootMenu,
        relatedHRef: destination.relatedHRef,
        titleTranslationKey: destination.titleTranslationKey,
        titleTranslationKeyParams: destination.titleTranslationKeyParams,
    };
}

export interface IPolicyApplicationResult {
    // tab id that need to become the right part active id or undefined if no change needs to be done
    rightPartActiveIdToSelect?: string;
    // tab id that need to become the left part active id or undefined if no change needs to be done
    leftPartActiveIdToSelect?: string;
    // the destination tab to create if a new tab should be created
    tabToCreate?: IDestination;
    // the destination tab id to replace with a new one that should be created using the destination
    tabToReplace?: { tabUuid: string, destination: IDestination };
}

export function focusOrCreateTab(state: IPanelState, panelKind: PanelKind, destination: IDestination) {
    // 1) APPLY the policy to know if we need to select an existing tab or create a new one :
    const policyResult = applyPolicy(state, destination, panelKind);

    // first we replace tab if needed
    if (policyResult.tabToReplace !== undefined) {
        replaceTab(state, policyResult.tabToReplace.tabUuid, policyResult.tabToReplace.destination);
    }

    // if we need to select/focus an existing left or right tab, do it immediately and return.
    if (policyResult.leftPartActiveIdToSelect) {
        state.leftPartActiveId = policyResult.leftPartActiveIdToSelect;
        return;
    } else if (policyResult.rightPartActiveIdToSelect) {
        state.rightPartActiveId = policyResult.rightPartActiveIdToSelect;
        return;
    } else if (policyResult.tabToCreate === undefined) {
        // if there is no right or left part NOR tab to create, it means that there is nothing to do
        return;
    }

    // else  we check tab limits before creating tabs
    if (isTabLimitReached(state.leftPartTabs, state.rightPartTabs)) {
        console.log(`trying to create a tab while having reach the tab limit [${Constants.TABS_COUNT_THRESHOLD}] is not allowed as it will lead to exceed this threshold`);
        return;
    }

    const createdTab = createNewTab(destination);

    if (panelKind == PanelKind.Left) {
        // FOR LEFT PRIMARY PART : simply add it in the left part
        state.leftPartActiveId = createdTab.id;
        state.leftPartTabs.push(createdTab);
    } else {
        // FOR RIGHT SECONDARY PART if compact mode:
        if (state.isCompactMode) {
            // in compact mode, it is not possible to add a tab on secondary right part are they are not displayed
            // BUT, when switching from compact to desktop mode, we still want them to appear on right
            // The answer is to add the new tab on right saved tabs and saved active right id tab BUT add it on main LEFT part
            state.leftPartActiveId = createdTab.id;
            state.leftPartTabs.push(createdTab);
            state.rightTabCompactSavedActiveId = createdTab.id;
            state.rightTabCompactSavedIds.push(createdTab.id);
        } else {
            // in desktop mode, simply add it on right as expected
            state.rightPartActiveId = createdTab.id;
            state.rightPartTabs.push(createdTab);
        }
    }
}

/**
 * Method that apply all policies depending on given destination.
 * <strong>The function return the result of the policy application but DO NOT ALTER THE STATE</strong>
 */
export function applyPolicy(state: IPanelStateTabsArraySubset, destination: IDestination, panelKind: PanelKind): IPolicyApplicationResult {

    switch (destination.policy) {
        case TabPolicy.Transient:
            return applyTransientPolicy(destination);

        case TabPolicy.SpecificMainMenu:
            return applySpecificMainMenuPolicy(state, destination, panelKind);

        case TabPolicy.Singleton:
            return applySingletonPolicy(state, destination);

        case TabPolicy.SingletonWithSameId:
            return applySingletonWithSameIdPolicy(destination, state);

        default:
            throw new Error(`Unsupported policy: ${destination.policy}`);
    }
}

function sameNavKeyAndSameId(part: IPanelPart, navKey: NavigationKey, id: string) {
    return part.navigationKey === navKey && extractRouteParam(part.relatedHRef).get(RouteParam.Id) === id;
}

/**
 * transient == create a new one each time
 * @param destination
 */
export function applyTransientPolicy(destination: IDestination): IPolicyApplicationResult {
    return {tabToCreate: destination};
}

/**
 * Singleton with same id policy == we first try to focus an existing tab with same navKey AND same id in href parameters.
 * If it is on any part (main or secondary) find it and focus.
 * Else create a new tab
 */
export function applySingletonWithSameIdPolicy(destination: IDestination, state: IPanelStateTabsArraySubset): IPolicyApplicationResult {
    const hrefParam = extractRouteParam(destination.relatedHRef);
    const relatedId = hrefParam.get(RouteParam.Id);
    if (!relatedId) {
        // if no related id, apply transient pattern
        return applyTransientPolicy(destination);
    }

    // Else we will search for a singleton BUT if we find one, we will only consider it the same if the id argument in href is the same
    const foundRight = state.rightPartTabs.find(o => sameNavKeyAndSameId(o, destination.navigationKey, relatedId));
    if (foundRight) {
        // found in left part : indicate it and returns
        return {rightPartActiveIdToSelect: foundRight.id};
    }
    const foundLeft = state.leftPartTabs.find(o => sameNavKeyAndSameId(o, destination.navigationKey, relatedId));
    if (foundLeft) {
        // found in left part : indicate it and returns
        return {leftPartActiveIdToSelect: foundLeft.id};
    }
    // not found == create a new one
    return {tabToCreate: destination};
}


/**
 * Transient policy for main menu ==  each time a tab is requested :
 *  - if the main displayed tab is a root main menu (like dashboard/): it navigates the main part
 *  - if the main displayed tab is a subMenu (like dashboard/animal) : it focuses an existing same tab if it exists ON MAIN LEFT PART ONLY, or create a new one and focus it
 * This Policy try to preserve specific tab while keeping a way to navigate without opening too much tabs
 */
export function applySpecificMainMenuPolicy(
    state: IPanelStateTabsArraySubset,
    destination: IDestination,
    targetPanelKind: PanelKind,
): IPolicyApplicationResult {
    if(targetPanelKind === PanelKind.Right) {
        // if the main menu target is on the secondary part (right) we create a new tab
        return {tabToCreate: destination};
    }

    // ElSE, IT MEANS THE TARGET PANEL IS THE MAIN LEFT PART
    const activeMainLeftTab = findActiveTab(state.leftPartTabs, state.leftPartActiveId);
    if (activeMainLeftTab !== undefined && activeMainLeftTab.isRootMenu) {
        // 1) IF it is a root menu, we replace the main current and if it is the same as current we do nothing
        if (activeMainLeftTab.relatedHRef === destination.relatedHRef) {
            // same as current == do nothing
            return {};
        }
        // replace current active
        return {tabToReplace: {tabUuid: state.leftPartActiveId, destination: destination}};
    }

    // 2) ELSE if the main (LEFT) display is NOT a root menu: it means that in any case, we should NOT replace the current nav tab (either open a new one or focus another one)
    if (destination.isRootMenu) {
        // IF destination is a root menu, we will try to find an equivalent root tab to focus on the MAIN (LEFT) part only
        for (const leftPart of state.leftPartTabs) {
            if (leftPart.isRootMenu && leftPart.navigationKey === destination.navigationKey) {
                // focus this one:
                return {leftPartActiveIdToSelect: leftPart.id};
            }
        }
        // if none has been found, create a new one with the case 3)
    }

    // 3) ELSE if destination is not a root menu ==> create a new tab
    return {tabToCreate: destination};
}

/**
 * Singleton policy == we first try to focus an existing tab with same navKey.
 * If it is on any part (main or secondary) find it and focus.
 * Else create a new tab
 */
export function applySingletonPolicy(state: IPanelStateTabsArraySubset, destination: IDestination): IPolicyApplicationResult {
    const foundRightTab = state.rightPartTabs.find(o => o.navigationKey === destination.navigationKey);
    if (foundRightTab) {
        // found in right part : indicate it and returns
        return {rightPartActiveIdToSelect: foundRightTab.id};
    }

    const foundLeftTab = state.leftPartTabs.find(o => o.navigationKey === destination.navigationKey);
    if (foundLeftTab) {
        // found in left part : indicate it and returns
        return {leftPartActiveIdToSelect: foundLeftTab.id};
    }
    // not found == create a new one
    return {tabToCreate: destination};
}

export function replaceTab(state: IPanelStateTabsArraySubset, tabUuid: string, destination: IDestination) {
    const tab = findTab(state, tabUuid);
    if (tab === undefined) {
        console.log(`an replacement of tab ${tabUuid} has been requested but it has not been found in any panel part`);
        return;
    }
    // update the tab :
    tab.current.navigationKey = destination.navigationKey;
    tab.current.relatedHRef = destination.relatedHRef;
    tab.current.titleTranslationKey = destination.titleTranslationKey;
    tab.current.titleTranslationKeyParams = destination.titleTranslationKeyParams;
}

export function findTab(state: IPanelStateTabsArraySubset, targetId: string): { current: IPanelPart, currentKind: PanelKind, index: number, isActive: boolean; } | undefined {
    const indexRight = state.rightPartTabs.findIndex(o => o.id === targetId);
    if (indexRight > -1) {
        return {current: state.rightPartTabs[indexRight], index: indexRight, currentKind: PanelKind.Right, isActive: state.rightPartActiveId === targetId};
    }
    const indexLeft = state.leftPartTabs.findIndex(o => o.id === targetId);
    if (indexLeft > -1) {
        return {current: state.leftPartTabs[indexLeft], index: indexLeft, currentKind: PanelKind.Left, isActive: state.leftPartActiveId === targetId};
    }
    return undefined;
}

export function cleanSavedRightIdIfNeeded(state: IPanelState, id: string) {
    // remove matching id from saved Ids
    state.rightTabCompactSavedIds = state.rightTabCompactSavedIds.filter(o => o !== id);
    if (state.rightTabCompactSavedActiveId === id) {
        // if the right rightTabCompactSavedActiveId ActiveId was the removed one,
        // assign the first of the list or undefined
        state.rightTabCompactSavedActiveId = state.rightTabCompactSavedIds[0];
    }
}

export function removeFromLeft(state: IPanelState, index: number, isActive: boolean) {
    state.leftPartTabs.splice(index, 1);

    // specific case for last left tab: CREATE A NEW DEFAULT ONE AND SET AS CURRENT
    if (state.leftPartTabs.length == 0) {
        const initialTab = createNewTab(Destinations.Planning);
        state.leftPartActiveId = initialTab.id;
        state.leftPartTabs.push(initialTab);
    } else if (isActive) {
        const isLastTab = index === state.leftPartTabs.length;
        // if last tab, select the last one
        if (isLastTab) {
            state.leftPartActiveId = last(state.leftPartTabs).id;
        } else {
            // else select the same index (== le tab that was on its right)
            state.leftPartActiveId = state.leftPartTabs[index].id;
        }
    }
}

export function removeFromRight(state: IPanelStateTabsArraySubset, index: number, isActive: boolean) {
    state.rightPartTabs.splice(index, 1);
    // and reset the active tab if needed:
    if (isActive) {
        const isLastTab = index === state.rightPartTabs.length;
        // if last tab, select the last one
        if (isLastTab) {
            state.rightPartActiveId = lastOrDefault(state.rightPartTabs)?.id;
        } else {
            // else select the same index (== le tab that was on its right)
            state.rightPartActiveId = state.rightPartTabs[index]?.id;
        }
    }
}

export function findActiveTab(tabs: IPanelPart[], activeTabId: string) {
    return tabs.find(o => o.id === activeTabId)!;
}

export function isTabLimitReached(leftPartTabs: IPanelPart[], rightPartTabs: IPanelPart[]) {
    return (leftPartTabs.length + rightPartTabs.length) >= Constants.TABS_COUNT_THRESHOLD;
}

/**
 * If the tab is the last on left AND if the threshold tab count is reached,
 * ==> WE DO NOT ALLOW THE MOVE as it will lead to creating another tab
 */
export function shouldDisableMoveToRight(leftPartTabs: IPanelPart[], rightPartTabs: IPanelPart[]) {
    // if the tab is the last on left AND if the threshold tab count is reached,
    // ==> WE DO NOT ALLOW THE MOVE as it will lead to creating another tab
    return leftPartTabs.length == 1 && isTabLimitReached(leftPartTabs, rightPartTabs);
}
