import {NavigationKey} from "./navigationKey.tsx";
import {Accounts_Path, Appointment_Path, CustomerDashboard_Path, concatenateHrefAndParameters, Financial_Path, Help_Path, Hospitalization_Path, Planning_Path, Products_Path, RouteParam, Settings_Path, Statistics_Path, WaitingRoom_Path, CustomerRecord_Path, AnimalRecord_Path} from "../../routes.ts";
import IDestination, {TabPolicy} from "./destinationHelper.ts";
import {Operation} from "../../lib/Operation.ts";

/**
 * List all available destinations and which data need to be provided
 * A destination could be either a const in which case there is no data
 * to provide or a function with provided data as parameters
 */
export class Destinations {
    // =========== SINGLETONS ===========
    public static readonly Account: IDestination = {
        isRootMenu : false,
        policy: TabPolicy.Singleton,
        navigationKey: NavigationKey.Account,
        relatedHRef: Accounts_Path,
        titleTranslationKey: "account"
    };
    public static readonly Settings: IDestination = {
        isRootMenu : false,
        policy: TabPolicy.Singleton,
        navigationKey: NavigationKey.Settings,
        relatedHRef: Settings_Path,
        titleTranslationKey: "settings"
    };
    public static readonly WaitingRoom: IDestination = {
        isRootMenu : false,
        policy: TabPolicy.Singleton,
        navigationKey: NavigationKey.WaitingRoom,
        relatedHRef: WaitingRoom_Path,
        titleTranslationKey: "waiting_room"
    };

    // =========== ROOT MENU ===========
    public static readonly Planning: IDestination = {
        isRootMenu : true,
        policy: TabPolicy.SpecificMainMenu,
        navigationKey: NavigationKey.Planning,
        relatedHRef: Planning_Path,
        titleTranslationKey: "planning"
    };
    public static readonly Hospitalization: IDestination = {
        isRootMenu : true,
        policy: TabPolicy.SpecificMainMenu,
        navigationKey: NavigationKey.Hospitalization,
        relatedHRef: Hospitalization_Path,
        titleTranslationKey: "hospitalization"
    };
    public static readonly Products: IDestination = {
        isRootMenu : true,
        policy: TabPolicy.SpecificMainMenu,
        navigationKey: NavigationKey.Products,
        relatedHRef: Products_Path,
        titleTranslationKey: "products"
    };
    public static readonly Financial: IDestination = {
        isRootMenu : true,
        policy: TabPolicy.SpecificMainMenu,
        navigationKey: NavigationKey.Financial,
        relatedHRef: Financial_Path,
        titleTranslationKey: "financial"
    };
    public static readonly Statistics: IDestination = {
        isRootMenu : true,
        policy: TabPolicy.SpecificMainMenu,
        navigationKey: NavigationKey.Statistics,
        relatedHRef: Statistics_Path,
        titleTranslationKey: "statistics"
    };
    public static readonly Help: IDestination = {
        isRootMenu : true,
        policy: TabPolicy.SpecificMainMenu,
        navigationKey: NavigationKey.Help,
        relatedHRef: Help_Path,
        titleTranslationKey: "help"
    };
    public static readonly CustomerDashboard: IDestination = {
        isRootMenu : true,
        policy: TabPolicy.SpecificMainMenu,
        navigationKey: NavigationKey.CustomersDashboard,
        relatedHRef: CustomerDashboard_Path,
        titleTranslationKey: "customers"
    };

    /**
     * A Visualization of given customer
     */
    public static CustomerRecord({customerId, customerDisplayName}: { customerId: string, customerDisplayName: string }): IDestination {
        return {
            isRootMenu : false,
            policy: TabPolicy.SingletonWithSameId,
            navigationKey: NavigationKey.CustomerRecord,
            relatedHRef: concatenateHrefAndParameters(CustomerRecord_Path,
                [RouteParam.Operation, Operation.Visualize],
                [RouteParam.Id, customerId]),
            titleTranslationKey: "customer_record",
            titleTranslationKeyParams: {ParamKey_customerName: customerDisplayName}
        };
    };

    public static NewCustomer(): IDestination {
        return {
            isRootMenu : false,
            policy: TabPolicy.SingletonWithSameId,
            navigationKey: NavigationKey.CustomerRecord,
            relatedHRef: concatenateHrefAndParameters(CustomerRecord_Path, [RouteParam.Operation, Operation.Add]),
            titleTranslationKey: "new_Customer"
        };
    };


    /**
     * A Visualization of given animal
     */
    public static AnimalRecord({animalUuid, animalName}: { animalUuid: string, animalName: string }): IDestination {
        return {
            isRootMenu : false,
            policy: TabPolicy.SingletonWithSameId,
            navigationKey: NavigationKey.AnimalRecord,
            relatedHRef: concatenateHrefAndParameters(AnimalRecord_Path,
                [RouteParam.Operation, Operation.Visualize],
                [RouteParam.Id, animalUuid]),
            titleTranslationKey: "animal_record",
            titleTranslationKeyParams: {ParamKey_animalName: animalName}
        };
    };

    public static NewAnimal(): IDestination {
        return {
            isRootMenu : false,
            policy: TabPolicy.SingletonWithSameId,
            navigationKey: NavigationKey.AnimalRecord,
            relatedHRef: concatenateHrefAndParameters(AnimalRecord_Path, [RouteParam.Operation, Operation.Add]),
            titleTranslationKey: "newAnimal"
        };
    };

    /**
     * A Creation Tab for a new appointment
     */
    public static NewAppointment(): IDestination {
        return {
            isRootMenu : false,
            policy: TabPolicy.Transient,
            navigationKey: NavigationKey.Appointment,
            relatedHRef: concatenateHrefAndParameters(Appointment_Path, [RouteParam.Operation, Operation.Add]),
            titleTranslationKey: "new_appointment"
        };
    };

    /**
     * A Visualization of given appointment
     */
    public static Appointment({appointmentId}: { appointmentId: string }): IDestination {
        return {
            isRootMenu : false,
            policy: TabPolicy.SingletonWithSameId,
            navigationKey: NavigationKey.Appointment,
            relatedHRef: concatenateHrefAndParameters(Appointment_Path,
                [RouteParam.Operation, Operation.Visualize],
                [RouteParam.Id, appointmentId]),
            titleTranslationKey: "appointment"
        };
    };

    /**
     * An Edition Tab of given appointment
     */
    public static EditAppointment({appointmentId}: { appointmentId: string }): IDestination {
        return {
            isRootMenu : false,
            policy: TabPolicy.SingletonWithSameId,
            navigationKey: NavigationKey.Appointment,
            relatedHRef: concatenateHrefAndParameters(Appointment_Path,
                [RouteParam.Operation, Operation.Edit],
                [RouteParam.Id, appointmentId]),
            titleTranslationKey: "edit_appointment"
        };
    };
}

