import { HTMLAttributes, FC } from "react";

const DivCore: FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
    return (
        <div
            {...props}
            className={
            ` bg-theme-bgBase dark:bg-theme-bgBase-dark 
            text-theme-OnBg dark:text-theme-OnBg-dark 
             ${props.className}`}>
            {props.children}
        </div>
    );
};

export const DivCoreContrast: FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
    return (
        <div
            {...props}
            className={
            `bg-theme-bgContrast dark:bg-theme-bgContrast-dark 
            text-theme-OnBg dark:text-theme-OnBg-dark 
             ${props.className}`}>
            {props.children}
        </div>
    );
};

export const DivCoreCard: FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
    return (
        <div
            {...props}
            className={
            `px-6 py-12 sm:rounded-lg sm:px-12 
             shadow dark:shadow-xl 
             dark:border-2 dark:border-opacity-35 dark:border-theme-border-dark 
             bg-theme-bgBase dark:bg-theme-bgBase-dark 
             text-theme-OnBg dark:text-theme-OnBg-dark 
             ${props.className}`}>
            {props.children}
        </div>
    );
};

export default DivCore;
