/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { JwtValidationResultDto, ProblemDetails, TokenApiClientCredentialDto } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Server<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Server
   * @name ApiProxyServerUrlList
   * @summary Request the proxy server depending on the current establishment id or, if null for user without associated establishment
   * @request GET:/Server/apiProxyServerUrl
   * @secure
   */
  apiProxyServerUrlList = (params: RequestParams = {}) =>
    this.request<string, ProblemDetails>({
      path: `/Server/apiProxyServerUrl`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Server
   * @name PingList
   * @summary Ping the Api to check presence
   * @request GET:/Server/ping
   * @secure
   */
  pingList = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/Server/ping`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Server
   * @name PingAuthenticatedList
   * @summary Ping the Api as Authenticated user to check presence and allow stress test
   * @request GET:/Server/pingAuthenticated
   * @secure
   */
  pingAuthenticatedList = (params: RequestParams = {}) =>
    this.request<void, ProblemDetails>({
      path: `/Server/pingAuthenticated`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Server
   * @name PingAuthenticatedWithEstablishmentIdList
   * @summary Ping the Api as Authenticated user for a specific establishmentId to check presence and allow stress test
   * @request GET:/Server/pingAuthenticatedWithEstablishmentId
   * @secure
   */
  pingAuthenticatedWithEstablishmentIdList = (params: RequestParams = {}) =>
    this.request<number, ProblemDetails>({
      path: `/Server/pingAuthenticatedWithEstablishmentId`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Server
   * @name GetCurrentUtcTimeList
   * @summary Get the server UTC TIME in ISO 8601 (YYYY-MM-DDThh:mm:ss) to ensure consistency between client time and server time
   * @request GET:/Server/GetCurrentUtcTime
   * @secure
   */
  getCurrentUtcTimeList = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/Server/GetCurrentUtcTime`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Server
   * @name GetbackendapitokenList
   * @summary Retrieve a backend JWT token to identify as the backend from keycloak perspective: ONLY SUPER ADMIN should be able to do it
   * @request GET:/Server/getbackendapitoken
   * @secure
   */
  getbackendapitokenList = (params: RequestParams = {}) =>
    this.request<TokenApiClientCredentialDto, ProblemDetails | void>({
      path: `/Server/getbackendapitoken`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Server
   * @name ValidatejwttokenCreate
   * @summary Follow a validation process to check why a token is considered as invalid
   * @request POST:/Server/validatejwttoken
   * @secure
   */
  validatejwttokenCreate = (data: string, params: RequestParams = {}) =>
    this.request<JwtValidationResultDto, ProblemDetails | void>({
      path: `/Server/validatejwttoken`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
