import {Frequency, SubscriptionType} from "./subscriptionsInfos.ts";
import {Field, Label} from "../../ui/catalyst_ui/fieldset.tsx";
import {Input} from "../../ui/catalyst_ui/input.tsx";
import {SubmitHandler, useForm, UseFormRegister} from "react-hook-form";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";

interface IBillingDetails {
    company_name: string;
    siret_number: string;
    billing_address_street: string;
    billing_address_city: string;
    billing_address_zipcode: string;
}

interface IFormInputs extends IBillingDetails {
    email: string;
    password: string;
    password_confirmation: string;
    first_name: string;
    last_name: string;
    remember_me: boolean;
}

export function SubscriptionDetails() {
    // url parameters:
    const frequency = Frequency.Monthly;
    const subscription = SubscriptionType.Premium;
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const {t} = useTranslationFile(TranslationFile.SignIn);

    // react-hook-form :
    const {register, handleSubmit} = useForm<IFormInputs>({
        mode: "onChange"
    });
    const navigateBack = () => navigate(-1);

    const [isLoading, setIsLoading] = useState(false);

    const onCreateUserSubmitted: SubmitHandler<IFormInputs> = async (_) => {
        setIsLoading(true);
        try {
           // do nothing
        } catch {
            setErrorMessage(t("sign_in_error_message"));
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <form className="w-full md:max-w-lg lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl mx-auto"
              onSubmit={handleSubmit(onCreateUserSubmitted)}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="font-semibold leading-7 text-gray-900 text-2xl sm:text-3xl ">Personal Information</h2>

                    <div className="overflow-hidden rounded-lg bg-white shadow my-8">
                        <div className="px-4 py-5 sm:p-6">
                            <p className="mt-1 text-lg leading-6 text-gray-600">Abonnement: {subscription}</p>
                            <p className="mt-1 text-lg leading-6 text-gray-600">fréquence de paiement: {frequency}</p>

                        </div>
                    </div>
                </div>

                <BillingDetails isLoading={isLoading} register={register}/>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm font-semibold leading-6 text-gray-900"
                        onClick={navigateBack}>
                    Cancel
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-amber-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                >
                    Validate
                </button>
            </div>
            {errorMessage && <h2 className="mt-6 text-center text-xl font-bold leading-9 tracking-tight text-notification-error dark:text-notification-error-dark">
                {errorMessage}
            </h2>}
        </form>
    );
}

interface BillingDetailsProps {
    isLoading: boolean;
    register: UseFormRegister<IFormInputs>;
}

function BillingDetails({isLoading, register}: BillingDetailsProps) {
    return <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">Subscription Information</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">You could add any number of establishment with your subscription. They will be configured at startup</p>

        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <div className="col-span-full">
                <Field className="mb-4" disabled={isLoading}>
                    <Label>Dénomination sociale</Label>
                    <Input type="text" {...register("company_name", {required: true})} />
                </Field>

                <Field className="mb-4" disabled={isLoading}>
                    {/* not mandatory*/}
                    <Label>SIRET</Label>
                    <Input type="text" {...register("siret_number")} />
                </Field>
            </div>

            <div className="sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                    Country
                </label>
                <div className="mt-2 col-span-full">
                    <select
                        disabled={isLoading}
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                        <option>France</option>
                        <option>Belgium</option>
                        <option>Great Britain</option>
                        <option>United States</option>
                        <option>Canada</option>
                    </select>
                </div>
            </div>

            <div className="col-span-full">
                <Field className="mb-4">
                    <Label>Adresse de facturation</Label>
                    <Input type="text" {...register("billing_address_street", {required: true})} />
                </Field>
            </div>

            <div className="sm:col-span-4 sm:col-start-1">
                <Field className="mb-4">
                    <Label>Ville</Label>
                    <Input type="text" {...register("billing_address_city", {required: true})} />
                </Field>
            </div>

            <div className="sm:col-span-2">
                <Field className="mb-4">
                    <Label>ZIP / Postal code</Label>
                    <Input type="text" {...register("billing_address_zipcode", {required: true})} />
                </Field>
            </div>
        </div>
    </div>;
}
