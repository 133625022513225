import {COLOR_TAB_TEXT_AND_TEXT_HOVER, COLOR_TEXT_ON_BG, colorOrDefault, TabColors} from "../colors/colorConfiguration.tsx";
import {forwardRef, InputHTMLAttributes} from "react";

interface CheckboxInputCoreProps extends InputHTMLAttributes<HTMLInputElement> {
    tabColor?: TabColors;
    text: string;
}

const COLOR_CLASSES_CHECKBOX: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]:
    "border-theme-border text-mainPart-primary100 focus:ring-mainPart-primaryLight hover:text-mainPart-primary50 " +
    "dark:border-theme-border-dark dark:text-mainPart-primary100-dark dark:focus:ring-mainPart-primaryLight-dark dark:hover:text-mainPart-primary50-dark",
    [TabColors.mainPart_secondaryColor]:
    "border-theme-border text-mainPart-secondary100 focus:ring-mainPart-secondaryLight hover:text-mainPart-secondary50 " +
    "dark:border-theme-border-dark dark:text-mainPart-secondary100-dark dark:focus:ring-mainPart-secondaryLight-dark dark:hover:text-mainPart-secondary50-dark",
    [TabColors.secondaryPart_primaryColor]:
    "border-theme-border text-secondaryPart-primary100 focus:ring-secondaryPart-primaryLight hover:text-secondaryPart-primary50 " +
    "dark:border-theme-border-dark dark:text-secondaryPart-primary100-dark dark:focus:ring-secondaryPart-primaryLight-dark dark:hover:text-secondaryPart-primary50-dark",
    [TabColors.secondaryPart_secondaryColor]:
    "border-theme-border text-secondaryPart-secondary100 focus:ring-secondaryPart-secondaryLight hover:text-secondaryPart-secondary50 " +
    "dark:border-theme-border-dark dark:text-secondaryPart-secondary100-dark dark:focus:ring-secondaryPart-secondaryLight-dark dark:hover:text-secondaryPart-secondary50-dark"
} as const;

const COLOR_CHECKBOXES_BLACK_AND_WHITE = "border-theme-border dark:border-theme-border-dark" +
    "text-theme-bgBase dark:text-theme-bgBase-dark " +
    "focus:ring-theme-OnBgVariant dark:focus:ring-theme-OnBgVariant-dark " +
    "hover:text-theme-hoverNeutral dark:hover:text-theme-hoverNeutral-dark ";

const CheckboxInputCore = forwardRef<HTMLInputElement, CheckboxInputCoreProps>(
    ({tabColor, text, ...props}, ref) => {

        const colorClasses = colorOrDefault(tabColor, COLOR_CLASSES_CHECKBOX, COLOR_CHECKBOXES_BLACK_AND_WHITE);
        const textColorClasses = colorOrDefault(tabColor, COLOR_TAB_TEXT_AND_TEXT_HOVER, COLOR_TEXT_ON_BG);
        return (
            <div className="flex items-center text-theme-OnBg dark:text-theme-bgBase-dark ">
                <input
                    type="checkbox"
                    id={props.id}
                    {...props}
                    className={`h-4 w-4 rounded ${colorClasses} ${props.className} ring-0`}
                    ref={ref}  // give ref here in order for react-hook-form to be able to use register
                />
                <label
                    htmlFor={props.id}
                    className={`ml-3 block text-sm leading-6 ${textColorClasses} ${props.className}`}
                >
                    {text}
                </label>
            </div>
        );
    }
);

export default CheckboxInputCore;
