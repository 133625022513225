import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../stores/sliceNames.ts";
import {Establishment, EstablishmentLocation} from "../../models/establishment.ts";

const establishmentTest: Establishment = {
    establishmentId: -1,
    establishmentName: "testEstablishment",
    isTestEstablishment: true,
    locations: [
        {locationName: "testEstablishmentLocation1", locationId: 0},
        {locationName: "testEstablishmentLocation2", locationId: 1}]
};

interface IEstablishmentState {
    currentEstablishment: Establishment;
    availableEstablishments: Establishment[];
    currentLocation: EstablishmentLocation;
}

const initialState: IEstablishmentState = {
    currentEstablishment: establishmentTest,
    availableEstablishments: [establishmentTest],
    currentLocation: establishmentTest.locations[0],
};

export interface INewEstablishment {
    establishmentId: number;
    locationId: number;
}

const establishmentSlice = createSlice({
    name: SliceNames.ESTABLISHMENT,
    initialState: initialState,
    reducers: {
        // update the establishment location but do NOT change the establishment itself
        changeLocation: (state, action: PayloadAction<number>) => {
            const newLocation = state
                .currentEstablishment
                .locations
                .find(o => o.locationId == action.payload);
            if (!newLocation) {
                console.error(`try to select unavailable location id : ${action.payload}`);
            } else {
                state.currentLocation = newLocation;
            }
        },
        // update the current establishment and related location (and trigger a reset of cache)
        changeCurrentEstablishment: (state, action: PayloadAction<INewEstablishment>) => {
            const establishment = state
                .availableEstablishments
                .find(o => o.establishmentId == action.payload.establishmentId);

            if (!establishment) {
                console.error(`try to select unavailable establishment id : ${action.payload}`);
            } else {
                const location = establishment
                    .locations
                    .find(o => o.locationId == action.payload.locationId);
                if (!location) {
                    console.error(`try to select unavailable location id : ${action.payload}`);
                } else {
                    state.currentEstablishment = establishment;
                    state.currentLocation = location;
                }
            }
        },
    },
    selectors: {
        selectCurrentLocation: (state) => state.currentLocation,
        selectCurrentEstablishment: (state) => state.currentEstablishment,
        selectAvailableEstablishments: (state) => state.availableEstablishments,
    },
});

export const {changeLocation, changeCurrentEstablishment} = establishmentSlice.actions;
export const {selectCurrentLocation, selectCurrentEstablishment, selectAvailableEstablishments} = establishmentSlice.selectors;
const establishmentReducer = establishmentSlice.reducer;
export default establishmentReducer;
