import {useAppDispatch, useAppSelector} from "../../stores/reduxHooks.ts";
import {pingServer} from "../../sagas/apiStatus/apiStatusSagas.ts";
import {useEffect} from "react";


export default function Hospitalization() {

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(pingServer());
    }, [dispatch]);
    const isConnected = useAppSelector(state => state.apiStatus.isConnectedToBackend);
    return (
        <>
            <header>HOSPITALIZATION</header>
            {isConnected
                ? <div>CONNECTED TO BACKEND</div>
                : <div>NOT CONNECTED!</div>}
        </>
    )
}
