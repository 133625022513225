export const availableLocales = ["fr", "en"] as const;
export const localStorageLocalizationKey = "localizationVcsKey"; // the string key used to retrieve local storage selected localization
export type AvailableLocaleType = typeof availableLocales[number]; // create a type that is only among local defined above

export const defaultFallBackLocale = availableLocales[0];

export default function GetUserSavedLocaleIfAvailable(): string | null {
    // return the saved local, if available, if not, we will fall back to
    // i18next-browser-languagedetector and in last case, the default language
    // defined in i18n.js
    return localStorage.getItem(localStorageLocalizationKey);
}

// Normalize language (extract main code, ex: 'fr' de 'fr-FR')
export const normalizeLocale = (locale: string): AvailableLocaleType => {
    return availableLocales.find((o) => locale.startsWith(o)) || defaultFallBackLocale;
};
