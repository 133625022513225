import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../../stores/sliceNames.ts";
import ITabIndexedState, {getOrDefault, removeTabState, updateTabState} from "../../../ui/tab_panels/tabIndexedState.ts";
import {DashboardMode} from "./customersDashboardHeader.tsx";

export interface ICustomersDashboardState {
    mode: DashboardMode;
}

const selectMode = (state: ITabIndexedState<ICustomersDashboardState>, tabUuid: string) => getOrDefault(state, tabUuid).mode;

const initialState: ITabIndexedState<ICustomersDashboardState> = {
    initialState: {mode: DashboardMode.Customers},
    tabStates: []
};

const customersDashboardSlice = createSlice({
    name: SliceNames.CUSTOMERS_DASHBOARD,
    initialState: initialState,
    reducers: {
        // WARNING : Register this action in registeredRemoveTabsSlices in tabNotifierMiddleware.ts
        cleanCustomersDashboardTabs: (state, action: PayloadAction<string>) => {
            removeTabState(state, action.payload);
        },
        changeDashboardMode: (state, action: PayloadAction<{ tabUuid: string; selectedMode: DashboardMode }>) => {
            updateTabState(state, action.payload.tabUuid, o => {
                o.mode = action.payload.selectedMode;
            });
        }
    },
    selectors: {
        selectedMode: selectMode,
    }
});

export const {changeDashboardMode, cleanCustomersDashboardTabs} = customersDashboardSlice.actions;
export const {selectedMode} = customersDashboardSlice.selectors;

const customersDashboardSliceReducer = customersDashboardSlice.reducer;
export default customersDashboardSliceReducer;
