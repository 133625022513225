import {useState} from "react";
import {Radio, RadioGroup} from "@headlessui/react";
import {HiArrowLeft, HiCheck} from "react-icons/hi2";
import {classNames} from "../../lib/utilsVcs.ts";
import {Frequency, getAmount, GetFrequencies, GetFrequenciesInfo, GetSubscriptionInfo} from "./subscriptionsInfos.ts";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import {Trans} from "react-i18next";
import DivCore from "../../ui/controls/divCore.tsx";
import ButtonCore from "../../ui/controls/buttonCore.tsx";
import {TabColors} from "../../ui/colors/colorConfiguration.tsx";
import {motion} from "framer-motion";
import LinkCore from "../../ui/controls/LinkCore.tsx";
import {useNavigate} from "react-router-dom";

export default function Subscription() {
    const frequencies = GetFrequencies();
    const frequenciesInfos = GetFrequenciesInfo();
    const [frequency, setFrequency] = useState(Frequency.Monthly);
    const tiers = GetSubscriptionInfo();
    const [selectedOffer, setSelectedOffer] = useState(tiers[1].type);
    const selectedFrequencyInfo = frequenciesInfos.get(frequency)!;
    const {t} = useTranslationFile(TranslationFile.Subscription);
    const navigate = useNavigate();
    const navigateBack = () => navigate(-1);

    return (
        <DivCore className="flex flex-col py-10 sm:py-10 justify-between h-full">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-base font-semibold leading-7 text-mainPart-primary100 dark:text-mainPart-primary100-dark">{t("subscription")}</h2>
                    <p className="mt-2 font-bold tracking-tight text-theme-OnBg dark:text-theme-OnBg-dark
                      text-xl sm:text-2xl lg:text-4xl">
                        <Trans
                            i18nKey="subscription_title"
                            components={{b: <strong/>}}
                            t={t}
                        />
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-md lg:text-lg leading-8">
                    {t("subscription_subtitle")}
                </p>
                <div className="mt-6 2xl:mt-10 flex justify-center">
                    <fieldset aria-label="Payment frequency">
                        <RadioGroup
                            value={frequency}
                            onChange={(f: Frequency) => setFrequency(f)}
                            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-theme-OnBg dark:ring-theme-OnBg-dark "
                        >
                            {frequencies.map((key) =>
                                (
                                    <Radio
                                        key={key}
                                        value={key}
                                        className="cursor-pointer rounded-full px-2.5 py-1 text-theme-disabled dark:text-theme-disabled-dark
                                         data-[checked]:bg-mainPart-primary100 data-[checked]:dark:bg-mainPart-primary100-dark
                                          data-[checked]:text-mainPart-primaryLight data-[checked]:dark:text-mainPart-primaryLight-dark"
                                    >
                                        {(frequenciesInfos.get(key)!.label)}
                                    </Radio>
                                ))}
                        </RadioGroup>
                    </fieldset>
                </div>
                <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {tiers.map((tier) => (
                        <div
                            onClick={() => setSelectedOffer(tier.type)}
                            key={tier.type}
                            className={classNames(
                                selectedOffer === tier.type
                                    ? "ring-4 ring-mainPart-primary100 dark:ring-mainPart-primary100-dark"
                                    : "ring-2 ring-theme-border dark:ring-theme-border-dark",
                                "rounded-3xl p-8 xl:p-10",
                            )}
                        >
                            <div className="flex items-center justify-between gap-x-4">
                                <h3
                                    id={tier.type.toString()}
                                    className={classNames(
                                        selectedOffer === tier.type
                                            ? "text-mainPart-primary100 dark:text-mainPart-primary100-dark"
                                            : "",
                                        "text-lg font-semibold leading-8",
                                    )}
                                >
                                    {tier.name}
                                </h3>
                                {selectedOffer === tier.type ? (
                                    <p className="rounded-full px-2.5 py-1 text-xs font-semibold leading-5
                                     bg-mainPart-primary50 dark:bg-mainPart-primary50-dark/10
                                    text-mainPart-primary100 dark:text-mainPart-primary100-dark">
                                        {t("subscription_selected_bubble")}
                                    </p>
                                ) : null}
                            </div>
                            {/*  === DESCRIPTION  === */}
                            <p className="mt-4 text-sm leading-6 text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark
                            min-h-20
                             max-h-32 overflow-hidden">{tier.description}</p>

                            <div className="flex flex-row items-baseline h-20">
                                <p className={`mt-6 flex flex-1 items-baseline gap-x-1 ${selectedOffer === tier.type
                                    ? "text-mainPart-primary100 dark:text-mainPart-primary100-dark"
                                    : ""}`}>
                                    <span className={`text-4xl font-bold tracking-tight `}>{getAmount(tier.price, selectedFrequencyInfo.kind)}</span>
                                    <span className="text-sm font-semibold leading-6">{selectedFrequencyInfo.priceSuffix}</span>
                                </p>
                                    {selectedFrequencyInfo.kind === Frequency.Annually &&
                                        <motion.div
                                            className="grid items-center"
                                            initial={{scale: 0, rotate: 180}}
                                            animate={{rotate: 0, scale: 1}}
                                            transition={{
                                                type: "spring",
                                                stiffness: 260,
                                                damping: 20
                                            }}>
                                            <h3 className="text-3xl font-semibold leading-8 rounded-full p-2
                                    text-mainPart-secondary100-dark
                                    bg-mainPart-secondary50">-10%</h3>
                                        </motion.div>
                                    }
                            </div>

                            <ButtonCore className="flex flex-1 w-full mt-2 px-3 py-2"
                                        colorKind={TabColors.mainPart_primaryColor}>
                                {t("subscription_button")}
                            </ButtonCore>

                            <ul role="list" className="mt-4 space-y-3 text-sm leading-6 xl:mt-6">
                                {tier.features.map((feature: string) => (
                                    <li key={feature} className="flex gap-x-3">
                                        <HiCheck aria-hidden="true" className="h-6 w-5 flex-none
                                        text-mainPart-secondary100 dark:text-mainPart-secondary100-dark"/>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>

            <LinkCore onClick={navigateBack}
                      href="#"
                      tabColor={TabColors.mainPart_primaryColor}
                      className="flex flex-row items-cente gap-3 text-sm font-semibold p-4 mt-8">
                <HiArrowLeft className="h-6 w-6" />
                Back
            </LinkCore>
        </DivCore>
    );
}
