import {useAppDispatch,} from "../../stores/reduxHooks.ts";
import ITabDefinition from "../tabDefinition.ts";
import {FC} from "react";
import ButtonCore from "../../ui/controls/buttonCore.tsx";
import {extractRouteParam, RouteParam} from "../../routes.ts";
import {removeTab, updateTab} from "../../ui/tab_panels/panelSlice.ts";
import {Destinations} from "../../ui/navigation/destinations.ts";
import {COLOR_PANEL_KIND_PRIMARY_COLORS, COLOR_PANEL_KIND_SECONDARY_COLORS} from "../../ui/colors/colorConfiguration.tsx";
import {HeadingCore} from "../../ui/controls/HeadingCore.tsx";
import {Operation} from "../../lib/Operation.ts";
import {CiCircleCheck, CiCircleRemove} from "react-icons/ci";

const Appointment: FC<ITabDefinition> = ({href, tabUuid, panelKind}) => {
    const primaryColor = COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind];
    const secondaryColor = COLOR_PANEL_KIND_SECONDARY_COLORS[panelKind];
    const searchParams = extractRouteParam(href)
    const dispatch = useAppDispatch();
    // Extraction des valeurs
    const id = searchParams.get(RouteParam.Id); // '63482'

    return (
        <div className="flex flex-col h-full">
            <HeadingCore colorKind={primaryColor}>Nouvel animal</HeadingCore>
            {
                searchParams.get(RouteParam.Operation) === Operation.Add
                    ? <div className="bg-blue-400 flex flex-col flex-1 p-6 gap-3">
                        <ButtonCore onClick={() =>
                            dispatch(updateTab(
                                {
                                    tabUuid: tabUuid,
                                    destination : Destinations.EditAppointment({appointmentId : id ?? "editId"})
                                }))}>
                            CLICK TO SWITCH TO EDIT MODE
                        </ButtonCore>

                        <ButtonCore onClick={() =>
                            dispatch(updateTab(
                                {
                                    tabUuid: tabUuid,
                                    destination : Destinations.Appointment({appointmentId : id ?? "editId"})
                                }))}>
                            CLICK TO SWITCH TO VISUALIZE MODE
                        </ButtonCore>

                        <ButtonCore onClick={() =>
                            dispatch(updateTab(
                                {
                                    tabUuid: tabUuid,
                                    destination : Destinations.NewAppointment()
                                }))}>
                            CLICK TO SWITCH TO NEW MODE
                        </ButtonCore>
                    </div>
                    : <div className="bg-pink-400 flex flex-col flex-1 p-6 gap-3">
                        <ButtonCore onClick={() =>
                            dispatch(updateTab(
                                {
                                    tabUuid: tabUuid,
                                    destination : Destinations.EditAppointment({appointmentId : id ?? "editId"})
                                }))}>
                            CLICK TO SWITCH TO EDIT MODE
                        </ButtonCore>

                        <ButtonCore onClick={() =>
                            dispatch(updateTab(
                                {
                                    tabUuid: tabUuid,
                                    destination : Destinations.Appointment({appointmentId : id ?? "editId"})
                                }))}>
                            CLICK TO SWITCH TO VISUALIZE MODE
                        </ButtonCore>

                        <ButtonCore onClick={() =>
                            dispatch(updateTab(
                                {
                                    tabUuid: tabUuid,
                                    destination : Destinations.NewAppointment()
                                }))}>
                            CLICK TO SWITCH TO NEW MODE
                        </ButtonCore>
                    </div>
            }

            <div className="flex flex-row items-center justify-center gap-x-16 m-3">
                <ButtonCore className="flex flex-row"
                            onClick={() => {
                                dispatch(removeTab(tabUuid));
                            }}>
                    <CiCircleRemove className="h-6 w-6 @4xl:w-5 @4xl:h-5"/>
                    Annuler
                </ButtonCore>
                <ButtonCore className="flex flex-row"
                            onClick={() => {
                                // TODO SAVE
                                dispatch(removeTab(tabUuid));
                            }}
                            colorKind={secondaryColor}>
                    <CiCircleCheck className="h-6 w-6 @4xl:w-5 @4xl:h-5"/>
                    Valider
                </ButtonCore>
            </div>
        </div>
    );
};
export default Appointment;

