/**
 * Define an establishment
 */
export interface Establishment {
    establishmentId: number;
    establishmentName: string;
    /**
     * At first connection or without any subscription, a test establishment is always available to test the app
     */
    isTestEstablishment: boolean;

    /**
     * Location of the establishment : an establishment could have many location but has at least one
     */
    locations: EstablishmentLocation[];
}

/**
 * The location of an establishment
 */
export interface EstablishmentLocation {
    locationId: number;
    locationName: string;
}


// HELPERS

/**
 * A single pair of establishment + one of its location
 */
export class EstablishmentAndLocation {
    readonly establishment: Establishment;
    readonly location: EstablishmentLocation;

    constructor(establishment: Establishment, location: EstablishmentLocation) {
        this.establishment = establishment;
        this.location = location;
    }

    IsSameEstablishmentId(establishment: Establishment) {
        return this.establishment.establishmentId === establishment.establishmentId;
    }

    IsSameLocationId(location: EstablishmentLocation) {
        return this.location.locationId === location.locationId;
    }
}

/**
 * flatten establishment list with establishment and location pair
 * @param establishments
 */
export function getEstablishmentAndLocationPairs(establishments: Establishment[]): EstablishmentAndLocation[] {
    return establishments
        .flatMap(establishment =>
            establishment
                .locations
                .map(location => new EstablishmentAndLocation(establishment, location))
        );
}
