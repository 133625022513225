import { Provider } from "react-redux";
import React from "react";
import vcsStore, {vcsReduxPersistor} from "../../stores/vcsStore.ts";
import {PersistGate} from "redux-persist/integration/react";

/*
Configure a Redux Provider
 */
export const ReduxProvider = ({ children }: { children: React.ReactNode }) => {
    return <Provider store={vcsStore}>
        <PersistGate loading={children} persistor={vcsReduxPersistor}>
            {children}
        </PersistGate>
    </Provider>
}
