import React, {useRef, useEffect, useState} from "react";
import {useVirtualizer} from "@tanstack/react-virtual";

interface VirtualizedTableProps<T> {
    items: T[];
    renderHeader: () => React.ReactNode;
    renderRow: (item: T, i: number) => React.ReactNode;
    rowHeight: number;
    className?: string;
}

export function VirtualizedTableCore<T>(
    {
        items,
        renderHeader,
        renderRow,
        rowHeight,
    }: VirtualizedTableProps<T>) {
    const containerRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const [scrollContainerHeight, setScrollContainerHeight] = useState<number>(0);

    // Calculation of available height
    useEffect(() => {
        const updateHeight = () => {
            if (containerRef.current && headerRef.current) {
                const containerRect = containerRef.current.getBoundingClientRect();
                const headerHeight = headerRef.current.offsetHeight;
                const availableHeight = Math.floor(containerRect.height - headerHeight);
                setScrollContainerHeight(availableHeight);
            }
        };

        // Observe the container size changes
        const resizeObserver = new ResizeObserver(updateHeight);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Clean the observer
        return () => resizeObserver.disconnect();
    }, []);

    const tableRef = useRef<HTMLDivElement>(null);

    // Use tanStack virtualizer :
    const rowVirtualizer = useVirtualizer({
        count: items.length,
        getScrollElement: () => tableRef.current,
        estimateSize: () => rowHeight,
    });

    return (
        <div ref={containerRef} className={`flex flex-col h-full overflow-hidden`}>

            {/* HEADERS */}
            <div ref={headerRef} className="sticky top-0 z-10">
                {renderHeader()}
            </div>

            {/*MAIN SCROLLING PART*/}
            <div
                ref={tableRef}
                className="overflow-auto flex-1"
                style={{
                    height: scrollContainerHeight || "auto",
                    width: "100%", // Force explicit width
                    boxSizing: "border-box", // Prend en compte padding/border dans le calcul
                }}
            >
                <div style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    position: "relative",
                }}>
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                        <div
                            key={virtualRow.key}
                            className="absolute left-0 right-0"
                            style={{
                                top: 0,
                                transform: `translateY(${virtualRow.start}px)`,
                                height: `${rowHeight}px`,
                                width: "100%",
                            }}
                        >
                            {renderRow(items[virtualRow.index], virtualRow.index)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
