import {PanelKind} from "../tab_panels/panelKind.ts";

/**
 * Interface shared by all component that implement colors and classname overrides
 */
export interface ColorProps {
    className?: string;
    color : TabColors;
}

/**
 * Interface shared by all component that implement panel kind and classname overrides
 */
export interface PanelKindProps {
    className?: string;
    panelKind : PanelKind;
}

/**
 * All possible tab colors (combination of main part/secondary part + primary/secondary color
 */
export enum TabColors {
    // eslint-disable-next-line no-unused-vars
    mainPart_primaryColor, // main tab part (left) + primary color
    // eslint-disable-next-line no-unused-vars
    mainPart_secondaryColor, // main tab part (left) + secondary color
    // eslint-disable-next-line no-unused-vars
    secondaryPart_primaryColor, // secondary tab part (right) + primary color
    // eslint-disable-next-line no-unused-vars
    secondaryPart_secondaryColor, // secondary tab part (right) + secondary color
}

/*
 * global rounded value for buttons, combobox...
 */
export const STYLE_ROUNDED_ITEM = " rounded-xl "

export const COLOR_TEXT_ON_BG = " text-theme-OnBg dark:text-theme-OnBg-dark dark:hover:text-theme-hoverNeutral-dark hover:text-theme-hoverNeutral ";

export const COLOR_CLASSES_BUTTON_BASIC = "ring-1 ring-inset focus-visible:ring-transparent bg-theme-bgVariant ring-theme-border dark:bg-theme-bgVariant-dark dark:ring-theme-border-dark " +
    "text-theme-OnBg dark:text-theme-OnBg-dark dark:hover:bg-theme-hoverNeutral-dark hover:bg-theme-hoverNeutral ";

export const COLOR_TAB_CLASSES_BUTTON_ICON_ONLY: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]:
    "text-mainPart-primary100 hover:bg-mainPart-primary50 hover:text-mainPart-primary100 " +
    "dark:text-mainPart-primary100-dark dark:hover:bg-mainPart-primary50-dark dark:hover:text-mainPart-primary100-dark ",
    [TabColors.mainPart_secondaryColor]:
    "text-mainPart-secondaryLight hover:bg-mainPart-secondary50 hover:text-mainPart-secondary100 " +
    "dark:text-mainPart-secondaryLight-dark dark:hover:bg-mainPart-secondary50-dark dark:hover:text-mainPart-secondaryLight-dark",
    [TabColors.secondaryPart_primaryColor]:
    "text-secondaryPart-primary100 hover:bg-secondaryPart-primary50 hover:text-secondaryPart-primary100 " +
    "dark:text-secondaryPart-primary100-dark dark:hover:bg-secondaryPart-primary50-dark dark:hover:text-secondaryPart-primary100-dark",
    [TabColors.secondaryPart_secondaryColor]:
    "text-secondaryPart-secondaryLight hover:bg-secondaryPart-secondary50 hover:text-secondaryPart-secondary100 " +
    "dark:text-secondaryPart-secondaryLight-dark dark:hover:bg-secondaryPart-secondary50-dark dark:hover:text-secondaryPart-secondaryLight-dark"
} as const;

/*
background with plain color
 */
export const COLOR_TAB_BG: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]: " bg-mainPart-primary100 dark:bg-mainPart-primary100-dark ",
    [TabColors.mainPart_secondaryColor]: " bg-mainPart-secondary100 dark:bg-mainPart-secondary100-dark ",
    [TabColors.secondaryPart_primaryColor]: " bg-secondaryPart-primary100 dark:bg-secondaryPart-primary100-dark ",
    [TabColors.secondaryPart_secondaryColor]: " bg-secondaryPart-secondary100 dark:bg-secondaryPart-secondary100-dark "
} as const;

/*
background with plain LIGHT color
 */
export const COLOR_TAB_BG_LIGHT: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]: " bg-mainPart-primaryLight dark:bg-mainPart-primaryLight-dark ",
    [TabColors.mainPart_secondaryColor]: " bg-mainPart-secondaryLight dark:bg-mainPart-secondaryLight-dark ",
    [TabColors.secondaryPart_primaryColor]: " bg-secondaryPart-primaryLight dark:bg-secondaryPart-primaryLight-dark ",
    [TabColors.secondaryPart_secondaryColor]: " bg-secondaryPart-secondaryLight dark:bg-secondaryPart-secondaryLight-dark "
} as const;
/*
background with Dark/Light variant (mainly for button)
 */
export const COLOR_BG_THEME_BGVARIANT = " bg-theme-bgVariant dark:bg-theme-bgVariant-dark ";

/*
background with Dark/Light constrast
 */
export const COLOR_BG_CONTRAST = " bg-theme-bgContrast dark:bg-theme-bgContrast-dark ";

/*
background with Dark/Light base
 */
export const COLOR_BG_THEME_BGBASE = " bg-theme-bgBase dark:bg-theme-bgBase-dark ";

export const COLOR_TAB_TEXT_HOVER: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]: " !hover:text-mainPart-primary50 !dark:hover:text-mainPart-primary50-dark ",
    [TabColors.mainPart_secondaryColor]: " !hover:text-mainPart-secondary50 !dark:hover:text-mainPart-secondary50-dark ",
    [TabColors.secondaryPart_primaryColor]: " !hover:text-secondaryPart-primary50 !dark:hover:text-secondaryPart-primary50-dark ",
    [TabColors.secondaryPart_secondaryColor]: " !hover:text-secondaryPart-secondary50 !dark:hover:text-secondaryPart-secondary50-dark "
} as const;

/*
text colored in primary or secondary 100% plain color
 */
export const COLOR_TAB_TEXT_100: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]: " !text-mainPart-primary100 !dark:text-mainPart-primary100-dark ",
    [TabColors.mainPart_secondaryColor]: " !text-mainPart-secondary100 !dark:text-mainPart-secondary100-dark ",
    [TabColors.secondaryPart_primaryColor]: " !text-secondaryPart-primary100 !dark:text-secondaryPart-primary100-dark ",
    [TabColors.secondaryPart_secondaryColor]: " !text-secondaryPart-secondary100 !dark:text-secondaryPart-secondary100-dark "
} as const;
/*
text colored in primary or secondary 50% plain color
 */
export const COLOR_TAB_TEXT_50: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]: " !text-mainPart-primary50 !dark:text-mainPart-primary50-dark ",
    [TabColors.mainPart_secondaryColor]: " !text-mainPart-secondary50 !dark:text-mainPart-secondary50-dark ",
    [TabColors.secondaryPart_primaryColor]: " !text-secondaryPart-primary50 !dark:text-secondaryPart-primary50-dark ",
    [TabColors.secondaryPart_secondaryColor]: " !text-secondaryPart-secondary50 !dark:text-secondaryPart-secondary50-dark "
} as const;
/*
text colored in primary or secondary light color
 */
export const COLOR_TEXT_LIGHT: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]: " !text-mainPart-primaryLight !dark:text-mainPart-primaryLight-dark ",
    [TabColors.mainPart_secondaryColor]: " !text-mainPart-secondaryLight !dark:text-mainPart-secondaryLight-dark ",
    [TabColors.secondaryPart_primaryColor]: " !text-secondaryPart-primaryLight !dark:text-secondaryPart-primaryLight-dark ",
    [TabColors.secondaryPart_secondaryColor]: " !text-secondaryPart-secondaryLight !dark:text-secondaryPart-secondaryLight-dark "
} as const;

export const COLOR_TAB_TEXT_AND_TEXT_HOVER: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]: COLOR_TAB_TEXT_100[TabColors.mainPart_primaryColor] + COLOR_TAB_TEXT_HOVER[TabColors.mainPart_primaryColor],
    [TabColors.mainPart_secondaryColor]: COLOR_TAB_TEXT_100[TabColors.mainPart_secondaryColor] + COLOR_TAB_TEXT_HOVER[TabColors.mainPart_secondaryColor],
    [TabColors.secondaryPart_primaryColor]: COLOR_TAB_TEXT_100[TabColors.secondaryPart_primaryColor] + COLOR_TAB_TEXT_HOVER[TabColors.secondaryPart_primaryColor],
    [TabColors.secondaryPart_secondaryColor]: COLOR_TAB_TEXT_100[TabColors.secondaryPart_secondaryColor] + COLOR_TAB_TEXT_HOVER[TabColors.secondaryPart_secondaryColor]
} as const;

// Return a border of 100% color depending on TabColors
export const COLOR_TAB_BORDER_100: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]: " border-mainPart-primary100 dark:border-mainPart-primary100-dark ",
    [TabColors.mainPart_secondaryColor]:" border-mainPart-secondary100 dark:border-mainPart-secondary100-dark ",
    [TabColors.secondaryPart_primaryColor]:" border-secondaryPart-primary100 dark:border-secondaryPart-primary100-dark ",
    [TabColors.secondaryPart_secondaryColor]: " border-secondaryPart-secondary100 dark:border-secondaryPart-secondary100-dark ",
} as const;

// Return a border of 50% color depending on TabColors
export const COLOR_TAB_BORDER_50: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]: " border-mainPart-primary50 dark:border-mainPart-primary50-dark ",
    [TabColors.mainPart_secondaryColor]:" border-mainPart-secondary50 dark:border-mainPart-secondary50-dark ",
    [TabColors.secondaryPart_primaryColor]:" border-secondaryPart-primary50 dark:border-secondaryPart-primary50-dark ",
    [TabColors.secondaryPart_secondaryColor]: " border-secondaryPart-secondary50 dark:border-secondaryPart-secondary50-dark ",
} as const;

// Return a border of 50% On HOVER color depending on TabColors
export const COLOR_TAB_BORDER_50_ON_HOVER: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]: " hover:border-mainPart-primary50 hover:dark:border-mainPart-primary50-dark ",
    [TabColors.mainPart_secondaryColor]:" hover:border-mainPart-secondary50 hover:dark:border-mainPart-secondary50-dark ",
    [TabColors.secondaryPart_primaryColor]:" hover:border-secondaryPart-primary50 hover:dark:border-secondaryPart-primary50-dark ",
    [TabColors.secondaryPart_secondaryColor]: " hover:border-secondaryPart-secondary50 hover:dark:border-secondaryPart-secondary50-dark ",
} as const;

// Return a border of BLACK AND WHITE depending on TabColors
export const COLOR_BORDER_BLACK_AND_WHITE : string =  " border-theme-border dark:border-theme-border-dark ";

export function colorOrDefault(tab: TabColors | undefined, ifNotNull : Record<TabColors, string>, ifNull : string ) : string {
    if(tab === undefined){
        return ifNull
    }
    return ifNotNull[tab]
}

export const COLOR_TAB_BUTTON: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]:
    COLOR_TAB_BG[TabColors.mainPart_primaryColor] +
    " text-mainPart-primaryLight hover:bg-mainPart-primary50 hover:text-mainPart-primary100 " +
    "dark:text-mainPart-primaryLight-dark dark:hover:bg-mainPart-primary50-dark dark:hover:text-mainPart-primaryLight-dark ",
    [TabColors.mainPart_secondaryColor]:
    COLOR_TAB_BG[TabColors.mainPart_secondaryColor] +
    "text-mainPart-secondaryLight hover:bg-mainPart-secondary50 hover:text-mainPart-secondary100 " +
    "dark:text-mainPart-secondaryLight-dark dark:hover:bg-mainPart-secondary50-dark dark:hover:text-mainPart-secondaryLight-dark ",
    [TabColors.secondaryPart_primaryColor]:
    COLOR_TAB_BG[TabColors.secondaryPart_primaryColor] +
    "text-secondaryPart-primaryLight hover:bg-secondaryPart-primary50 hover:text-secondaryPart-primary100 " +
    "dark:text-secondaryPart-primaryLight-dark dark:hover:bg-secondaryPart-primary50-dark dark:hover:text-secondaryPart-primaryLight-dark ",
    [TabColors.secondaryPart_secondaryColor]:
    COLOR_TAB_BG[TabColors.secondaryPart_secondaryColor] +
    "text-secondaryPart-secondaryLight hover:bg-secondaryPart-secondary50 hover:text-secondaryPart-secondary100 dark:text-secondaryPart-secondaryLight-dark dark:hover:bg-secondaryPart-secondary50-dark dark:hover:text-secondaryPart-secondaryLight-dark "
} as const;

/**
 * returns only primary style for text depending on panel kind
 */
export const COLOR_PANEL_KIND_TEXT_ON_PRIMARY: Record<PanelKind, string> = {
    [PanelKind.Left]: COLOR_TAB_TEXT_100[TabColors.mainPart_primaryColor],
    [PanelKind.Right]: COLOR_TAB_TEXT_100[TabColors.secondaryPart_primaryColor]
} as const;

/**
 * returns primary TabColor depending on panel kind
 */
export const COLOR_PANEL_KIND_PRIMARY_COLORS: Record<PanelKind, TabColors> = {
    [PanelKind.Left]: TabColors.mainPart_primaryColor,
    [PanelKind.Right]: TabColors.secondaryPart_primaryColor
} as const;

/**
 * returns secondary TabColor depending on panel kind
 */
export const COLOR_PANEL_KIND_SECONDARY_COLORS: Record<PanelKind, TabColors> = {
    [PanelKind.Left]: TabColors.mainPart_secondaryColor,
    [PanelKind.Right]: TabColors.secondaryPart_secondaryColor
} as const;
