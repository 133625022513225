import {RequestParams} from "./generated/http-client.ts";

/**
 * Set params of the query according to the current authentication setup
 * @param params
 */
export function setAuthenticationAccordingToValues(
    useHttpOnlyCookie: boolean,
    accessToken: string | undefined,
    params: RequestParams
): RequestParams {
    if (useHttpOnlyCookie) {
        // when using httpOnly cookie, the caller need to add and explicit credentials
        // header in order for the browser to add the httpOnly cookie that
        // contains the access_token
        return {credentials: "include", ...params};
    } else if (accessToken !== undefined) {
        // if there is a access token, join it explicitely
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }, ...params
        };
    } else {
        // else do nothing
        return params;
    }

}
