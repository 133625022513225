import React, {useState} from "react";
import {Dialog, DialogBackdrop, DialogPanel, TransitionChild} from "@headlessui/react";
import {HiXMark,} from "react-icons/hi2";
import {SideNavigationBarLink} from "./sideNavigationBarLink.tsx";
import {NotificationLevel, NotificationVcs} from "../../components/notifications/notificationVcs.tsx";
import SidebarComponent from "./sidebarComponent.tsx";
import {useAppDispatch, useAppSelector} from "../../stores/reduxHooks.ts";
import {focusOrCreatePrimaryTab} from "../tab_panels/panelSlice.ts";
import TopHeaderBar from "./topHeaderBar.tsx";

interface SideBarNavigationProps {
    children: React.ReactNode,
}

const SideBarNavigation: React.FC<SideBarNavigationProps> = ({children}) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const isConnectedToBackend = useAppSelector(state => state.apiStatus.isConnectedToBackend);
    const dispatch = useAppDispatch();

    function onNavigationMainMenu(o: SideNavigationBarLink) {
        // close the nav bar if needed
        setSidebarOpen(false);
        // set the left tab panel location
        dispatch(focusOrCreatePrimaryTab(o.destination));
    }

    // TODO : Provide notifications
    const notifications: NotificationVcs[] = isConnectedToBackend ? [] : [
        {
            title: "Test 1", importance: NotificationLevel.High, content: "Not connected to backend", onRemove: () => {
            }
        },
    ];

    return (
        <>
            <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-40 lg:hidden">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                />

                <div className="fixed inset-0 flex">
                    <DialogPanel
                        transition
                        className="relative mr-16 flex w-full max-w-32 flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
                    >
                        <TransitionChild>
                            <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                                <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                                    <span className="sr-only">Close sidebar</span>
                                    <HiXMark aria-hidden="true" className="h-6 w-6 text-white"/>
                                </button>
                            </div>
                        </TransitionChild>
                        {/* Sidebar component, swap this element with another sidebar if you like */}
                        <SidebarComponent onNavigationMainMenu={onNavigationMainMenu}/>
                    </DialogPanel>
                </div>
            </Dialog>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-24 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <SidebarComponent onNavigationMainMenu={onNavigationMainMenu}/>
            </div>

            {/*lg:pl-xxx = Move from the sidebar size with a padding left xxx*/}
            <div className="lg:pl-24 flex flex-col h-full">
                <TopHeaderBar
                    notifications={notifications}
                    onOpenSidebar={() => setSidebarOpen(true)}/>

                <main className="flex-1 min-h-0 bg-theme-bgContrast dark:bg-theme-bgContrast-dark">
                    {children}
                </main>
            </div>
        </>
    );
};

export default SideBarNavigation;

