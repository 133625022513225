import {FC} from "react";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import {COLOR_PANEL_KIND_PRIMARY_COLORS,  COLOR_TAB_TEXT_100, PanelKindProps} from "../../ui/colors/colorConfiguration.tsx";
import {getUserInitials} from "../../ui/profil/ProfilBagde.tsx";
import {IUserInfos} from "../signin/signInSlice.ts";
import DivCore from "../../ui/controls/divCore.tsx";
import {HiChevronRight} from "react-icons/hi2";

const TeamViewPart: FC<PanelKindProps> = ({panelKind}) => {
    const primaryColor = COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind];
    const primaryTextColor = COLOR_TAB_TEXT_100[primaryColor];
    const {t} = useTranslationFile(TranslationFile.Account);
    return (
        <DivCore className="mx-auto max-w-7xl px-0 @md:px-2 @2xl:px-6 mt-8 pt-8">
            <div className="divide-y divide-theme-border dark:divide-theme-border-dark">
                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 @2xl:grid-cols-3">
                    <div>
                        <h2 className={`text-base/7 font-semibold ${primaryTextColor}`}>
                            {t("establishmentTeam")}
                        </h2>
                        <p className="mt-1 text-sm text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark font-medium">
                            {t("establishmentTeamDetails")}
                        </p>
                    </div>

                    <div className="@2xl:col-span-2">
                        <ul role="list" className="divide-y divide-theme-border dark:divide-theme-border-dark">
                            {people.map((member) => (
                                <li key={member.email} className="relative flex justify-between gap-x-6 py-5">
                                    <div className="flex min-w-0 gap-x-4" onClick={() => {
                                    }}>
                                        <div className="h-10 w-10 rounded-full
                                        items-center justify-center
                                        text-white font-semibold hidden @lg:flex"
                                            style={{backgroundColor: member.userColor}}>
                                            {getUserInitials(member)}
                                        </div>
                                        <div className="min-w-0 flex-auto text-left">
                                            <p className="text-sm font-semibold">
                                         <span className="text-sm font-semibold leading-6 text-theme-OnBg dark:text-theme-OnBg-dark
                     truncate max-w-48 xl:max-w-80 2xl:max-w-96" aria-hidden="true">
                        {member?.userFirstName ?? "John"} {member?.userLastName ?? "Doe"}
                    </span>
                                            </p>
                                            <p className="mt-1 flex text-xs/5 text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark">
                                                <a href={`mailto:${member.email}`} className="relative truncate hover:underline">
                                                    {member.email}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex shrink-0 items-center gap-x-4">
                                        <div className="hidden sm:flex sm:flex-col sm:items-end">
                                            <p className="text-sm/6 text-gray-900">{member.role}</p>
                                        </div>
                                        <HiChevronRight aria-hidden="true" className="h-5 w-5 flex-none text-gray-400"/>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </DivCore>
    );
};

export default TeamViewPart;


interface ITeamMember extends IUserInfos {
    email: string;
    role: string;
    imageUrl: string;
}

// Todo => replace by server call
const people: ITeamMember[] = [
    {
        userFirstName: "Estelle",
        userLastName: "GOUACHE",
        email: "estelle@gouache.com",
        role: "Co-Founder / CTO",
        userColor: "#35a2b5",
        imageUrl:
            "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    },
    {
        userFirstName: "Pierre",
        userLastName: "BONY",
        email: "pierre@bony.com",
        role: "Co-Founder / CTO",
        userColor: "#ff790b",
        imageUrl:
            "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    },
    {
        userFirstName: "Loïs",
        userLastName: "AZZARO",
        email: "lois@azzaro.com",
        role: "Lead designer / UX senior",
        userColor: "#21c877",
        imageUrl:
            "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    },
    // More people...
];
