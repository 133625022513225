
export default class SliceNames {
    public static readonly ERROR = "errors";
    public static readonly ACCOUNT = "account";
    public static readonly APIS_STATUS = "apiStatus";
    public static readonly SIGN_IN = "signIn";
    public static readonly SETTINGS = "settings";
    public static readonly PANELS = "panels";
    public static readonly STATISTICS = "statistics";
    public static readonly PLANNING = "planning";
    public static readonly HOSPITALIZATION = "hospitalization";
    public static readonly PRODUCT = "product";
    public static readonly FINANCIAL = "financial";
    public static readonly CUSTOMERS_DASHBOARD = "customers_dashboard";
    public static readonly HELP = "help";
    public static readonly ESTABLISHMENT = "establishment";
    public static readonly APPOINTMENT = "appointment";
    public static readonly WAITING_ROOM = "waiting_room";
    public static readonly CUSTOMER_RECORD = "customer_record";
    public static readonly ANIMAL_RECORD = "animal_record";

    static GetPersistedSlices() : string[] {
        return [
            this.ERROR,
            this.ACCOUNT,
            this.APIS_STATUS,
            this.SIGN_IN,
            this.PANELS,
            this.STATISTICS,
            this.PLANNING,
            this.HOSPITALIZATION,
            this.PRODUCT,
            this.FINANCIAL,
            this.CUSTOMERS_DASHBOARD,
            this.HELP,
            this.SETTINGS,
            this.ESTABLISHMENT,
            this.APPOINTMENT,
            this.WAITING_ROOM,
            this.CUSTOMER_RECORD,
            this.ANIMAL_RECORD,
        ]
    }
}

