import {ComponentProps, forwardRef, useState} from "react";
import {HiEye, HiEyeSlash} from "react-icons/hi2";
import InputCore from "./InputCore.tsx";

type InputPasswordProps = ComponentProps<typeof InputCore>;

// NOTE : react-hook-form uses refs to handle form fields.
// ==> Functional components cannot receive refs directly unless they are wrapped in React.forwardRef.
// ==> That is why we had to wrap component in a forwardRef
const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(({...props}, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <div className="relative mt-2 rounded-md">
            <InputCore
                autoComplete={props.autoComplete || "new-password"}
                type={isPasswordVisible ? "text" : "password"}
                {...props}
                ref={ref}/>
            <button
                type="button"
                tabIndex={-1} // Set the tabIndex to -1 to avoid focusing the button on tab pressed after password input
                className="absolute inset-y-0 right-0 flex items-center px-4"
                onClick={togglePasswordVisibility}
            >
                {isPasswordVisible ? (
                    <HiEye className="h-5 w-5 text-theme-OnBg dark:text-theme-OnBg-dark" aria-hidden="true"/>
                ) : (
                    <HiEyeSlash className="h-5 w-5 text-theme-OnBg dark:text-theme-OnBg-dark" aria-hidden="true"/>
                )}
            </button>
        </div>
    );
});

export default InputPassword;
