import {WritableDraft} from "immer";

export interface ITabStateKeyValue<T> {
    tabUuid: string;
    sliceState: T;
}

/**
 * Represent a tab indexed data structure:
 * each slice that need to index its data from a tab should use this.
 * IMPORTANT : Be careful to REPLACE instance in reducers to ensure redraw
 */
export default interface ITabIndexedState<T> {
    initialState: T;
    tabStates: ITabStateKeyValue<T>[];
}

export function getOrDefault<T>(state: ITabIndexedState<T>, tabUuid: string): T {
    for (const keyValue of state.tabStates) {
        if (keyValue.tabUuid === tabUuid) {
            return keyValue.sliceState;
        }
    }
    // not found, use initial State
    return state.initialState;
}

export function updateTabState<T>(
    state: ITabIndexedState<WritableDraft<T>>,
    tabUuid: string,
    updateFunction: (current: WritableDraft<T>) => void) {

    for (const keyValue of state.tabStates) {
        if (keyValue.tabUuid === tabUuid) {
            // update
            updateFunction(keyValue.sliceState);
            return;
        }
    }
    // create new if not found
    const newSliceState = { ...state.initialState };
    updateFunction(newSliceState);
    state.tabStates.push({
        sliceState: newSliceState,
        tabUuid: tabUuid
    });
}

export function removeTabState<T>(
    state: ITabIndexedState<T>,
    tabUuid: string) {

    for (let i = 0; i < state.tabStates.length; i++) {
        if (state.tabStates[i].tabUuid === tabUuid) {
            // remove from array
            state.tabStates.splice(i, 1);
            return;
        }
    }
}
