import api from "./Api.ts";
import {loginSuccessWithoutRememberMe, loginSuccessWithRememberMe, logout, updateUserInfo} from "../pages/signin/signInSlice.ts";
import {JwtTokenDto} from "./generated/data-contracts.ts";
import {AppDispatch} from "../stores/appDispatch.ts";


export async function createUserAsync(
    dispatch: AppDispatch,
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    rememberMe: boolean,
    reCaptchaToken : string
) {
    const token = await api.account.createUser({
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        withHttpOnlyCookie: rememberMe, // if remember me = true => keep login info in httpOnly cookie
        reCaptchaToken: reCaptchaToken
    });
    await SaveTokenAndUpdateUserInfo(dispatch, rememberMe, token);
}

export async function logInAsync(
    dispatch: AppDispatch,
    email: string,
    password: string,
    rememberMe: boolean
) {
    // if remember me = true => keep login info in httpOnly cookie
    const token = await api.account.login({
        userName: email,
        password: password,
        withHttpOnlyCookie: rememberMe
    });
    await SaveTokenAndUpdateUserInfo(dispatch, rememberMe, token);
}

export async function logOutAsync(dispatch: AppDispatch) {
    // if remember me = true => keep login info in httpOnly cookie
    await api.account.logout();
    dispatch(logout());
}

async function SaveTokenAndUpdateUserInfo(
    dispatch: AppDispatch,
    rememberMe: boolean,
    token: JwtTokenDto) {
    if (rememberMe) {
        // if remember me = false => Store JWT into Redux store but first, query the user info
        dispatch(loginSuccessWithRememberMe());
    } else {
        dispatch(loginSuccessWithoutRememberMe({
            access_token: token.access_token,
            refresh_token: token.refresh_token,
        }));
    }

    const userInfo = await api.account.userinfo();
    console.log(`user ${userInfo.userFirstName} ${userInfo.userLastName} connected successfully.`);

    dispatch(updateUserInfo(userInfo));
}
