import {FC, HTMLAttributes} from "react";
import {Heading} from "../catalyst_ui/heading.tsx";
import {COLOR_TAB_TEXT_100, colorOrDefault, TabColors} from "../colors/colorConfiguration.tsx";

export interface HeadingCoreProps extends HTMLAttributes<HTMLHeadingElement> {
    colorKind?: TabColors;
}

export const HeadingCore: FC<HeadingCoreProps> = ({colorKind, ...props}) => {
    const color = colorOrDefault(colorKind, COLOR_TAB_TEXT_100, " text-theme-OnBg dark:text-theme-OnBg-dark ")
    return (
        <Heading
            {...props}
            className={`${color}  ${props.className}`}>
            {props.children}
        </Heading>
    );
    //
    // <Heading
    //     {...props}
    //     className={` text-secondaryPart-primary100 dark:text-secondaryPart-primary100-dark`}>
    //     {props.children}
    // </Heading>
};
