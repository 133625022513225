import {createSlice} from "@reduxjs/toolkit";
import SliceNames from "../../stores/sliceNames.ts";

export interface IAccountState {
    count: number;
}

function getInitialState() : IAccountState {
    return {
        count: 0
    };
}

const accountSlice = createSlice({
    name: SliceNames.ACCOUNT,
    initialState: getInitialState,
    reducers: {
    },
});


const accountSliceReducer = accountSlice.reducer;
export default accountSliceReducer;
