import {Key} from "react";

// WARNING: every enum member need to have a translation key in json file
export enum ActivityKind {
    Canine = "Canine",
    Rural = "Rural",
    Equine = "Equine"
}

// WARNING: every enum member need to have a translation key in json file
export enum OperationKind {
    Surgery = "Surgery",
    Appointment = "Appointment",
    Vaccine = "Vaccine"
}

export interface IFilterAdapter {
    // method called when is checked change, it should propagate event
    onIsCheckedChanged(e: boolean): void;

    getFilterText(translator: (input: string) => string): string;

    uniqueKey: Key;
    isChecked: boolean;
}

// the adapter for UI of a calendar activity filter
export class ActivityFilterAdapter implements IFilterAdapter {
    private readonly ActivityKindFilter: ActivityKind;
    readonly uniqueKey: Key;
    readonly isChecked: boolean;
    private readonly updateIsCheckedCallback: (kind: ActivityKind, isChecked: boolean) => void;

    constructor(
        ActivityKindFilter: ActivityKind,
        isChecked: boolean,
        updateIsCheckedCallback: (kind: ActivityKind, isChecked: boolean) => void
    ) {
        this.ActivityKindFilter = ActivityKindFilter;
        this.isChecked = isChecked;
        this.uniqueKey = ActivityKindFilter;
        this.updateIsCheckedCallback = updateIsCheckedCallback;
    }

    onIsCheckedChanged(isChecked: boolean): void {
        this.updateIsCheckedCallback(this.ActivityKindFilter, isChecked);
    }

    getFilterText(translator: (input: string) => string): string {
        return translator(this.ActivityKindFilter.toString());
    }
}

export class OperationKindFilterAdapter implements IFilterAdapter {
    readonly uniqueKey: Key;
    readonly isChecked: boolean;
    private readonly OperationKindFilter: OperationKind;
    private readonly updateIsCheckedCallback: (kind: OperationKind, isChecked: boolean) => void;

    constructor(
        OperationKindFilter: OperationKind,
        isChecked: boolean,
        updateIsCheckedCallback: (kind: OperationKind, isChecked: boolean) => void
    ) {
        this.isChecked = isChecked;
        this.OperationKindFilter = OperationKindFilter;
        this.uniqueKey = OperationKindFilter;
        this.updateIsCheckedCallback = updateIsCheckedCallback;
    }

    onIsCheckedChanged(isChecked: boolean): void {
        this.updateIsCheckedCallback(this.OperationKindFilter, isChecked);
    }

    getFilterText(translator: (input: string) => string): string {
        return translator(this.OperationKindFilter.toString());
    }
}

export class TeamMemberFilterAdapter implements IFilterAdapter {
    readonly uniqueKey: Key;
    readonly isChecked: boolean;
    private readonly TeamMemberUuid: string;
    private readonly TeamMemberName: string;
    private readonly updateIsCheckedCallback: (memberId: string, isChecked: boolean) => void;

    constructor(
        TeamMemberName: string,
        TeamMemberUuid: string,
        isChecked: boolean,
        updateIsCheckedCallback: (memberId: string, isChecked: boolean) => void
    ) {
        this.isChecked = isChecked;
        this.TeamMemberUuid = TeamMemberUuid;
        this.TeamMemberName = TeamMemberName;
        this.uniqueKey = TeamMemberUuid;
        this.updateIsCheckedCallback = updateIsCheckedCallback;
    }

    onIsCheckedChanged(isChecked: boolean): void {
        this.updateIsCheckedCallback(this.TeamMemberUuid, isChecked);
    }

    getFilterText(): string {
        // No translation for team memebr name
        return this.TeamMemberName;
    }
}
