import {isValidUrlProxy} from "../lib/helpers/urlAndPathHelper.ts";
import vcsStore from "../stores/vcsStore.ts";
import {updateApiProxyServerUrl} from "../sagas/apiStatus/apiStatusSlice.ts";
import api, {UpdateProxyServerFromUrl} from "./Api.ts";

export async function ensureBackendReachableAndRetrieveProxyServer(): Promise<boolean> {
    try {
        // first try to get the proxy url if the central server is reachable.
        const newUrlProxyServer = await api.centralServer.apiProxyServerUrl();
        updateProxyServerIfNeeded(newUrlProxyServer, api.server.baseUrl);
    } catch (error) {
        if(api.useAProxyServer()) {
            // in case api proxy has a different baseUrl, try to access it directly :
            console.error(`unable to reach the Main server: ${api.centralServer.baseUrl} - try to switch to direct proxy access`);
            return await isProxyServerConnected();
        }
        // else returns false
        console.error(`unable to reach the Main server: ${api.centralServer.baseUrl} and the proxy server has the same url.`);
        return false;
    }

    return await isProxyServerConnected();
}

async function isProxyServerConnected() {
    try {
        await api.server.ping();
        return true;
    } catch (error) {
        console.error(`unable to reach proxy server : ${api.server.baseUrl} - error : ${error}`);
        return false;
    }
}

function updateProxyServerIfNeeded(newUrl: string, previousUrl: string) {
    if (newUrl == previousUrl) {
        // same as before = do not change
        return;
    }
    console.log(`Proxy Url [${newUrl}] is different from previous one [${previousUrl}]`)

    if (!isValidUrlProxy(newUrl)) {
        console.log(`Proxy Url [${newUrl}] is not a valid proxy url. It will be ignored`)
        return;
    }
    console.log(`Proxy Url [${newUrl}] is valid and will be updated in store`)
    // if ping successfully, update the server url in store and api proxyServer
    UpdateProxyServerFromUrl(newUrl);
    // dispatch update in store if displayed
    vcsStore.dispatch(updateApiProxyServerUrl(newUrl))
}
