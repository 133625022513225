import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import LoginHolder, {ISessionJwtTokens} from "../../api/LoginDataHolder.ts";
import SliceNames from "../../stores/sliceNames.ts";

export interface IUserInfos {
    userFirstName: string;
    userLastName: string;
    userColor : string;
}

interface ISignInState {
    isLoggedIn: boolean;
    userInfo: IUserInfos | null;
}

const initialState: ISignInState = {
    userInfo: null,
    isLoggedIn: false,
};

const signInSlice = createSlice({
    name: SliceNames.SIGN_IN,
    initialState : initialState,
    reducers: {
        loginSuccessWithRememberMe: (state) => {
            // remember me use the httponly token, so there is no need to hold current
            // jwt infos : clean it in case previously set
            state.isLoggedIn = true;
            LoginHolder.UseImplicitCookieToken();
        },
        loginSuccessWithoutRememberMe: (state, action: PayloadAction<ISessionJwtTokens>) => {
            // without remember me, with should keep the token in the store
            state.isLoggedIn = true;
            LoginHolder.UseExplicitToken(action.payload);
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.userInfo = null;
            LoginHolder.UseNone();
        },
        updateUserInfo: (state, action: PayloadAction<IUserInfos>) => {
            state.userInfo = action.payload;
        },
    },
    selectors: {
        selectUserInfo: (state) => state.userInfo,
        selectIsLoggedIn: (state) => state.isLoggedIn,
    },
});

export const {loginSuccessWithRememberMe, loginSuccessWithoutRememberMe, logout, updateUserInfo} = signInSlice.actions;
export const { selectUserInfo, selectIsLoggedIn } = signInSlice.selectors;
const signInReducer = signInSlice.reducer;
export default signInReducer;
