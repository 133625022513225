/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateUserDto,
  JwtTokenDto,
  LoginDto,
  NewSubscriptionInfoDto,
  ProblemDetails,
  RefreshDto,
  UserInfoDto,
  UserMultipleEstablishmentsDto,
  UserSingleEstablishmentDto,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Account<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Account
   * @name LoginCreate
   * @summary Try to log in with a username and a password. If the login check succeed, a JWT token used for identification is returned
   * @request POST:/Account/login
   * @secure
   */
  loginCreate = (data: LoginDto, params: RequestParams = {}) =>
    this.request<JwtTokenDto, ProblemDetails | void>({
      path: `/Account/login`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
 * No description
 *
 * @tags Account
 * @name LogoutCreate
 * @summary Try to log out by setting an expired empty jwt token in the http Only cache to prevent any resend.
This method is useful only in the case where the HttpOnly cookie are used for saving jwt access_token and refresh_token.
 * @request POST:/Account/logout
 * @secure
 */
  logoutCreate = (params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/Account/logout`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name RefreshtokenCreate
   * @summary Try to get a refresh token from current one
   * @request POST:/Account/refreshtoken
   * @secure
   */
  refreshtokenCreate = (data: RefreshDto, params: RequestParams = {}) =>
    this.request<JwtTokenDto, void | ProblemDetails>({
      path: `/Account/refreshtoken`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name CreateUserCreate
   * @summary Create new user with default role and log it to retrieve an associated JWT
   * @request POST:/Account/createUser
   * @secure
   */
  createUserCreate = (data: CreateUserDto, params: RequestParams = {}) =>
    this.request<JwtTokenDto, ProblemDetails | void>({
      path: `/Account/createUser`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name CreateEstablishmentSubscriptionCreate
   * @summary Request an establishment subscription for the current logged user
   * @request POST:/Account/createEstablishmentSubscription
   * @secure
   */
  createEstablishmentSubscriptionCreate = (data: NewSubscriptionInfoDto, params: RequestParams = {}) =>
    this.request<number, ProblemDetails | void>({
      path: `/Account/createEstablishmentSubscription`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name GetallusersList
   * @summary Get all Users of all establishments
   * @request GET:/Account/getallusers
   * @secure
   */
  getallusersList = (params: RequestParams = {}) =>
    this.request<UserMultipleEstablishmentsDto[], ProblemDetails | void>({
      path: `/Account/getallusers`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name GetAllUsersByEstablishmentList
   * @summary Get all Users for a specific establishments
   * @request GET:/Account/getAllUsersByEstablishment
   * @secure
   */
  getAllUsersByEstablishmentList = (params: RequestParams = {}) =>
    this.request<UserSingleEstablishmentDto[], ProblemDetails | void>({
      path: `/Account/getAllUsersByEstablishment`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name AssignusertoestablishmentList
   * @summary Assign a user to an establishment with basic right
   * @request GET:/Account/assignusertoestablishment
   * @secure
   */
  assignusertoestablishmentList = (
    query?: {
      /** @format uuid */
      userIdToAdd?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<number, ProblemDetails | void>({
      path: `/Account/assignusertoestablishment`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name UserinfoList
   * @summary Get logged in user informations
   * @request GET:/Account/userinfo
   * @secure
   */
  userinfoList = (params: RequestParams = {}) =>
    this.request<UserInfoDto, ProblemDetails | void>({
      path: `/Account/userinfo`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name UpdateestablishmentsubscriptionList
   * @summary Assign a user to an establishment with basic right
   * @request GET:/Account/updateestablishmentsubscription
   * @secure
   */
  updateestablishmentsubscriptionList = (data: NewSubscriptionInfoDto, params: RequestParams = {}) =>
    this.request<number, ProblemDetails | void>({
      path: `/Account/updateestablishmentsubscription`,
      method: "GET",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
