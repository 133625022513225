import {FC, useState} from "react";
import {TranslationFile, useTranslationFile} from "../../../hooks/translations.ts";
import {HiChevronDown, HiFunnel, HiXMark} from "react-icons/hi2";
import {Dialog, DialogBackdrop, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel} from "@headlessui/react";
import {COLOR_PANEL_KIND_PRIMARY_COLORS, COLOR_TAB_TEXT_AND_TEXT_HOVER, TabColors} from "../../../ui/colors/colorConfiguration.tsx";
import {PanelKind} from "../../../ui/tab_panels/panelKind.ts";
import {ActivityFilterAdapter, IFilterAdapter, OperationKindFilterAdapter, TeamMemberFilterAdapter} from "./filters/filterHelpers.ts";
import DivCore from "../../../ui/controls/divCore.tsx";
import {ButtonCoreNoBorder} from "../../../ui/controls/buttonCore.tsx";
import {motion} from "framer-motion";
import {Label} from "../../../ui/catalyst_ui/fieldset.tsx";
import {Checkbox, CheckboxField} from "../../../ui/catalyst_ui/checkbox.tsx";

interface CalendarFiltersProps {
    panelKind: PanelKind;
    activityFilters: ActivityFilterAdapter[];
    operationKindFilters: OperationKindFilterAdapter[];
    teamMemberUserFilters: TeamMemberFilterAdapter[];
}

const CalendarFilters: FC<CalendarFiltersProps> = (
    {
        panelKind,
        activityFilters,
        operationKindFilters,
        teamMemberUserFilters,
    }) => {
    const primaryColor = COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind];
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
    const {t} = useTranslationFile(TranslationFile.Calendar);

    return (
        <>
            <DivCore className="flex flex-col h-full w-full divide-y-2">

                {/* Mobile filter dialog */}
                <Dialog open={mobileFiltersOpen} onClose={setMobileFiltersOpen} className="relative z-40 @5xl:hidden">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                    />

                    <div className="fixed inset-0 z-40 flex">
                        <DialogPanel
                            transition
                            className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
                        >
                            <div className="flex items-center justify-between px-4">
                                <h2 className="text-lg font-medium text-gray-900">{t('filtersTitle')}</h2>
                                <button
                                    type="button"
                                    onClick={() => setMobileFiltersOpen(false)}
                                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                >
                                    <span className="sr-only">{t('closeMenu')}</span>
                                    <HiXMark aria-hidden="true" className="h-6 w-6"/>
                                </button>
                            </div>

                            {/* Filters */}
                            <form className="mt-4 border-t border-gray-200">
                                <h3 className="sr-only">{t("filtersTitle")}</h3>
                                <ExpanderCore
                                    filters={activityFilters}
                                    title={t("activitiesTitle")}
                                    id="activity"
                                    color={primaryColor}/>
                                <ExpanderCore
                                    filters={operationKindFilters}
                                    title={t("operationsTitle")}
                                    id="operationKind"
                                    color={primaryColor}/>
                                <ExpanderCore
                                    filters={teamMemberUserFilters}
                                    title={t("veterinariansTitle")}
                                    id="teamMembers"
                                    color={primaryColor}/>

                            </form>
                        </DialogPanel>
                    </div>
                </Dialog>

                <DivCore className="flex flex-col divide-y-1">
                    <div className="flex justify-between items-center pb-2 border-b-2 border-pink-400">
                        <h1 className="text-2xl font-bold tracking-tight ">{t("filtersTitle")}</h1>

                        <div className="flex items-center">
                            <ButtonCoreNoBorder
                                onClick={() => setMobileFiltersOpen(true)}
                                className="text-gray-400 p-0
                                 hover:text-gray-500
                                  @5xl:hidden"
                            >
                                <span className="sr-only">{t("filtersTitle")}</span>
                                <HiFunnel aria-hidden="true" className="h-7 w-7 m-2"/>
                            </ButtonCoreNoBorder>
                        </div>
                    </div>

                    <section aria-labelledby="filters">
                        <h2 id="products-filters" className="sr-only">
                            {t('filtersTitle')}
                        </h2>

                        {/* Filters */}
                        <form className="hidden @5xl:block">
                            <h3 className="sr-only">{t('filtersTitle')}</h3>
                            <ExpanderCore
                                filters={activityFilters}
                                title={t('activitiesTitle')}
                                id="activity"
                                color={primaryColor}/>
                            <ExpanderCore
                                filters={operationKindFilters}
                                title={t('operationsTitle')}
                                id="operationKind"
                                color={primaryColor}/>
                            <ExpanderCore
                                filters={teamMemberUserFilters}
                                title={t('veterinariansTitle')}
                                id="teamMembers"
                                color={primaryColor}/>
                        </form>
                    </section>
                </DivCore>
            </DivCore>
        </>
    );
};

interface ExpanderCoreProps<T extends IFilterAdapter> {
    filters: T[];
    title: string;
    id: string;
    color: TabColors;
}

const ExpanderCore = <T extends IFilterAdapter>(
    {
        filters,
        title,
        id,
        color,
    }: ExpanderCoreProps<T>): JSX.Element => {
    const {t} = useTranslationFile(TranslationFile.Calendar);
    const textColor = COLOR_TAB_TEXT_AND_TEXT_HOVER[color];
    return (
        <Disclosure key={id} as="div"
                    className="border-b border-theme-border dark:border-theme-border-dark py-2 flex flex-col w-full flex-1">
            {({open}) => (
                <>
                    <h3 className="flow-root">
                        <DisclosureButton className={`group flex w-full items-center justify-between py-3 text-sm ${textColor}`}>
                            <span className="font-semibold">{title}</span>
                            <span className="ml-6 flex items-center">
                                 <motion.span
                                     className="ml-6 flex items-center"
                                     animate={{rotate: open ? -180 : 0}}
                                     transition={{duration: 0.2}}>
                                    <HiChevronDown className="mx-2 h-5 w-5" aria-hidden="true"/>
                                </motion.span>
                            </span>
                        </DisclosureButton>
                    </h3>
                    <DisclosurePanel className="py-1">
                        <div className="space-y-3">
                            {filters.map((filter) => {
                                return (
                                    <div key={filter.uniqueKey} className="flex items-center">
                                        <CheckboxField className="w-full">
                                            <Checkbox
                                                name="discoverability"
                                                value="show_on_events_page"
                                                onChange={(e) => filter.onIsCheckedChanged(e)}
                                                checked={filter.isChecked}/>
                                            <Label className="w-full">{filter.getFilterText(t)}</Label>
                                        </CheckboxField>
                                    </div>
                                );
                            })}
                        </div>
                    </DisclosurePanel>
                </>
            )}
        </Disclosure>
    );
};

export default CalendarFilters;
