
export default function Help() {
    return (
        <>
            <header>Help</header>
            {Array.from({length: 100}, (_, index) => (
                <div key={index}>SCROLL ...</div>
            ))}
        </>
    )
}
