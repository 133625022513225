import {ButtonHTMLAttributes, FC} from "react";
import ButtonCore from "../controls/buttonCore.tsx";
import {useSelector} from "react-redux";
import {selectIsDarkTheme, setIsDarkTheme} from "../../pages/settings/settingsSlice.ts";
import {useAppDispatch} from "../../stores/reduxHooks.ts";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";


const ThemeToggle: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({...props}) => {
    const isDarkTheme = useSelector(selectIsDarkTheme);
    const dispatch = useAppDispatch();
    const {t} = useTranslationFile(TranslationFile.Panels);

    return (
        <ButtonCore onClick={() => dispatch(setIsDarkTheme(!isDarkTheme))}
                    {...props}>
            {isDarkTheme
                ? `${t('switchToLightTheme')}`
                : `${t('switchToDarkTheme')}` }
        </ButtonCore>
    );
};

export default ThemeToggle;
