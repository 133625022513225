import {FC} from "react";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import ButtonCore from "../../ui/controls/buttonCore.tsx";
import {COLOR_PANEL_KIND_PRIMARY_COLORS, COLOR_PANEL_KIND_SECONDARY_COLORS, COLOR_TAB_TEXT_100, COLOR_TAB_TEXT_AND_TEXT_HOVER, PanelKindProps} from "../../ui/colors/colorConfiguration.tsx";
import {Avatar} from "../../ui/catalyst_ui/avatar.tsx";
import {getUserInitials} from "../../ui/profil/ProfilBagde.tsx";
import {useSelector} from "react-redux";
import {selectUserInfo} from "../signin/signInSlice.ts";
import DivCore from "../../ui/controls/divCore.tsx";
import InputCore from "../../ui/controls/InputCore.tsx";

const YourProfile: FC<PanelKindProps> = ({panelKind}) => {
    const inviteCode = "ABC123XYZ"; // TODO use real code
    const userInfo = useSelector(selectUserInfo);
    const primaryColor = COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind];
    const secondaryColor = COLOR_PANEL_KIND_SECONDARY_COLORS[panelKind];
    const primaryTextColor = COLOR_TAB_TEXT_100[primaryColor];
    const handleCopyCode = () => {
        navigator.clipboard.writeText(inviteCode).then(() => {
            alert(t("codeCopiedToClipboard"));
        }).catch((error) => {
            console.error("Failed to copy code: ", error);
        });
    };
    const {t} = useTranslationFile(TranslationFile.Account);
    return (
        <DivCore className="mx-auto max-w-7xl px-0 @md:px-2 @2xl:px-6">
            <div className="divide-y divide-theme-border dark:divide-theme-border-dark">
                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 @2xl:grid-cols-3">
                    <div>
                        <h2 className={`text-base/7 font-semibold ${primaryTextColor}`}>
                            {t("personalInformation")}
                        </h2>
                        <p className="mt-1 text-sm text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark font-medium">
                            {t("personalInformationDetails")}
                        </p>
                    </div>

                    <form className="@2xl:col-span-2">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 @xl:max-w-xl @xl:grid-cols-6">
                            <div className="col-span-full flex items-center gap-x-8">
                                <Avatar initials={getUserInitials(userInfo)} className="size-24 font-semibold"
                                        style={{backgroundColor: userInfo?.userColor}}/>
                                <div>
                                    <ButtonCore type="button">
                                        {t("changeColor")}
                                    </ButtonCore>
                                    <p className="mt-2 text-xs text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark">colors use hexadecimal values</p>
                                </div>
                            </div>

                            <div className="@xl:col-span-3">
                                <label htmlFor="first-name" className="block text-sm font-semibold">
                                    {t("firstName")}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    className="mt-2"
                                    id="first-name"
                                    name="first-name"
                                    type="text"
                                    value={userInfo?.userFirstName}
                                    autoComplete="given-name"
                                />
                            </div>

                            <div className="@xl:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-semibold">
                                    {t("lastName")}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    className="mt-2"
                                    id="last-name"
                                    name="last-name"
                                    type="text"
                                    autoComplete="family-name"
                                    value={userInfo?.userLastName}
                                />
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="email" className="block text-sm font-semibold">
                                    {t("emailAddress")}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    readOnly={true}
                                    className="mt-2"
                                    id="email"
                                    name="email"
                                    type="email"
                                    value="TODO@todo.com"
                                    autoComplete="email"
                                />
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="userCode" className="block text-sm font-semibold">
                                    {t("userCode")}
                                </label>
                                <div className="flex flex-row flex-1 items-center gap-3">
                                    <InputCore
                                        colorKind={primaryColor}
                                        className="mt-2"
                                        id="userCode"
                                        name="userCode"
                                        placeholder="21BDCC54-3E29-4862-95BF-C1D96DEE6AE3"
                                        type="text"
                                    />
                                    <ButtonCore
                                        colorKind={secondaryColor}
                                        className="flex text-sm px-5 mt-2"
                                        onClick={() => handleCopyCode()}>
                                        {t("copy")}
                                    </ButtonCore>
                                </div>

                                <a href="#" className={`text-sm ${COLOR_TAB_TEXT_AND_TEXT_HOVER[primaryColor]}`}>
                                    {t("resetClinicCode")}
                                </a>
                                <p className="mt-2 text-xs/5 text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark">Vous pouvez communiquer ce code à votre clinique afin qu'elle puisse vous ajouter en tant que membre d'équipe</p>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="timezone" className="block text-sm font-semibold">
                                    {t("subscription")}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    readOnly={true}
                                    className="mt-2"
                                    id="subscription"
                                    name="subscription"
                                    value="Premium"
                                    type="text"
                                />
                            </div>
                        </div>

                        <div className="mt-8 flex gap-3">
                            <ButtonCore
                                className="min-w-24"
                                type="submit">
                                {t("reset")}
                            </ButtonCore>
                            <ButtonCore colorKind={secondaryColor}
                                        className="min-w-24"
                                        type="submit">
                                {t("save")}
                            </ButtonCore>
                        </div>
                    </form>
                </div>
            </div>
        </DivCore>
    );
};

export default YourProfile;

