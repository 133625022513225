import {Server} from "../generated/Server.ts";
import {ApiConfig, RequestParams} from "../generated/http-client.ts";
import {ICookieTokenConfig} from "../LoginDataHolder.ts";

export interface IServer {
    baseUrl: string;

    apiProxyServerUrl(params?: RequestParams): Promise<string>;

    ping(params?: RequestParams): Promise<void>;

    pingAuthenticated(params?: RequestParams): Promise<void>;

    getCurrentUtcTime(params?: RequestParams): Promise<string>;
}

/**
 * Wrapper of Server generated code
 */
export class WrapperServer<SecurityDataType = unknown> implements IServer {
    private readonly wrappedServer: Server<SecurityDataType>;
    readonly baseUrl: string;
    readonly loginData: ICookieTokenConfig;

    constructor(loginData: ICookieTokenConfig, apiConfig: ApiConfig<SecurityDataType> = {}) {
        this.wrappedServer = new Server(apiConfig);
        this.baseUrl = this.wrappedServer.baseUrl;
        this.loginData = loginData;
    }

    apiProxyServerUrl = async (params: RequestParams = {}): Promise<string> => this.wrappedServer.apiProxyServerUrlList(params);

    ping = async (params: RequestParams = {}): Promise<void> =>
        this.wrappedServer.pingList(params);

    pingAuthenticated = async (params: RequestParams = {}): Promise<void> =>
        this.wrappedServer.pingAuthenticatedList(this.loginData.withAuthentication(params));

    getCurrentUtcTime = async (params: RequestParams = {}): Promise<string> =>
        this.wrappedServer.getCurrentUtcTimeList(params);
}
