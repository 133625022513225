import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../stores/sliceNames.ts";
import ITabIndexedState, {getOrDefault, removeTabState, updateTabState} from "../../ui/tab_panels/tabIndexedState.ts";

interface IHelpCounter {
    tabUuid: string;
    currentCount: number;
}

export interface IHelpState {
    count: number;
}

const counterSelector = (state: ITabIndexedState<IHelpState>, tabUuid: string) => getOrDefault(state, tabUuid).count;

const initialState: ITabIndexedState<IHelpState> = {
    initialState: {count: 0},
    tabStates: []
};

const helpSlice = createSlice({
    name: SliceNames.HELP,
    initialState: initialState,
    reducers: {
        // WARNING : Register this action in registeredRemoveTabsSlices in tabNotifierMiddleware.ts
        cleanHelpTabs : (state, action: PayloadAction<string>) => {
            removeTabState(state, action.payload);
        },
        incrementHelp: (state, action: PayloadAction<IHelpCounter>) => {
            updateTabState(state, action.payload.tabUuid, o => {
                o.count = action.payload.currentCount;
            });
        }
    },
    selectors: {
        counterHelp: counterSelector,
    }
});

export const {incrementHelp, cleanHelpTabs} = helpSlice.actions;
export const {counterHelp} = helpSlice.selectors;

const helpSliceReducer = helpSlice.reducer;
export default helpSliceReducer;
