import {IPanelPart} from "../panelSlice.ts";
import {NavigationKey} from "../../navigation/navigationKey.tsx";
import {ActionCreatorWithPayload, PayloadAction} from "@reduxjs/toolkit";
import {cleanStatisticsTabs} from "../../../pages/statistics/statisticsSlice.ts";
import {cleanPlanningTabs} from "../../../pages/planning/planningSlice.ts";
import {RootState} from "../../../stores/rootState.ts";
import {addError, ErrorLevel, IError} from "../../errors/errorSlice.ts";
import {cleanHospitalizationTabs} from "../../../pages/hospitalization/hospitalizationSlice.ts";
import {cleanProductTabs} from "../../../pages/products/productsSlice.ts";
import {cleanFinancialTabs} from "../../../pages/financial/financialSlice.ts";
import {cleanHelpTabs} from "../../../pages/help/helpSlice.ts";
import {cleanAppointmentsTabs} from "../../../pages/appointments/appointmentSlice.ts";
import {cleanCustomersDashboardTabs} from "../../../pages/customers/dashboard/customerDashboardSlice.ts";
import {cleanCustomerRecordTabs} from "../../../pages/customers/customerRecord/customerRecordSlice.ts";
import {cleanAnimalRecordTabs} from "../../../pages/customers/animalRecord/animalRecordSlice.ts";

// the clean tabs action of every slice. They need to be all registered otherwise, their store will not be cleanup
const registeredRemoveTabsSlices = new Map<NavigationKey, ActionCreatorWithPayload<string, string>>([
    [NavigationKey.Statistics, cleanStatisticsTabs],
    [NavigationKey.Planning, cleanPlanningTabs],
    [NavigationKey.Hospitalization, cleanHospitalizationTabs],
    [NavigationKey.Products, cleanProductTabs],
    [NavigationKey.Financial, cleanFinancialTabs],
    [NavigationKey.CustomersDashboard, cleanCustomersDashboardTabs],
    [NavigationKey.AnimalRecord, cleanAnimalRecordTabs],
    [NavigationKey.CustomerRecord, cleanCustomerRecordTabs],
    [NavigationKey.Help, cleanHelpTabs],
    [NavigationKey.Appointment, cleanAppointmentsTabs],
]);

// tabs that even if duplicated will use the same data context in Redux.
// They are explicitly set to avoid raising a false positive warning
const explicitAllTabsOnSameContext = new Set<NavigationKey>([
    NavigationKey.Account,
    NavigationKey.Settings,
    NavigationKey.WaitingRoom,
]);

export class TabMiddlewareHelpers {
    /**
     * Remove the tab matching slice if the tab is defined. If no tab is found among registered tab,
     * log a warning as it may indicate a slice registration is missing
     */
    static RemoveMatchingSliceFromStore(dispatch: (action: PayloadAction<string | IError>) => void, tab: IPanelPart | undefined) {
        if (tab) {
            console.log(`remove tab from store`, tab);
            const sliceToClean = registeredRemoveTabsSlices.get(tab.navigationKey);
            if (sliceToClean) {
                const payloadAction: PayloadAction<string, string> = sliceToClean(tab.id);
                dispatch(payloadAction);

                // exclude specific tabs explicitly and raise warning if not
            } else if (!explicitAllTabsOnSameContext.has(tab.navigationKey)){
                dispatch(addError({
                    code: 5120,
                    level: ErrorLevel.Warning,
                    messageTranslationKey: "message_error_slice_tab_not_found",
                    translationParams: [
                        {key: "navKey", value: tab.navigationKey},
                        {key: "sliceInfo", value: tab.relatedHRef}
                    ]
                }));
            }
        }
    }

    static CleanPreviouslyNavigatedLeftTabIfNeeded(
        previousState: RootState,
        nextState: RootState,
        dispatch: (action: PayloadAction<string | IError>) => void) {

        //  As, we want the case where we are navigating on the SAME tab, we only consider same active id
        if (previousState.panels.leftPartActiveId === nextState.panels.leftPartActiveId) {
            // if same active id, we will clean data as soon as the relatedNavigation key differ
            const previousLeftTab = previousState.panels.leftPartTabs.find(o => o.id == previousState.panels.leftPartActiveId);

            if (previousLeftTab) {

                const nextLeftTab = nextState.panels.leftPartTabs.find(o => o.id == nextState.panels.leftPartActiveId);

                if (nextLeftTab && previousLeftTab.navigationKey !== nextLeftTab.navigationKey) {
                    console.log(`remove previous left tab from store after navigation`, previousLeftTab);
                    TabMiddlewareHelpers.RemoveMatchingSliceFromStore(dispatch, previousLeftTab);
                }
            }

        }
    }
}
