export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

/**
 * If the current build is in development or production
 */
export const isInDevelopment = import.meta.env.MODE === 'development';

export function clamp(value: number, min: number, max: number): number {
    if (min > max) {
        throw new RangeError("min should be lower than max");
    }
    return Math.min(Math.max(value, min), max);
}

/**
 * Return the index, clamped by given array size
 */
export function clampFromArray<T>(value: number, array: T[]): number {
    return Math.min(Math.max(value, 0), array.length - 1);
}

export  function aggregatePaths(...paths: string[]) {
    return paths.join("");
}

/**
 * Return the index where to move the item depending on the item position and target index.
 * Return null if no move should be done
 */
export function computeIndexForMoveTabs(targetIndex: number, currentIndex: number): number | null {
    // allow to be over max size to add at the end but cap min
    const targetIndexCap = Math.max(targetIndex, 0);
    // if current or next, no move is needed (check unit tests for details)
    if (targetIndexCap === currentIndex || targetIndexCap === currentIndex + 1) {
        return null;
    }
    // else, if lower than current position, return it. If higher, remove 1 to compensate current item
    if (targetIndexCap < currentIndex) {
        return targetIndexCap;
    }
    return targetIndexCap - 1;
}

export function lastOrDefault<T>(array: T[], defaultValue: T | undefined = undefined): T | undefined {
    return array.length > 0 ? array[array.length - 1] : defaultValue;
}

export function last<T>(array: T[]): T {
    if (array.length === 0) {
        throw new Error("empty array on last call");
    }
    return array[array.length - 1];
}

export function moveItem<T>(array: T[], item: T, toIndex: number): T[] {
    const fromIndex = array.indexOf(item);

    // Check if the item is in the array else do nothing
    if (fromIndex === -1) {
        return array;
    }

    // Check index bounds : allow to be over max size to add at the end but cap min (slice allow it)
    const targetIndex = Math.max(toIndex, 0);

    // Do nothing if the item is already at the correct position
    if (fromIndex === targetIndex) {
        return array;
    }

    // Move the item
    array.splice(fromIndex, 1);
    array.splice(targetIndex, 0, item);
    return array;
}
