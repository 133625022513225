import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {clamp, computeIndexForMoveTabs, moveItem} from "../../lib/utilsVcs.ts";
import SliceNames from "../../stores/sliceNames.ts";
import {PanelKind} from "./panelKind.ts";
import {Constants} from "../../lib/helpers/constants.ts";
import {createSelector} from "reselect";
import {cleanSavedRightIdIfNeeded, createNewTab, findTab, focusOrCreateTab, isTabLimitReached, removeFromLeft, removeFromRight, replaceTab, shouldDisableMoveToRight} from "./panelSliceHelper.ts";
import IDestination from "../navigation/destinationHelper.ts";
import {NavigationKey} from "../navigation/navigationKey.tsx";
import {Destinations} from "../navigation/destinations.ts";

export interface IPanelPart {
    isRootMenu: boolean;
    navigationKey: NavigationKey;
    relatedHRef: string;
    titleTranslationKey: string;
    titleTranslationKeyParams?: Record<string, string>;
    id: string;
}


/**
 * A subset of main state with only right and left panel parts and selected ones
 */
export interface IPanelStateTabsArraySubset {
    rightPartTabs: IPanelPart[];
    leftPartTabs: IPanelPart[];
    // Uuid of the right active part
    rightPartActiveId?: string;
    // Uuid of the left active part
    leftPartActiveId: string;
}

/**
 * Main interface of panel state
 */
export interface IPanelState extends IPanelStateTabsArraySubset {
    splitterPercentageLeft: number;
    // when we set the compact mode
    isCompactMode: boolean;
    rightTabCompactSavedIds: string[];
    rightTabCompactSavedActiveId?: string;
}

const initialTab = createNewTab(Destinations.Planning);
const initialState: IPanelState = {
    splitterPercentageLeft: 60, // 60% left / 40% right initially
    rightPartTabs: [],
    leftPartTabs: [initialTab],
    rightPartActiveId: undefined,
    leftPartActiveId: initialTab.id,
    rightTabCompactSavedIds: [],
    isCompactMode: false,
};

interface PanelMovePayload {
    panelToMoveId: string; // warning, do not give reference here as it is not working with RTK (see readme)
    destinationPanel: PanelKind;
    destinationIndex: number;
    selectTabAfterMove: boolean;
}

// Data structure used to update the destination of a panel without closing it
interface TabToUpdatePayload {
    tabUuid: string;
    destination: IDestination;
}

const leftPartTabsSelector = (state: IPanelState) => state.leftPartTabs;
const rightPartTabsSelector = (state: IPanelState) => state.rightPartTabs;
const activeRightPartIdSelector = (state: IPanelState) => state.rightPartActiveId;
const activeLeftPartIdSelector = (state: IPanelState) => state.leftPartActiveId;
const splitterPercentageLeftSelector = (state: IPanelState) => state.splitterPercentageLeft;

// ====  Selector combine and memoized ====
export const selectLeftPartTabsAndActive = createSelector(
    [leftPartTabsSelector, activeLeftPartIdSelector],
    (leftPartTabs: IPanelPart[], activeLeftPartId: string) => ({
        leftPartTabs,
        activeLeftPart: leftPartTabs.find(tab => tab.id === activeLeftPartId)!
    })
);

export const selectTabLimitReached = createSelector(
    [leftPartTabsSelector, rightPartTabsSelector],
    (leftPartTabs, rightPartTabs) => isTabLimitReached(leftPartTabs, rightPartTabs)
);

export const selectDisableMoveToRight = createSelector(
    [leftPartTabsSelector, rightPartTabsSelector],
    (leftPartTabs, rightPartTabs) => shouldDisableMoveToRight(leftPartTabs, rightPartTabs)
);

export const selectGetActiveLeftTab = createSelector(
    [leftPartTabsSelector, activeLeftPartIdSelector],
    (leftPartTabs, leftPartActiveId) => leftPartTabs.find(o => o.id === leftPartActiveId)!
);

export const selectHasRightPart = createSelector(
    [rightPartTabsSelector],
    (rightPartTabs) => rightPartTabs.length !== 0
);


// ===== slice creation
const panelSlice = createSlice({
    name: SliceNames.PANELS,
    initialState,
    reducers: {
        createTab: (state) => {
            if (isTabLimitReached(state.leftPartTabs, state.rightPartTabs)) {
                console.log(`trying to create a tab while having reach the tab limit [${Constants.TABS_COUNT_THRESHOLD}] is not allowed as it will lead to exceed this threshold`);
                return;
            }
            const createdTab = createNewTab(Destinations.Planning);
            state.leftPartActiveId = createdTab.id;
            state.leftPartTabs.push(createdTab);
        },
        // create a secondary tab directly on right or focus depending on policy
        focusOrCreateSecondaryTab: (state, action: PayloadAction<IDestination>) => {
            focusOrCreateTab(state, PanelKind.Right, action.payload);
        },

        // create a primary tab directly on left or focus depending on policy
        focusOrCreatePrimaryTab: (state, action: PayloadAction<IDestination>) => {
            focusOrCreateTab(state, PanelKind.Left, action.payload);
        },

        updateTab: (state, action: PayloadAction<TabToUpdatePayload>) => {
            replaceTab(state, action.payload.tabUuid, action.payload.destination);
        },
        removeTab: (state, action: PayloadAction<string>) => {
            const tab = findTab(state, action.payload);
            if (tab === undefined) {
                console.log(`a remove of panel ${action.payload} has been requested but it has not been found in any panel part`);
                return;
            }
            switch (tab.currentKind) {
                case PanelKind.Left: {
                    removeFromLeft(state, tab.index, tab.isActive);
                    break;
                }
                case PanelKind.Right: {
                    removeFromRight(state, tab.index, tab.isActive);
                    break;
                }
            }

            // clean saved id if needed to ensure store coherent state (even if the restoration tolerate incoherence
            cleanSavedRightIdIfNeeded(state, tab.current.id);
        },
        // change the currently active tab using its uuid (as string)
        setActiveTab: (state, action: PayloadAction<string>) => {
            const tab = findTab(state, action.payload);
            if (tab === undefined) {
                console.log(`a set active panel ${action.payload} has been requested but pane has not been found`);
                return;
            }
            if (tab.isActive) {
                // already active
                return;
            }
            switch (tab.currentKind) {
                case PanelKind.Left:
                    state.leftPartActiveId = action.payload;
                    break;
                case PanelKind.Right:
                    state.rightPartActiveId = action.payload;
                    break;

            }
        },
        restoreFromCompactTabsSetup: (state) => {
            // restore all tabs that has been moved if any and if they were not deleted before
            if (state.rightTabCompactSavedIds.length !== 0) {
                const rightTabsIds = new Set(state.rightTabCompactSavedIds);
                const restoredRightTabs = new Set<string>();
                // reverse for as we will remove items
                for (let i = state.leftPartTabs.length - 1; i >= 0; i--) {
                    const leftTab = state.leftPartTabs[i];
                    // for any tabs that was on right, set it back
                    if (rightTabsIds.has(leftTab.id)) {
                        restoredRightTabs.add(leftTab.id);
                        removeFromLeft(state, i, leftTab.id === state.leftPartActiveId);
                        // add in right
                        state.rightPartTabs.push(leftTab);
                    }
                }
                // then, set back the active right tab if the tab still exists:
                if (state.rightTabCompactSavedActiveId && restoredRightTabs.has(state.rightTabCompactSavedActiveId)) {
                    state.rightPartActiveId = state.rightTabCompactSavedActiveId;
                } else {
                    // select the first one IF IT EXISTS or undefined
                    state.rightPartActiveId = state.rightPartTabs[0]?.id;
                }
                // finally, clear saved data
                state.rightTabCompactSavedIds = [];
                state.rightTabCompactSavedActiveId = undefined;
            }
            state.isCompactMode = false;
        },
        setCompactTabsSetup: (state) => {
            // to avoid ambiguity on tabs selection changed we move all right tabs to the left
            if (state.rightPartTabs.length !== 0) {
                // save right setup (which tab and which active one)
                state.rightTabCompactSavedIds = state.rightPartTabs.map(o => o.id);
                state.rightTabCompactSavedActiveId = state.rightPartActiveId;
                // push all right tabs in left part
                state.leftPartTabs.push(...state.rightPartTabs);

                // clear right tabs and current right tab
                state.rightPartTabs = [];
                state.rightPartActiveId = undefined;
            }
            state.isCompactMode = true;
        },
        moveTab: (state, action: PayloadAction<PanelMovePayload>) => {
            const {panelToMoveId, destinationPanel, destinationIndex} = action.payload;
            const tab = findTab(state, panelToMoveId);

            if (tab === undefined) {
                console.log(`a move panel ${panelToMoveId} has been requested but it has not been found`);
                return;
            }

            // specific case: if compact mode, we do not allow a move that is not within main (LEFT) tab, as only them are displayed.
            if (state.isCompactMode && (destinationPanel !== PanelKind.Left || tab.currentKind !== PanelKind.Left)) {
                return;
            }

            // Add panel to destination:
            // ======> FROM LEFT
            if (tab.currentKind == PanelKind.Left) {
                switch (destinationPanel) {
                    case PanelKind.Left: {
                        // ======> TO LEFT = same panel we move only it index is different AND WE DO NOT CHANGE THE CURRENTLY SELECTED ITEM
                        const targetIndex = computeIndexForMoveTabs(destinationIndex, tab.index);
                        if (targetIndex !== null) {
                            state.leftPartTabs = moveItem(state.leftPartTabs, tab.current, targetIndex);
                        }
                        return;
                    }
                    case PanelKind.Right: {
                        // ======> TO RIGHT = remove from left and add to right
                        // THERE IS ONE EXCEPTION in this case:
                        //  - if the tab is the last on left
                        //  - AND
                        //  - if the threshold tab count is reached,
                        //  => WE DO NOT ALLOW THE MOVE as it will lead to creating another tab
                        if (shouldDisableMoveToRight(state.leftPartTabs, state.rightPartTabs)) {
                            console.log(`trying to move the tab on left while not allowed`);
                            return;
                        }
                        removeFromLeft(state, tab.index, tab.isActive);
                        // allow to be over max size to add at the end but cap min
                        const targetIndexRight = Math.max(destinationIndex, 0);
                        state.rightPartTabs.splice(targetIndexRight, 0, tab.current);

                        if (action.payload.selectTabAfterMove) {
                            // WE CHANGE THE SELECTED ITEM WHEN PANEL CHANGE
                            state.rightPartActiveId = tab.current.id;
                        }
                        return;
                    }
                }
            }
            // ======> FROM RIGHT
            else {
                switch (destinationPanel) {
                    case PanelKind.Right: {
                        // ======> TO RIGHT = same panel we move only it index is different AND WE DO NOT CHANGE THE CURRENTLY SELECTED ITEM
                        const targetIndex = computeIndexForMoveTabs(destinationIndex, tab.index);
                        if (targetIndex !== null) {
                            state.rightPartTabs = moveItem(state.rightPartTabs, tab.current, targetIndex);
                        }
                        return;
                    }
                    case PanelKind.Left: {
                        // ======> TO LEFT = remove from right and add to left
                        removeFromRight(state, tab.index, tab.isActive);
                        // allow to be over max size to add at the end but cap min
                        const targetIndexLeft = Math.max(destinationIndex, 0);
                        state.leftPartTabs.splice(targetIndexLeft, 0, tab.current);
                        if (action.payload.selectTabAfterMove) {
                            // WE CHANGE THE SELECTED ITEM WHEN PANEL CHANGE
                            state.leftPartActiveId = tab.current.id;
                        }
                        return;
                    }
                }
            }
        },
        updateSplitPercentageLeft: (state, action: PayloadAction<number>) => {
            state.splitterPercentageLeft = clamp(action.payload, 0, 100);
        },
    },
    selectors: {
        leftPartTabs: leftPartTabsSelector,
        rightPartTabs: rightPartTabsSelector,
        getActiveRightPartId: activeRightPartIdSelector,
        getActiveLeftPartId: activeLeftPartIdSelector,
        getSplitterPercentageLeft: splitterPercentageLeftSelector,
        getActiveLeftPart: selectGetActiveLeftTab,
        leftPartTabsAndActive: selectLeftPartTabsAndActive,
        tabLimitReached: selectTabLimitReached,
        disableMoveToRight: selectDisableMoveToRight,
        hasRightPart: selectHasRightPart,
    },
});

export const {
    createTab,
    focusOrCreateSecondaryTab,
    focusOrCreatePrimaryTab,
    removeTab,
    updateTab,
    moveTab,
    setActiveTab,
    updateSplitPercentageLeft,
    restoreFromCompactTabsSetup,
    setCompactTabsSetup,
} = panelSlice.actions;
export const {
    leftPartTabs,
    rightPartTabs,
    getActiveRightPartId,
    getActiveLeftPartId,
    leftPartTabsAndActive,
    disableMoveToRight,
    tabLimitReached,
    getActiveLeftPart,
    getSplitterPercentageLeft,
    hasRightPart,
} = panelSlice.selectors;

const panelSliceReducer = panelSlice.reducer;
export default panelSliceReducer;
