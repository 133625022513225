import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../stores/sliceNames.ts";
import ITabIndexedState, {getOrDefault, removeTabState, updateTabState} from "../../ui/tab_panels/tabIndexedState.ts";

interface IAppointmentCounter {
    tabUuid: string;
    currentCount: number;
}

export interface IAppointmentState {
    counter : number;
}

const counterSelector = (state: ITabIndexedState<IAppointmentState>, tabUuid: string) => getOrDefault(state, tabUuid).counter;

const initialState: ITabIndexedState<IAppointmentState> = {
    initialState: {counter: 0},
    tabStates: []
};

const appointmentSlice = createSlice({
    name: SliceNames.APPOINTMENT,
    initialState: initialState,
    reducers: {
        // WARNING : Register this action in registeredRemoveTabsSlices in tabNotifierMiddleware.ts
        cleanAppointmentsTabs : (state, action: PayloadAction<string>) => {
            removeTabState(state, action.payload);
        },
        incrementAppointments: (state, action: PayloadAction<IAppointmentCounter>) => {
            updateTabState(state, action.payload.tabUuid, o => {
               o.counter = action.payload.currentCount;
            });
        }
    },
    selectors: {
        counterStat: counterSelector,
    }
});

export const {incrementAppointments, cleanAppointmentsTabs} = appointmentSlice.actions;
export const {counterStat} = appointmentSlice.selectors;

const appointmentSliceReducer = appointmentSlice.reducer;
export default appointmentSliceReducer;
