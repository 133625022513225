import DivCore from "../../ui/controls/divCore.tsx";
import YourProfile from "./yourProfil.tsx";
import {FC} from "react";
import ITabDefinition from "../tabDefinition.ts";
import PasswordUpdatePart from "./passwordUpdatePart.tsx";
import ClinicInfoPart from "./ClinicInfoPart.tsx";
import RelateSitesPart from "./RelatedSitesPart.tsx";
import DangerZonePart from "./dangerZonePart.tsx";
import TeamViewPart from "./teamViewPart.tsx";
import ButtonCore from "../../ui/controls/buttonCore.tsx";
import {COLOR_PANEL_KIND_PRIMARY_COLORS} from "../../ui/colors/colorConfiguration.tsx";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import {useNavigate} from "react-router-dom";
import {Subscriptions_Path} from "../../routes.ts";


const Accounts: FC<ITabDefinition> = ({panelKind}) => {
    const primaryColor = COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind];
    const {t} = useTranslationFile(TranslationFile.Account);
    const navigate = useNavigate()
    return (
        <DivCore className="flex flex-col divide-y divide-theme-border dark:divide-theme-border-dark">
            <ButtonCore colorKind={primaryColor}
                        onClick={() => navigate(Subscriptions_Path)}
                        className="flex flex-row flex-1 m-6">{t('subscribeForNewClinic')}</ButtonCore>
            <YourProfile panelKind={panelKind}/>
            <PasswordUpdatePart panelKind={panelKind}/>
            <ClinicInfoPart panelKind={panelKind}/>
            PARTIE SUB AVEC MODIF DUREE, RAJOUTER SUB...
            <RelateSitesPart panelKind={panelKind}/>
            <TeamViewPart panelKind={panelKind}/>
            <DangerZonePart panelKind={panelKind}/>
        </DivCore>
    );
};
export default Accounts;
