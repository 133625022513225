

export default function Products() {

    // const [data] = useState<RandomData[] | null>(null);
    // const [timeout, setTimeout] = useState<string>("500");
    //
    // const fetchData = async () => {
    //     // console.log("call https://localhost:5250/MiscData/GetRandomDataNumber")
    //     // const response = await fetch('https://localhost:5247/MiscData/GetRandomDataNumber');
    //     // const data: RandomData[] = await response.json();
    //     // setData(data);
    // };
    //
    // const fetchDataWithDelay = async () => {
    //     // console.log("call https://localhost:5250/MiscData/GetRandomDataNumberWithDelay?millisecondsDelay=")
    //     // const response = await fetch('https://localhost:5250/MiscData/GetRandomDataNumberWithDelay?millisecondsDelay=' + timeout);
    //     // const data: RandomData[] = await response.json();
    //     // setData(data);
    // }; TODO TO DELETE
    return (
        <>
            <header>PRODUCT</header>
            {Array.from({length: 80}, (_, index) => (
                <div key={index}>SCROLL PART</div>
            ))}
            {/*<div className="flex flex-col">*/}

            {/*<div className="flex">*/}
            {/*    <Button className="h-10 m-4" color="light" onClick={fetchData}>Call GetRandomDataNumber</Button>*/}
            {/*    <div className="flex flex-col m-4">*/}
            {/*        <Field className="p-4">*/}
            {/*            <Label>Timeout in milliseconds</Label>*/}
            {/*            <Input name="full_name" pattern="[0-9]*"*/}
            {/*                   value={timeout}*/}
            {/*                   onChange={e => setTimeout(e.target.value)}/>*/}
            {/*        </Field>*/}

            {/*        <Button className="h-10" color="dark/zinc" onClick={fetchDataWithDelay}>Call GetRandomDataNumber WithDelay</Button>*/}
            {/*    </div>*/}

            {/*</div>*/}
            {/*{data && <div>{JSON.stringify(data)}</div>}*/}
            {/*</div>*/}
        </>
    )
}
