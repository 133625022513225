import {FC} from "react";
import {HeadingCore} from "../../../ui/controls/HeadingCore.tsx";
import ButtonCore from "../../../ui/controls/buttonCore.tsx";
import {focusOrCreateSecondaryTab} from "../../../ui/tab_panels/panelSlice.ts";
import {Destinations} from "../../../ui/navigation/destinations.ts";
import {HiPlus} from "react-icons/hi2";
import {COLOR_PANEL_KIND_PRIMARY_COLORS, COLOR_PANEL_KIND_SECONDARY_COLORS} from "../../../ui/colors/colorConfiguration.tsx";
import {useDispatch} from "react-redux";
import {PanelKind} from "../../../ui/tab_panels/panelKind.ts";
import {TranslationFile, useTranslationFile} from "../../../hooks/translations.ts";
import {CiClock2} from "react-icons/ci";
import {changeDashboardMode, selectedMode} from "./customerDashboardSlice.ts";
import {useAppSelector} from "../../../stores/reduxHooks.ts";

export enum DashboardMode {
    Customers,
    Animals,
}

interface CustomersDashboardHeaderProps {
    panelKind: PanelKind;
    tabUuid: string;
}

const CustomersDashboardHeader: FC<CustomersDashboardHeaderProps> = ({tabUuid, panelKind}) => {
    const primaryColor = COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind];
    const secondaryColor = COLOR_PANEL_KIND_SECONDARY_COLORS[panelKind];
    const {t} = useTranslationFile(TranslationFile.Customers);
    const dispatch = useDispatch();
    const mode = useAppSelector(o => selectedMode(o, tabUuid));

    const currentMode = mode === DashboardMode.Customers ? "Clients" : "Animaux";
    return (
        <div className="flex flex-row gap-x-4 justify-between px-4 pb-4">
            <div className="flex flex-row gap-x-4 items-center">
                <HeadingCore className="mr-8 min-w-64">Base de données {currentMode}</HeadingCore>
                <span className="isolate inline-flex rounded-md shadow-sm ">
                    <button
                        onClick={() => dispatch(changeDashboardMode({selectedMode: DashboardMode.Customers, tabUuid: tabUuid}))}
                        type="button"
                        className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 ${mode === DashboardMode.Customers ? "bg-pink-400" : ""}`}>
                        Clients
                    </button>
                    <button
                        onClick={() => dispatch(changeDashboardMode({selectedMode: DashboardMode.Animals, tabUuid: tabUuid}))}
                        type="button"
                        className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10
                        ${mode === DashboardMode.Animals ? "bg-pink-400" : ""}`}>
                        Animaux
                    </button>
                </span>
            </div>
            <div className="flex flex-row items-center">
                <ButtonCore
                    className="flex items-center ml-2 @5xl:ml-4"
                    colorKind={primaryColor}
                    onClick={() =>
                        dispatch(mode === DashboardMode.Animals
                            ? focusOrCreateSecondaryTab(Destinations.NewAnimal())
                            : focusOrCreateSecondaryTab(Destinations.NewCustomer()))}>
                    <HiPlus className="h-6 w-6 @4xl:w-5 @4xl:h-5"/>
                    <span className="hidden @4xl:block">
                        {t("add")}
                        </span>
                </ButtonCore>
                <ButtonCore
                    className="flex items-center ml-2 @5xl:ml-4"
                    colorKind={secondaryColor}>
                    <CiClock2 className="h-6 w-6 @4xl:w-5 @4xl:h-5"/>
                    <span className="hidden @4xl:block">
                        Mettre en salle d'attente
                    </span>
                </ButtonCore>
            </div>
        </div>
    );
};

export default CustomersDashboardHeader;
