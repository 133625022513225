import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../../stores/sliceNames.ts";
import ITabIndexedState, {getOrDefault, removeTabState, updateTabState} from "../../../ui/tab_panels/tabIndexedState.ts";

interface IAnimalRecordCounter {
    tabUuid: string;
    currentCount: number;
}

export interface IAnimalRecordState {
    count: number;
}

const counterSelector = (state: ITabIndexedState<IAnimalRecordState>, tabUuid: string) => getOrDefault(state, tabUuid).count;

const initialState: ITabIndexedState<IAnimalRecordState> = {
    initialState: {count: 0},
    tabStates: []
};

const animalRecordSlice = createSlice({
    name: SliceNames.ANIMAL_RECORD,
    initialState: initialState,
    reducers: {
        // WARNING : Register this action in registeredRemoveTabsSlices in tabNotifierMiddleware.ts
        cleanAnimalRecordTabs : (state, action: PayloadAction<string>) => {
            removeTabState(state, action.payload);
        },
        incrementAnimalRecord: (state, action: PayloadAction<IAnimalRecordCounter>) => {
            updateTabState(state, action.payload.tabUuid, o => {
                o.count = action.payload.currentCount;
            });
        }
    },
    selectors: {
        counterAnimalRecord: counterSelector,
    }
});

export const {incrementAnimalRecord, cleanAnimalRecordTabs} = animalRecordSlice.actions;
export const {counterAnimalRecord} = animalRecordSlice.selectors;

const animalRecordSliceReducer = animalRecordSlice.reducer;
export default animalRecordSliceReducer;
