import {useRouteError} from "react-router-dom";
import {Link} from "./ui/catalyst_ui/link.tsx";
import {TranslationFile, useTranslationFile} from "./hooks/translations.ts";

type ErrorResponseCandidate = {
    statusText: string;
    message?: string;
};

const errorCheck = (error: unknown): error is ErrorResponseCandidate => {
    return typeof error === 'object'
        && error !== null
        && 'statusText' in error
        && 'message' in error;
};

export default function ErrorPage() {
    const { t } = useTranslationFile(TranslationFile.Translation);
    const error = useRouteError();
    return (
        <>
            <div className="grid min-h-full grid-cols-1 grid-rows-[1fr,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr]">
                <header className="mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8">
                    <Link href="/">
                        <span className="sr-only">VetCoreSolution</span>
                        <img
                            src="/vcs_no_border.svg"
                            alt="VetCoreSolution Logo"
                            className="h-10 w-auto sm:h-12"
                        />
                    </Link>
                </header>
                <main className="mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
                    <div className="max-w-lg">
                        <p className="text-base font-semibold leading-8 text-amber-500">404</p>
                        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t('ErrorPage.pageNotFound')}</h1>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            {t('ErrorPage.additionalMessage')}
                        </p>

                        {/*Display error details if any: */}
                        {errorCheck(error) && <p>{error.statusText || error.message}</p>}

                        <div className="mt-10">
                            <Link href="/" className="text-sm font-semibold leading-7 text-amber-500">
                                <span aria-hidden="true">&larr;</span>{t('ErrorPage.backToHome')}
                            </Link>
                        </div>
                    </div>
                </main>
                <footer className="self-end lg:col-span-2 lg:col-start-1 lg:row-start-3">
                    <div className="border-t border-gray-100 bg-gray-50 py-10">
                    </div>
                </footer>
                <div className="hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block">
                    <img
                        alt=""
                        src="https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80"
                        className="absolute inset-0 h-full w-full object-cover"
                    />
                </div>
            </div>
        </>
    )

}
