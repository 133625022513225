export interface ITailwindColor {
    readonly textNeutral: string; // the text where we want to use the background in color
    readonly text: string; // the text in color
    readonly bg: string; // the background of the plain color
    readonly bgNeutral: string; // the background where we want to use the text in plain color
    readonly hover: string;
    readonly hoverNeutral: string;
    readonly focusVisible: string;
    // contrast:
    readonly contrast_bg: string;
    readonly contrast_bgNeutral: string;
    readonly contrast_text: string;
    readonly contrast_textNeutral: string;
    readonly contrast_hover: string;
    readonly contrast_hoverNeutral: string;
    readonly contrast_focusVisible: string;

}

export class TailwindColor implements ITailwindColor {
    // base:
    public readonly bg: string;
    public readonly bgNeutral: string;
    public readonly text: string;
    public readonly textNeutral: string;
    public readonly hover: string;
    public readonly hoverNeutral: string;
    public readonly focusVisible: string;
    // contrast:
    public readonly contrast_bg: string;
    public readonly contrast_bgNeutral: string;
    public readonly contrast_text: string;
    public readonly contrast_textNeutral: string;
    public readonly contrast_hover: string;
    public readonly contrast_hoverNeutral: string;
    public readonly contrast_focusVisible: string;

    constructor({
                    bg,
                    bgNeutral,
                    textNeutral,
                    text,
                    hover,
                    hoverNeutral,
                    focusVisible,
                    contrast_bg,
                    contrast_bgNeutral,
                    contrast_text,
                    contrast_textNeutral,
                    contrast_hover,
                    contrast_hoverNeutral,
                    contrast_focusVisible
                }: {
        bg: string,
        bgNeutral: string,
        textNeutral: string,
        text: string,
        hover: string,
        hoverNeutral: string,
        focusVisible: string
        // contrast :
        contrast_bg: string,
        contrast_bgNeutral: string,
        contrast_text: string,
        contrast_textNeutral: string,
        contrast_hover: string,
        contrast_hoverNeutral: string,
        contrast_focusVisible: string
    }) {
        this.bg = bg;
        this.bgNeutral = bgNeutral;
        this.text = text;
        this.textNeutral = textNeutral;
        this.hover = hover;
        this.hoverNeutral = hoverNeutral;
        this.focusVisible = focusVisible;
        // contrast :
        this.contrast_bg = contrast_bg;
        this.contrast_bgNeutral = contrast_bgNeutral;
        this.contrast_text = contrast_text;
        this.contrast_textNeutral = contrast_textNeutral;
        this.contrast_hover = contrast_hover;
        this.contrast_hoverNeutral = contrast_hoverNeutral;
        this.contrast_focusVisible = contrast_focusVisible;
    }
}


export const leftScrollColor= 'rgba(31,131,124,0.6)';
export const rightScrollColor= 'rgba(255,157,11,0.6)';
export const leftScrollColorHover= '#0A7280';
export const rightScrollColorHover= '#ff9d0b';

/**
 * scroll style is defined in css. In order to link it to tailwindColor, we provide a method used to apply color
 */
export const applyScrollBarColors = () => {
    document.documentElement.style.setProperty(`--left-scroll-color`, leftScrollColor);
    document.documentElement.style.setProperty(`--right-scroll-color`, rightScrollColor);
    document.documentElement.style.setProperty(`--left-scroll-color-hover`, leftScrollColorHover);
    document.documentElement.style.setProperty(`--right-scroll-color-hover`, rightScrollColorHover);
};

// Watch out: the string class (like bg-teal-800) MUST exist to avoid being filtered by Tailwind css filtering
export const primaryColor = new TailwindColor({
    bg: `bg-teal-800`,
    bgNeutral: `bg-white`,
    text: `text-teal-800`,
    textNeutral: `text-white`,
    hover: `hover:bg-teal-700`,
    hoverNeutral: `hover:bg-teal-100`,
    focusVisible: `focus-visible:outline-teal-800`,
    // contrast color:
    contrast_bg: `bg-teal-400`,
    contrast_bgNeutral: `bg-white`,
    contrast_text: `text-teal-400`,
    contrast_textNeutral: `text-white`,
    contrast_hover: `hover:bg-teal-300`,
    contrast_hoverNeutral: `hover:bg-teal-300`,
    contrast_focusVisible: `focus-visible:outline-teal-400`
});
