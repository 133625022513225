import ITabDefinition from "../tabDefinition.ts";
import DivCore from "../../ui/controls/divCore.tsx";
import MainCalendar from "./calendar/MainCalendar.tsx";
import {FC} from "react";
import {AppointmentCalendar} from "./calendar/AppointmentCalendar.ts";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../stores/reduxHooks.ts";
import {getSelectedCalendarMode, updateSelectedCalendarMode} from "./planningSlice.ts";
import {CalendarDisplayMode} from "./calendar/CalendarDisplayMode.tsx";
import CalendarFilters from "./calendar/CalendarFilters.tsx";
import {ActivityKind, ActivityFilterAdapter, OperationKindFilterAdapter, TeamMemberFilterAdapter, OperationKind} from "./calendar/filters/filterHelpers.ts";


const Planning: FC<ITabDefinition> = ({panelKind, tabUuid}) => {
    // TODO
    const {i18n} = useTranslation();
    const dispatch = useAppDispatch();
    const selectedCalendarMode = useAppSelector(o => getSelectedCalendarMode(o, tabUuid));

    const updateSelectedModeChanged = (newMode: CalendarDisplayMode) => {
        dispatch(updateSelectedCalendarMode(
            {
                selectedCalendarMode: newMode, tabUuid:
                tabUuid
            }));
    };

    const today = new Date(Date.now());
    const selectedDay = new Date(Date.now());
    selectedDay.setDate(selectedDay.getDate() + 4);
    const appointments = [
        new AppointmentCalendar(
            "18346d03-16f6-4589-877c-8530992da0c2",
            "c094b677-2405-45ed-bd3f-de4b89fc3ac5",
            "#FFBBBB",
            "rdv 1 parmi N",
            new Date("2024-11-05T10:00:00Z"),
            new Date("2024-11-05T11:00:00Z")),
        new AppointmentCalendar(
            "c6337691-281b-45c0-b9b4-207516839a6f",
            "b73ca6e5-cb5d-400b-90f1-903b33ab8695",
            "#2f98b6",
            "autre rdv",
            new Date("2024-11-05T10:00:00Z"),
            new Date("2024-11-05T11:00:00Z")),
    ];

    function updateActivity() {

    }

    function updateOperation() {

    }

    function updateIsCheckedOnTeamMember() {

    }

    const activityFilters: ActivityFilterAdapter[] = [
        new ActivityFilterAdapter(ActivityKind.Canine, true, updateActivity),
        new ActivityFilterAdapter(ActivityKind.Rural, true, updateActivity),
        new ActivityFilterAdapter(ActivityKind.Equine, false, updateActivity),
    ];

    const operationKindFilters: OperationKindFilterAdapter[] = [
        new OperationKindFilterAdapter(OperationKind.Surgery, true, updateOperation),
        new OperationKindFilterAdapter(OperationKind.Appointment, true, updateOperation),
        new OperationKindFilterAdapter(OperationKind.Vaccine, false, updateOperation),
    ];


    const teamMemberUserFilters: TeamMemberFilterAdapter[] = [
        new TeamMemberFilterAdapter("Jack Sullivan", "ae044ae6-6eaf-44d0-91e3-f6ba9f20b600", true, updateIsCheckedOnTeamMember),
        new TeamMemberFilterAdapter("Diana Meunier", "288ee6ff-a144-4071-a862-1cb1b79d5672", true, updateIsCheckedOnTeamMember),
        new TeamMemberFilterAdapter("Sylvie Dubuisson", "e52ab09d-0907-49df-98b3-336b88950d2a", false, updateIsCheckedOnTeamMember),
    ];

    return (
        <DivCore className="
        flex flex-row
            bg-pink-400 h-full w-full">

            {/*LEFT FILTERS PART*/}
            <div className="hidden @4xl:block w-60 border-r-2 border-theme-border dark:border-theme-border-dark mr-1  h-full overflow-y-auto overflow-x-hidden">
                <CalendarFilters panelKind={panelKind}
                                 activityFilters={activityFilters}
                                 operationKindFilters={operationKindFilters}
                                 teamMemberUserFilters={teamMemberUserFilters}/>
            </div>
            {/*MAIN CALENDAR PART*/}
            <div className="block w-full">
                <MainCalendar
                    currentLocale={i18n.language}
                    selectedDay={selectedDay}
                    appointments={appointments}
                    calendarDisplayMode={selectedCalendarMode}
                    onSelectedModeChanged={updateSelectedModeChanged}
                    today={today}
                    panelKind={panelKind}></MainCalendar>
            </div>
        </DivCore>
    );
};



export default Planning;
