import {FC} from "react";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import ButtonCore from "../../ui/controls/buttonCore.tsx";
import {COLOR_PANEL_KIND_PRIMARY_COLORS, COLOR_PANEL_KIND_SECONDARY_COLORS, COLOR_TAB_TEXT_100, PanelKindProps} from "../../ui/colors/colorConfiguration.tsx";
import DivCore from "../../ui/controls/divCore.tsx";
import InputPassword from "../../ui/controls/InputPassword.tsx";

const PasswordUpdatePart: FC<PanelKindProps> = ({panelKind}) => {
    const primaryColor = COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind]
    const secondaryColor = COLOR_PANEL_KIND_SECONDARY_COLORS[panelKind]
    const primaryTextColor = COLOR_TAB_TEXT_100[primaryColor];

    const {t} = useTranslationFile(TranslationFile.Account);
    return (
        <DivCore className="mx-auto max-w-7xl px-0 @md:px-2 @2xl:px-6">
            <div className="divide-y divide-theme-border dark:divide-theme-border-dark">
                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 @2xl:grid-cols-3">
                    <div>
                        <h2 className={`text-base/7 font-semibold ${primaryTextColor}`}>
                            {t('changePassword')}
                        </h2>
                        <p className="mt-1 text-sm text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark font-medium">
                            {t('updatePasswordDescription')}
                        </p>
                    </div>

                    <form className="@2xl:col-span-2">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 @xl:max-w-xl @xl:grid-cols-6">
                            <div className="col-span-full">
                                <label htmlFor="current-password" className="block text-sm font-semibold">
                                    {t('currentPassword')}
                                </label>
                                <div className="mt-2">
                                    <InputPassword
                                        colorKind={primaryColor}
                                        className="mt-2"
                                        id="current-password"
                                        name="current_password"
                                        autoComplete="current-password"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="new-password" className="block text-sm font-semibold">
                                    {t('newPassword')}
                                </label>
                                <div className="mt-2">
                                    <InputPassword
                                        colorKind={primaryColor}
                                        className="mt-2"
                                        id="new-password"
                                        name="new_password"
                                        autoComplete="new-password"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="confirm-password" className="block text-sm font-semibold">
                                    {t('confirmPassword')}
                                </label>
                                <div className="mt-2">
                                    <InputPassword
                                        colorKind={primaryColor}
                                        className="mt-2"
                                        id="confirm-password"
                                        name="confirm_password"
                                        autoComplete="new-password"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-8 flex gap-3">
                            <ButtonCore
                                className="min-w-24"
                                type="submit">
                                {t('reset')}
                            </ButtonCore>
                            <ButtonCore colorKind={secondaryColor}
                                        className="min-w-24"
                                        type="submit">
                                {t('save')}
                            </ButtonCore>
                        </div>
                    </form>
                </div>
            </div>
        </DivCore>
    );
};
export default PasswordUpdatePart;

