import {useSelector} from "react-redux";
import {ReactNode, useEffect} from "react";
import {selectIsDarkTheme} from "../../pages/settings/settingsSlice.ts";

/**
 * Wrap application to handle theme changed
 */
const ThemeProvider = ({children}: { children: ReactNode }) => {
    const isDarkTheme = useSelector(selectIsDarkTheme);

    useEffect(() => {
        const root = document.documentElement;
        // apply theme on html when changed
        if (isDarkTheme) {
            root.classList.add("dark");

            /* TOOLTIP STYLE */
            // Update specific react-tooltip theme :
            root.style.setProperty("--rt-color-dark", "#ffffff");
            root.style.setProperty("--rt-color-white", "#000000");
        } else {
            root.classList.remove("dark");

            /* TOOLTIP STYLE */
            // Update specific react-tooltip theme :
            root.style.setProperty("--rt-color-dark", "#4b4b4b");
            root.style.setProperty("--rt-color-white", "#ffffff");
        }
    }, [isDarkTheme]);

    return (
        <>
            {children}
        </>);
};

export default ThemeProvider;
