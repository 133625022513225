import {useState} from "react";
import {TabColors} from "../../ui/colors/colorConfiguration.tsx";
import {SubmitHandler, useForm} from "react-hook-form";
import {Root_Path, SignUp_Path} from "../../routes.ts";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import {useNavigate} from "react-router-dom";
import {logInAsync} from "../../api/apiUsers.ts";
import {ErrorMessage, Field, Label} from "../../ui/catalyst_ui/fieldset.tsx";
import InputPassword from "../../ui/controls/InputPassword.tsx";
import {useFormValidation} from "../../lib/validations.ts";
import {useAppDispatch} from "../../stores/reduxHooks.ts";
import ButtonCore from "../../ui/controls/buttonCore.tsx";
import CheckboxInputCore from "../../ui/controls/checkboxInputCore.tsx";
import LinkCore from "../../ui/controls/LinkCore.tsx";
import DivCore, {DivCoreCard} from "../../ui/controls/divCore.tsx";
import ThemeToggle from "../../ui/colors/themeToggle.tsx";
import WelcomeScreenImage from "../../ui/controls/welcomeScreenImage.tsx";
import InputCore from "../../ui/controls/InputCore.tsx";

interface IFormInputs {
    email: string
    password: string,
    remember_me: boolean,
}

export default function SignIn() {
    const navigate = useNavigate();
    const {t} = useTranslationFile(TranslationFile.SignIn);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const {validateEmail, validatePassword} = useFormValidation();
    const dispatch = useAppDispatch();
    const currentTabColor = TabColors.mainPart_primaryColor;

    const onSignInSubmitted: SubmitHandler<IFormInputs> = async (data) => {
        setIsLoading(true);
        setErrorMessage(null);
        try {
            await logInAsync(dispatch, data.email.trim(), data.password, data.remember_me);
            navigate(Root_Path);
        } catch (error) {
            setErrorMessage(t("sign_in_error_message"));
        } finally {
            setIsLoading(false);
        }
    };
    const {register, handleSubmit, formState: {errors}} = useForm<IFormInputs>();
    return (
        <>
            <DivCore className="flex flex-row h-full bg-theme-bgContrast dark:bg-theme-bgContrast-dark">
                <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img
                            src="/vcs_no_border.svg"
                            alt="VetCoreSolution Logo"
                            className="mx-auto h-10 w-auto"
                        />
                        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight">
                            {t("header")}
                        </h2>
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                        <DivCoreCard>
                            <form action="#" method="POST" className="space-y-6"
                                  onSubmit={handleSubmit(onSignInSubmitted)}>
                                <Field className="mb-4" disabled={isLoading}>
                                    <Label> {t("email")}</Label>
                                    <InputCore
                                        colorKind={TabColors.mainPart_primaryColor}
                                        type="email"
                                        invalid={errors.email !== undefined}
                                        autoComplete="email"
                                        {...register("email", {validate: validateEmail})}
                                    />
                                    {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
                                </Field>

                                <Field className="mb-4" disabled={isLoading}>
                                    <Label>{t("password")}</Label>
                                    <InputPassword
                                        colorKind={TabColors.mainPart_primaryColor}
                                        autoComplete="new-password"
                                        invalid={errors.password !== undefined}
                                        {...register("password", {validate: validatePassword})} />
                                    {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}
                                </Field>


                                <div className="flex items-center justify-between">
                                    <CheckboxInputCore
                                        disabled={isLoading}
                                        id="remember-me"
                                        defaultChecked
                                        tabColor={currentTabColor}
                                        text={t("remember_me")}
                                        {...register("remember_me")}/>

                                    <LinkCore href="#"
                                              tabColor={currentTabColor}
                                              className="text-sm font-semibold">
                                        {t("forgot_password")}
                                    </LinkCore>
                                </div>

                                <div>
                                    <ButtonCore
                                        disabled={isLoading}
                                        type="submit"
                                        colorKind={currentTabColor}
                                        className="flex w-full">
                                        {t("sign_in")}
                                    </ButtonCore>
                                    {errorMessage && <h2 className="mt-6 text-center text-xl font-bold leading-9 tracking-tight text-notification-error dark:text-notification-error-dark">
                                        {errorMessage}
                                    </h2>}

                                </div>
                            </form>

                            <div className="border-theme-border dark:border-theme-border-dark">
                                <div className="relative mt-10">
                                    <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                        <div className="w-full border-t border-theme-border dark:border-theme-border-dark"/>
                                    </div>
                                    <div className="relative flex justify-center text-sm font-medium leading-6">
                                        <span className="bg-theme-bgBase dark:bg-theme-bgBase-dark px-6">{t("or_continue_with")}</span>
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-2 gap-4">
                                    <ButtonCore
                                        className="flex w-full"
                                    >
                                        <svg viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5">
                                            <path
                                                d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                                                fill="#EA4335"
                                            />
                                            <path
                                                d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                                                fill="#4285F4"
                                            />
                                            <path
                                                d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                                                fill="#FBBC05"
                                            />
                                            <path
                                                d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                                                fill="#34A853"
                                            />
                                        </svg>
                                        <span className="text-sm font-semibold leading-6">Google</span>
                                    </ButtonCore>

                                    <ButtonCore
                                        className="flex w-full ">
                                        <svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="h-5 w-5">
                                            <path
                                                d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                                                clipRule="evenodd"
                                                fillRule="evenodd"
                                            />
                                        </svg>
                                        <span className="text-sm font-semibold leading-6">GitHub</span>
                                    </ButtonCore>
                                </div>
                            </div>
                        </DivCoreCard>

                        {/*<p className="mt-10 text-center text-sm text-gray-500">*/}
                        <p className="mt-10 text-center text-sm text-gray-500 dark:text-gray-400">
                            {t("no_subscription")}{" "}
                            <LinkCore href={SignUp_Path} tabColor={currentTabColor}>
                                {t("sign_up")}
                            </LinkCore>
                        </p>
                    </div>

                    <ThemeToggle className="mx-auto m-4"/>
                </div>
                <WelcomeScreenImage/>
            </DivCore>
        </>
    );
}
