import {CiCircleAlert, CiClock2} from "react-icons/ci";
import {FC} from "react";
import {COLOR_PANEL_KIND_PRIMARY_COLORS} from "../../../ui/colors/colorConfiguration.tsx";
import {VirtualizedTableCore} from "../../../ui/controls/VirtualizedTableCore.tsx";
import CustomersDashboardHeader, {DashboardMode} from "./customersDashboardHeader.tsx";
import {focusOrCreatePrimaryTab} from "../../../ui/tab_panels/panelSlice.ts";
import {Destinations} from "../../../ui/navigation/destinations.ts";
import ButtonCore from "../../../ui/controls/buttonCore.tsx";
import {useAppDispatch, useAppSelector} from "../../../stores/reduxHooks.ts";
import ITabDefinition from "../../tabDefinition.ts";
import {selectedMode} from "./customerDashboardSlice.ts";

export interface ICustomer {
    // if the customer has an alert associated with him
    alert?: string;
    customerUuid: string;
    denomination: string;
    kind: CustomerKind;
    customerNumber: number;
    streetAddress: string;
    cityAddress: string;
    zipcode: string;
}

export interface IAnimal {
    // if the animal has an alert associated with him
    alert?: string;
    animalUuid: string;
    name: string;
    species: string;
    customerName: string;
    customerUuid: string;
    streetAddress: string;
    cityAddress: string;
    zipcode: string;
}

export enum CustomerKind {
    Professional = "Professional",
    Private = "Private"
}

const customers: ICustomer[] = Array.from({length: 1000}, (_, i) => ({
    alert: i % 13 === 0 ? "Client chiant" : undefined,
    customerUuid: "02f32865-ab9a-4fca-9aff-1a286ec1b" + i,
    denomination: `Mr john David ${i}`,
    kind: i % 5 === 0 ? CustomerKind.Professional : CustomerKind.Private,
    customerNumber: i,
    streetAddress: `${i} Place de la halle`,
    cityAddress: "PREMERY",
    zipcode: "58700"
}));

const animals: IAnimal[] = Array.from({length: 1000}, (_, i) => ({
    alert: i % 13 === 0 ? "Animal morderu chiant" : undefined,
    animalUuid: "02f32865-ab9a-4fca-9aff-1a286ec1b" + i,
    name: "Kiki " + i,
    species: "Fox terrier",
    customerName: "Jean Charles",
    customerUuid: "02f32865-ab9a-4fca-9aff-1a286ec1b",
    streetAddress: "5 rue de la Mairie",
    cityAddress: "Prémery",
    zipcode: "58700",
}));


const CustomersDashboard: FC<ITabDefinition> = ({panelKind, tabUuid}) => {
    const primaryColor = COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind];
    const dispatch = useAppDispatch();
    const mode = useAppSelector(o => selectedMode(o, tabUuid));

    const renderCustomerHeader = () => (
        <div className="flex py-3.5 w-full text-sm font-semibold text-gray-900
            bg-pink-100 border-b border-gray-300 ">
            <div className="pl-4 pr-3 w-12 sm:pl-0">
                <span className="sr-only">Alert</span>
            </div>
            <div className="flex-1 px-3">Dénomination</div>
            <div className="flex-1 px-3">Type</div>
            <div className="flex-1 px-3">Numéro de client</div>
            <div className="flex-1 px-3">Adresse</div>
            <div className="flex-1 px-3">Ville</div>
            <div className="flex-1 px-3">Code postal</div>
            <div className="flex-1 px-3">
                <span className="sr-only">Dossier</span>
            </div>
        </div>
    );

    const renderCustomerRow = (customer: ICustomer, i: number) => (
        <div className={`flex items-center w-full h-full
        border-b border-gray-200 bg-white hover:bg-gray-50 
        ${i % 2 === 0 ? "bg-gray-50" : "bg-white"}`}>
            <div className="w-12 h-12 flex items-center justify-center">
                {customer.alert && <CiCircleAlert className="text-notification-error h-6 w-6"/>}
            </div>
            <div className="flex-1 px-3 truncate ">{customer.denomination}</div>
            <div className="flex-1 px-3 truncate">{customer.kind}</div>
            <div className="flex-1 px-3 truncate">{customer.customerNumber}</div>
            <div className="flex-1 px-3 truncate">{customer.streetAddress}</div>
            <div className="flex-1 px-3 truncate">{customer.cityAddress}</div>
            <div className="flex-1 px-3 truncate">{customer.zipcode}</div>
            <div className="flex-1 px-3 text-right">
                <ButtonCore
                    className="flex items-center truncate"
                    colorKind={primaryColor}
                    onClick={() =>
                        dispatch(focusOrCreatePrimaryTab(Destinations.CustomerRecord({
                            customerId: customer.customerUuid,
                            customerDisplayName: customer.denomination
                        })))}>
                    <CiClock2 className="h-6 w-6 @4xl:w-5 @4xl:h-5"/>
                    <span className="hidden @4xl:block">
                         Fiche Client<span className="sr-only">, {customer.denomination}</span>
                    </span>
                </ButtonCore>
            </div>
        </div>
    );

    const renderAnimalHeader = () => (
        <div className="flex py-3.5 w-full text-sm font-semibold text-gray-900
            bg-pink-100 border-b border-gray-300 ">
            <div className="pl-4 pr-3 w-12 sm:pl-0">
                <span className="sr-only">Alert</span>
            </div>
            <div className="flex-1 px-3">Nom</div>
            <div className="flex-1 px-3">Espèce</div>
            <div className="flex-1 px-3">Client</div>
            <div className="flex-1 px-3">Adresse</div>
            <div className="flex-1 px-3">Ville</div>
            <div className="flex-1 px-3">Code postal</div>
            <div className="flex-1 px-3">
                <span className="sr-only">Dossier</span>
            </div>
        </div>
    );

    const renderAnimalRow = (animal: IAnimal, i: number) => (
        <div className={`flex items-center w-full h-full
        border-b border-gray-200 bg-white hover:bg-gray-50 
        ${i % 2 === 0 ? "bg-gray-50" : "bg-white"}`}>
            <div className="w-12 h-12 flex items-center justify-center">
                {animal.alert && <CiCircleAlert className="text-notification-error h-6 w-6"/>}
            </div>
            <div className="flex-1 px-3 truncate ">{animal.name}</div>
            <div className="flex-1 px-3 truncate">{animal.species}</div>
            <div className="flex-1 px-3 truncate">{animal.customerName}</div>
            <div className="flex-1 px-3 truncate">{animal.streetAddress}</div>
            <div className="flex-1 px-3 truncate">{animal.cityAddress}</div>
            <div className="flex-1 px-3 truncate">{animal.zipcode}</div>
            <div className="flex-1 px-3 text-right">
                <ButtonCore
                    className="flex items-center truncate"
                    colorKind={primaryColor}
                    onClick={() =>
                        dispatch(focusOrCreatePrimaryTab(Destinations.AnimalRecord({
                            animalUuid: animal.animalUuid,
                            animalName: animal.name,
                        })))}>
                    <CiClock2 className="h-6 w-6 @4xl:w-5 @4xl:h-5"/>
                    <span className="hidden @4xl:block">
                         Fiche Animal<span className="sr-only">, {animal.name}</span>
                    </span>
                </ButtonCore>
            </div>
        </div>
    );
    return (
        <div className="flex flex-col h-full px-4 sm:px-6 lg:px-8">
            <CustomersDashboardHeader
                tabUuid={tabUuid}
                panelKind={panelKind}/>

            <div className="mt-2 flex-1 min-h-0">

                {mode === DashboardMode.Customers
                    ? <VirtualizedTableCore
                        items={customers}
                        renderHeader={renderCustomerHeader}
                        renderRow={renderCustomerRow}
                        rowHeight={60}
                    />
                    : <VirtualizedTableCore
                        items={animals}
                        renderHeader={renderAnimalHeader}
                        renderRow={renderAnimalRow}
                        rowHeight={60}
                    />
                }

            </div>
        </div>
    );
};


export default CustomersDashboard;
