import {Dropdown, DropdownButton, DropdownItem, DropdownMenu} from "../catalyst_ui/dropdown.tsx";
import {HiChevronDown} from "react-icons/hi2";
import {COLOR_BG_CONTRAST, COLOR_BG_THEME_BGVARIANT, COLOR_TAB_TEXT_100, STYLE_ROUNDED_ITEM, TabColors} from "../colors/colorConfiguration.tsx";

export function DropdownCore<TItem extends string | number>(
    {
        color,
        onSelectionChanged,
        translateItem,
        selectedItem,
        availableItems,
        className,
        disabled,
    }: {
        color: TabColors,
        onSelectionChanged: (selectedItem: TItem) => void,
        translateItem: (item?: TItem) => string,
        selectedItem: TItem | undefined,
        availableItems: readonly TItem[],
        className?: string;
        disabled?: boolean;
    }) {

    return (
        <Dropdown>
            <DropdownButton
                disabled={disabled}
                outline
                className={`min-w-32 flex items-center justify-between 
                ${STYLE_ROUNDED_ITEM} 
                ${COLOR_BG_THEME_BGVARIANT} 
                data-[hover]:bg-theme-hoverNeutral
                dark:data-[hover]:bg-theme-hoverNeutral-dark
                data-[open]:bg-theme-hoverNeutral
                dark:data-[open]:bg-theme-hoverNeutral-dark
                transition ease-out duration-200 ${className}`}
            >
                {translateItem(selectedItem)}
                <HiChevronDown className={COLOR_TAB_TEXT_100[color]}/>
            </DropdownButton>
            <DropdownMenu className="min-w-32 gap-y-1">
                {availableItems.map((item) =>
                    <DropdownItem
                        onClick={() => onSelectionChanged(item)}
                        key={item}
                        className={`cursor-pointer
                        rounded-xl
                          active:bg-theme-disabled 
                          ${COLOR_BG_FOCUS_SPECIFIC[color]}
                          data-[focus]:text-mainPart-secondaryLight
                            ${item === selectedItem
                            ? `${COLOR_BG_CONTRAST}` 
                            : ""}`}>
                        {translateItem(item)}
                    </DropdownItem>)}
            </DropdownMenu>
        </Dropdown>
    );
}

/*
background with plain color
 */
const COLOR_BG_FOCUS_SPECIFIC: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]:
    "data-[focus]:bg-mainPart-primary100 dark:data-[focus]:bg-mainPart-primary100-dark " +
    "data-[focus]:text-mainPart-primaryLight dark:data-[focus]:text-mainPart-primaryLight-dark " +
    "transition ease-out duration-300 ",
    [TabColors.mainPart_secondaryColor]:
    "data-[focus]:bg-mainPart-secondary100 dark:data-[focus]:bg-mainPart-secondary100-dark " +
    "data-[focus]:text-mainPart-secondaryLight dark:data-[focus]:text-mainPart-secondaryLight-dark " +
    "transition ease-out duration-300 ",
    [TabColors.secondaryPart_primaryColor]:
    "data-[focus]:bg-secondaryPart-primary100 dark:data-[focus]:bg-secondaryPart-primary100-dark " +
    "data-[focus]:text-secondaryPart-primaryLight dark:data-[focus]:text-secondaryPart-primaryLight-dark " +
    "transition ease-out duration-300 ",
    [TabColors.secondaryPart_secondaryColor]:
    "data-[focus]:bg-secondaryPart-secondary100 dark:data-[focus]:bg-secondaryPart-secondary100-dark " +
    "data-[focus]:text-secondaryPart-secondaryLight dark:data-[focus]:text-secondaryPart-secondaryLight-dark " +
    "transition ease-out duration-300 ",
} as const;
