import {FC} from "react";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import {ButtonCoreRedAlert} from "../../ui/controls/buttonCore.tsx";
import {COLOR_PANEL_KIND_PRIMARY_COLORS,PanelKindProps} from "../../ui/colors/colorConfiguration.tsx";
import DivCore from "../../ui/controls/divCore.tsx";
import InputCore from "../../ui/controls/InputCore.tsx";

const DangerZonePart: FC<PanelKindProps> = ({panelKind}) => {
    const primaryColor = COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind];
    const {t} = useTranslationFile(TranslationFile.Account);
    return (
        <DivCore className="mx-auto max-w-7xl px-0 @md:px-2 @2xl:px-6 divide-x pt-16 mt-8">
            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 @2xl:grid-cols-3">
                <div>
                    <h2 className={`text-lg font-bold text-notification-error dark:text-notification-error-dark`}>
                        {t('dangerZone')}
                    </h2>
                    <p className="mt-1 text-sm text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark font-medium">
                        {t('permanentEffectWarning')}
                    </p>
                </div>

                <div className="@2xl:col-span-2 flex flex-col divide-y divide-theme-border dark:divide-theme-border-dark">
                    <form>
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 @xl:max-w-xl @xl:grid-cols-6">
                            <div className="@xl:col-span-full">
                                <h2 className={`text-lg font-bold text-notification-error dark:text-notification-error-dark`}>
                                    {t('grantTemporaryVisibility')}
                                </h2>
                                <p className="mt-1 text-sm text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark font-medium">
                                    {t('temporaryVisibilityExplanation')}
                                </p>
                            </div>

                            <div className="@xl:col-span-3">
                                <label htmlFor="period" className="block text-sm font-semibold">
                                    {t('expirationDate')}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    className="mt-2"
                                    id="period"
                                    name="period"
                                    type="date"
                                />
                            </div>

                            <div className="@xl:col-span-3">
                                <label htmlFor="rights" className="block text-sm font-semibold">
                                    {t('grantedRights')}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    className="mt-2"
                                    readOnly={true}
                                    value="Comptes"
                                    id="rights"
                                    name="rights"
                                    type="text"
                                />
                            </div>

                            <div className="@xl:col-span-full">
                                <ButtonCoreRedAlert className="flex flex-1 px-8">
                                    {t('generateVisibilityCode')}
                                </ButtonCoreRedAlert>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="clinicVisibilityCode" className="block text-sm font-semibold">
                                    {t('clinicVisibilityCode')}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    readOnly={true}
                                    className="mt-2"
                                    id="clinicVisibilityCode"
                                    name="clinicVisibilityCode"
                                    value="21BDCC54-3E29-4862-95BF-C1D96DEE6AE3"
                                    type="text"
                                />

                                <p className="mt-2 text-xs/5 text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark">{t('clinicTransferExplanation')}</p>
                            </div>
                        </div>
                    </form>

                    <div className="mt-8 pt-8 grid grid-cols-1 gap-x-6 gap-y-8 @xl:max-w-xl @xl:grid-cols-6">
                        <div className="@xl:col-span-full">
                            <h2 className={`text-lg font-bold text-notification-error dark:text-notification-error-dark`}>
                                {t('clinicGroup')}
                            </h2>
                            <p className="mt-1 text-sm text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark font-medium">
                                {t('clinicGroupExplanation')}
                            </p>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="groupCode" className="block text-sm font-semibold">
                                {t('enterGroupCode')}
                            </label>
                            <InputCore
                                colorKind={primaryColor}
                                readOnly={true}
                                className="mt-2"
                                id="groupCode"
                                name="groupCode"
                                value="21BDCC54-3E29-4862-95BF-C1D96DEE6AE3"
                                type="text"
                            />
                        </div>
                        <div className="@xl:col-span-full">
                            <ButtonCoreRedAlert className="flex flex-1 px-8">
                                {t('startGroupProcess')}
                            </ButtonCoreRedAlert>
                        </div>

                        <div className="@xl:col-span-full mt-8">
                            <ButtonCoreRedAlert className="flex flex-1 px-8">
                                {t('generateGroupCode')}
                            </ButtonCoreRedAlert>

                            <p className="mt-2 text-xs/5 text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark">{t('selfTransferExplanation')}</p>
                        </div>
                    </div>

                    <div className="mt-8 pt-8 grid grid-cols-1 gap-x-6 gap-y-8 @xl:max-w-xl @xl:grid-cols-6">
                        <div className="@xl:col-span-full">
                            <h2 className={`text-lg font-bold text-notification-error dark:text-notification-error-dark`}>
                                {t('deleteClinic')}
                            </h2>
                        </div>

                        <div className="@xl:col-span-full">
                            <ButtonCoreRedAlert className="flex flex-1 px-8">
                                {t('deleteClinicRequest')}
                            </ButtonCoreRedAlert>

                            <p className="mt-2 text-xs/5 text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark">{t('deletionWarning')}</p>
                        </div>
                    </div>

                    <div className="mt-8 pt-8 grid grid-cols-1 gap-x-6 gap-y-8 @xl:max-w-xl @xl:grid-cols-6">
                        <div className="@xl:col-span-full">
                            <h2 className={`text-lg font-bold text-notification-error dark:text-notification-error-dark`}>
                                {t('deletePersonalAccount')}
                            </h2>
                        </div>

                        <div className="@xl:col-span-full">
                            <ButtonCoreRedAlert className="flex flex-1 px-8">
                                {t('deleteAccountButton')}
                            </ButtonCoreRedAlert>

                            <p className="mt-2 text-xs/5 text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark">{t('deletionWarning')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </DivCore>
    );
};

export default DangerZonePart;
