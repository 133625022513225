import React, {useEffect, useState} from "react";
import {Trans} from "react-i18next";
import {TranslationFile, useTranslationFile} from "../hooks/translations.ts";

export const RECAPTCHA_PRODUCTION_KEY = "6Ld3RW8qAAAAAG5UR2z5c6VaDIn4sDdu5zNWZznJ";
export const RECAPTCHA_LOCALHOST_KEY = "6LdLmW8qAAAAAEd7RHAdcYTh61tbU57fa7_09rN-";

export function getReCaptchaKey() {
    const apiUrl = window.APP_CONFIG?.backendUrl;
    // if we have no backend specified or if it is localhost, use the recaptcha test key
    if (apiUrl === undefined || apiUrl.toLowerCase().includes("//localhost")) {
        console.log("use local reCAPTCHA key");
        return RECAPTCHA_LOCALHOST_KEY;
    }
    return RECAPTCHA_PRODUCTION_KEY;
}

export function useRecaptcha(siteKey: string) {
    const [recaptchaReady, setRecaptchaReady] = useState(false);

    useEffect(() => {
        // Load the reCAPTCHA script if it is not already done
        if (!window.grecaptcha) {
            const script = document.createElement("script");
            script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}&badge=bottomleft`;
            script.async = true;
            script.onload = () => setRecaptchaReady(true);
            document.head.appendChild(script);
        } else {
            setRecaptchaReady(true);
        }
    }, [siteKey]);

    // Function to execute the challenge and obtain the token
    const executeRecaptcha = async (action: string) => {
        if (!recaptchaReady || !window.grecaptcha) {
            console.log("reCAPTCHA is not ready");
            throw new Error("reCAPTCHA is not ready.");
        }
        return await window.grecaptcha.execute(siteKey, {action});
    };

    return {executeRecaptcha, recaptchaReady};
}

export const CaptchaMessage: React.FC = () => {
    const {t} = useTranslationFile(TranslationFile.SignIn);
    return (
        <p>
            <Trans
                i18nKey="captcha_official_google_message"
                t={t}
                components={{
                    privacyLink: (
                        <a
                            className="text-mainPart-primary100 dark:text-mainPart-primary100-dark"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://policies.google.com/privacy"
                        />
                    ),
                    termsLink: (
                        <a
                            className="text-mainPart-primary100 dark:text-mainPart-primary100-dark"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://policies.google.com/terms"
                        />
                    ),
                }}
            />
        </p>
    );
};
