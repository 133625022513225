import LocaleSwitcher from "../../ui/settings/LocaleSwitcher.tsx";
import ThemeToggle from "../../ui/colors/themeToggle.tsx";
import ITabDefinition from "../tabDefinition.ts";
import {COLOR_PANEL_KIND_PRIMARY_COLORS} from "../../ui/colors/colorConfiguration.tsx";
import {FC} from "react";


const Settings: FC<ITabDefinition> = ({panelKind, tabUuid}) => {
    return (
        <>
            <div className="flex flex-col gap-3 ">
                <header>SETTINGS {tabUuid}</header>
                <LocaleSwitcher className="mr-auto" color={COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind]}/>
                <ThemeToggle className="mr-auto"/>
            </div>
        </>
    );
};

export default Settings;
