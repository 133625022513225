import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../stores/sliceNames.ts";

export interface IError {
    code: number;
    messageTranslationKey: string;
    level: ErrorLevel;
    // key value pairs for translation parameters
    translationParams?: { key: string; value: string }[];
}

export enum ErrorLevel {
    Critical = 1,
    Major,
    Warning
}

interface IErrorsState {
    errors: IError[];
}

// Define the initial state using NavigationState type
const initialState: IErrorsState = {
    errors: []
};

// createSlice use Redux Toolkit and Immer to allow mutating code inside reducers
const errorsSlice = createSlice({
    name: SliceNames.ERROR,
    initialState: initialState,
    reducers: {
        addError: (state, action: PayloadAction<IError>) => {
            state.errors.push(action.payload);
        },
        acknowledgeAllErrors: (state) => {
            state.errors = [];
        },
    },
    selectors: {
        selectErrorsList: (state) => state.errors
    }
});


export const {acknowledgeAllErrors, addError} = errorsSlice.actions;
export const { selectErrorsList } = errorsSlice.selectors;
const errorsReducer = errorsSlice.reducer;
export default errorsReducer;
