import {HiArrowRight, HiChevronDown} from "react-icons/hi2";
import {FC, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../stores/reduxHooks.ts";
import {changeCurrentEstablishment, changeLocation, selectAvailableEstablishments, selectCurrentEstablishment, selectCurrentLocation} from "../../components/establishments/establishmentSlice.ts";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import {EstablishmentAndLocation, getEstablishmentAndLocationPairs} from "../../models/establishment.ts";
import {Popover, PopoverButton, PopoverPanel} from "@headlessui/react";
import LinkCore from "../controls/LinkCore.tsx";
import {TabColors} from "../colors/colorConfiguration.tsx";
import DivCore from "../controls/divCore.tsx";
import {FaHouseMedicalFlag} from "react-icons/fa6";
import WarningConfirmationAlert from "../errors/warningConfirmationAlert.tsx";
import {focusOrCreateSecondaryTab} from "../tab_panels/panelSlice.ts";
import {Destinations} from "../navigation/destinations.ts";

interface EstablishmentSwitcherProps {
    className?: string;
}

const EstablishmentSwitcher: FC<EstablishmentSwitcherProps> = ({className}) => {
    const dispatch = useAppDispatch();
    const currentLocation = useAppSelector(selectCurrentLocation);
    const currentEstablishment = useAppSelector(selectCurrentEstablishment);
    const availableEstablishments = useAppSelector(selectAvailableEstablishments);
    const {t} = useTranslationFile(TranslationFile.Panels);
    const [isAlertOpen, setAlertOpen] = useState(false);
    const [selectedPair, setSelectedPair] = useState<EstablishmentAndLocation | undefined>();

    const availableEstablishmentsAndLocation = getEstablishmentAndLocationPairs(availableEstablishments);

    const handleConfirm = () => {
        const pair = selectedPair;
        if (pair) {
            dispatch(changeCurrentEstablishment(
                {
                    establishmentId: pair.establishment.establishmentId,
                    locationId: pair.location.locationId
                }));
        }
        resetAndCloseAlert();
    };

    const resetAndCloseAlert = () => {
        setSelectedPair(undefined);
        setAlertOpen(false);
    };

    const updateEstablishmentAsync = async (pair: EstablishmentAndLocation) => {
        if (pair.IsSameEstablishmentId(currentEstablishment)) {
            if (pair.IsSameLocationId(currentLocation)) {
                // CASE where nothing change
                return;
            } else {
                dispatch(changeLocation(pair.location.locationId));
            }
        } else {
            // DISPLAY POPUP to warn user that every thing will be reset when changing establishment
            setSelectedPair(pair);
            setAlertOpen(true);
        }
    };
    return (
        <>
            <WarningConfirmationAlert
                isOpen={isAlertOpen}
                message={t("warningEstablishmentChanging")}
                onConfirm={handleConfirm}
                onCancel={resetAndCloseAlert}
            />
            <Popover
                className={`relative ${className}`}>
                <PopoverButton className="inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg px-2 py-1
            hover:bg-theme-hoverNeutral dark:hover:bg-theme-hoverNeutral-dark
            transition ease-out duration-200 ">
                    <FaHouseMedicalFlag aria-hidden="true" className="block sm:hidden h-9 w-9"/>
                    <div className="hidden sm:flex flex-col items-center gap-0 ">
                        <p className="font-semibold text-theme-OnBg dark:text-theme-OnBg-dark max-w-32 md:max-w-48 xl:max-w-60 truncate">
                            {currentEstablishment.isTestEstablishment
                                ? t(currentEstablishment.establishmentName) // translate test establishment
                                : currentEstablishment.establishmentName}
                        </p>
                        <p className="text-theme-OnBg dark:text-theme-OnBg-dark opacity-70 text-xs max-w-32 md:max-w-48 xl:max-w-60 truncate">
                            {currentEstablishment.isTestEstablishment
                                ? t(currentLocation.locationName) // translate currentLocation if test establishment
                                : currentLocation.locationName}
                        </p>
                    </div>
                    <HiChevronDown aria-hidden="true" className="h-5 w-5 hidden sm:block "/>
                </PopoverButton>
                <PopoverPanel
                    transition
                    className="left-full lg:left-1/2 -translate-x-3/4  md:-translate-x-full lg:-translate-x-1/2
                 w-screen max-w-max
                 flex z-10 mt-6 absolute px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                >
                    <DivCore className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-theme-border/50 dark:ring-theme-border-dark/50 dark:shadow-xl">
                        <div className="p-4">
                            {availableEstablishmentsAndLocation.map((item) =>
                                <PopoverButton className="w-full group relative flex gap-x-6 rounded-lg p-4
                                 hover:bg-theme-hoverNeutral dark:hover:bg-theme-hoverNeutral-dark
                                 transition ease-out duration-200 "
                                               onClick={() => updateEstablishmentAsync(item)}
                                               key={`${item.establishment.establishmentId}-${item.location.locationId}`}>

                                    <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg
                                bg-theme-bgContrast dark:bg-theme-bgContrast-dark">
                                        <FaHouseMedicalFlag aria-hidden="true" className="h-7 w-7 text-mainPart-primary100 dark:text-mainPart-primary100-dark
                                 group-hover:text-theme-OnBg dark:group-hover:text-theme-OnBg-dark"/>
                                    </div>
                                    <div className="flex flex-col items-start">
                                        <p className="font-semibold text-theme-OnBg dark:text-theme-OnBg-dark">
                                            {item.establishment.isTestEstablishment
                                                ? t(item.establishment.establishmentName) // translate test establishment
                                                : item.establishment.establishmentName}
                                        </p>
                                        <p className="mt-1 text-theme-OnBg dark:text-theme-OnBg-dark opacity-70 text-left">
                                            {item.establishment.isTestEstablishment
                                                ? t(item.location.locationName) // translate currentLocation if test establishment
                                                : item.location.locationName}
                                        </p>
                                    </div>
                                    {/*This span avoid text selection and made the entire area clickable*/}
                                    <span className="absolute inset-0"/>
                                </PopoverButton>)}
                        </div>
                        {/*LINK THAT GO TO ACCOUNT TO ACCESS SUBSCRIPTION */}
                        <div className="p-8 bg-theme-bgContrast dark:bg-theme-bgContrast-dark">
                                <PopoverButton as={LinkCore}
                                               href="#"
                                               className="flex flex-row items-center justify-end gap-1" tabColor={TabColors.mainPart_primaryColor}
                                               onClick={() =>
                                                   dispatch(focusOrCreateSecondaryTab(Destinations.Account))}
                                >
                                    {t('SubscribeOrLinkYourAccountToAClinic')}
                                    <HiArrowRight aria-hidden="true" className="h-4 w-4"/>
                                </PopoverButton>
                        </div>
                    </DivCore>
                </PopoverPanel>
            </Popover>
        </>
    );
};


export default EstablishmentSwitcher;
