import {configureStore} from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";
import {persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from "redux-persist";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/rootSaga.ts";
import {InitializeApiFromUrl} from "../api/Api.ts";
import SliceNames from "./sliceNames.ts";
import tabNotifierMiddleware from "../ui/tab_panels/middlewares/tabNotifierMiddleware.ts";
import {rootReducer} from "./rootState.ts";

const persistConfig = {
    key: "root",
    storage: storageSession,
    whitelist: SliceNames.GetPersistedSlices() // only element in whitelist will be persisted
};

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);

const vcsStore  = configureStore({
    reducer: persistedReducer,
    // Add a middleware to avoid serialization error on redux-persist actions (see https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist)
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            // concatenate with sagas middleware
            .concat(sagaMiddleware)
            .concat(tabNotifierMiddleware),
});

function InitializeAllStoreDependentComponents() {
    const store = vcsStore.getState();

    // First, initialize the API endpoints
    InitializeApiFromUrl(store.apiStatus.apiCentralServerUrl, store.apiStatus.apiProxyServerUrl);
}

// initialize all component that need the store AFTER calling configureStore:
InitializeAllStoreDependentComponents();

export const vcsReduxPersistor = persistStore(vcsStore);

sagaMiddleware.run(rootSaga);

export default vcsStore;
