export function isValidUrl(string: string): boolean {
    try {
        new URL(string);
        return true;
    } catch {
        return false;
    }
}

export function isValidUrlProxy(string: string): boolean {
    try {
        const url = new URL(string);
        // to be valid, used protocol for proxy server should be http o https
        if (url.protocol === 'http:' || url.protocol === 'https:') {
            return true;
        }
        return false;
    } catch {
        return false;
    }
}

