import {NavigationKey} from "./navigationKey.tsx";


/**
 * Represent a destination toward any tab or screen
 */
export default interface IDestination {
    isRootMenu: boolean;
    navigationKey: NavigationKey;
    relatedHRef: string;
    titleTranslationKey: string;
    titleTranslationKeyParams?: Record<string, string>;
    policy: TabPolicy;
}

/* eslint-disable no-unused-vars */
/**
 * All available Tab Policy
 */
export enum TabPolicy {

    /**
     * Basic Tab policy : <strong>each time a tab is requested, it creates a new tab</strong>.
     * This Policy allow for multiple parallels tabs like multiples Plannings with different data.
     * This is the default behaviour
     */
    Transient = "Transient",

    /**
     * Transient policy for main menu : <strong>each time a tab is requested :
     *  - if the main displayed tab is a root main menu (like dashboard/): it navigates the main part
     *  - if the main displayed tab is a subMenu (like dashboard/animal) : it focuses an existing same tab if it exists ON MAIN LEFT PART ONLY, or create a new one and focus it
     *  </strong>.
     * This Policy try to preserve specific tab while keeping a way to navigate without opening too much tabs
     */
    SpecificMainMenu = "SpecificMainMenu",

    /**
     * This policy <strong>only allow for one tab with a given NavigationKey to be open at the same time.</strong>
     * if you try to open another one, it will focus the current. Ths is helpful for tabs that are not
     * needed in multiple copies like Account or Parameters
     */
    Singleton = "Singleton",

    /**
     * This policy only allow for one tab with a given NavigationKey AND the same "id" url parameters.
     * If only the NavigationKey is identical, a new panel will be open. If there is no 'id', a new panel
     * will be open.
     */
    SingletonWithSameId = "SingletonWithSameId"
}

/* eslint-enable no-unused-vars */
