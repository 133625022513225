import {COLOR_TAB_BUTTON, COLOR_CLASSES_BUTTON_BASIC, STYLE_ROUNDED_ITEM, TabColors, colorOrDefault} from "../colors/colorConfiguration.tsx";
import {ButtonHTMLAttributes, FC} from "react";

export interface ButtonCoreProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    colorKind?: TabColors;
}

export const ButtonCoreNoBorder: FC<ButtonCoreProps> = (
    {
        colorKind,
        ...props
    }) => {
    return (
        <button
            {...props}
            className={`flex rounded-lg
            hover:bg-theme-hoverNeutral dark:hover:bg-theme-hoverNeutral-dark
            transition ease-out duration-200 
             ${props.className}`}>
            {props.children}
        </button>
    );
};


export const ButtonCoreRedAlert: FC<ButtonCoreProps> = (
    {
        colorKind,
        ...props
    }) => {
    return (
        <button
            {...props}
            className={`px-3 py-2 gap-3 items-center justify-center text-sm font-semibold shadow-sm leading-6 
            active:bg-theme-border dark:active:bg-theme-border-dark
            transition ease-out duration-200 
            ${STYLE_ROUNDED_ITEM}
              ${props.className}
               bg-notification-error dark:bg-notification-error-dark               
                font-semibold text-white shadow-sm
                hover:bg-notification-error/70
                dark:hover:bg-notification-error-dark/70`}>
            {props.children}
        </button>
    );
};

const ButtonCore: FC<ButtonCoreProps> = (
    {
        colorKind,
        ...props
    }) => {
    const colorClasses = colorOrDefault(colorKind, COLOR_TAB_BUTTON, COLOR_CLASSES_BUTTON_BASIC);

    return (
        <button
            {...props}
            className={`px-2 py-2 gap-3 items-center justify-center text-sm font-semibold shadow-sm leading-6 
             active:bg-theme-border dark:active:bg-theme-border-dark 
             transition ease-out duration-200 
             ${STYLE_ROUNDED_ITEM}
             ${colorClasses} 
             ${props.className}`}>
            {props.children}
        </button>
    );
};

export default ButtonCore;

