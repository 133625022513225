import {FC} from "react";
import {ButtonCoreProps} from "./buttonCore.tsx";
import {COLOR_BORDER_BLACK_AND_WHITE, COLOR_TAB_BORDER_100, COLOR_TAB_BORDER_50_ON_HOVER, COLOR_TAB_TEXT_100, COLOR_TAB_TEXT_AND_TEXT_HOVER, colorOrDefault, TabColors} from "../colors/colorConfiguration.tsx";

export interface TabHeaderCoreProps extends ButtonCoreProps {
    isCurrent: boolean;
}

// Return a border of 100% color depending on TabColors
export const COLOR_TAB_HEADER_BUTTON_CURRENT: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]:
    COLOR_TAB_TEXT_AND_TEXT_HOVER[TabColors.mainPart_primaryColor] +
    COLOR_TAB_BORDER_100[TabColors.mainPart_primaryColor],
    [TabColors.mainPart_secondaryColor]:
    COLOR_TAB_TEXT_AND_TEXT_HOVER[TabColors.mainPart_secondaryColor] +
    COLOR_TAB_BORDER_100[TabColors.mainPart_secondaryColor],
    [TabColors.secondaryPart_primaryColor]:
    COLOR_TAB_TEXT_AND_TEXT_HOVER[TabColors.secondaryPart_primaryColor] +
    COLOR_TAB_BORDER_100[TabColors.secondaryPart_primaryColor],
    [TabColors.secondaryPart_secondaryColor]:
    COLOR_TAB_TEXT_AND_TEXT_HOVER[TabColors.secondaryPart_secondaryColor] +
    COLOR_TAB_BORDER_100[TabColors.secondaryPart_secondaryColor],
} as const;

// Return a border of 50% color depending on TabColors
export const COLOR_TAB_HEADER_BUTTON_NOT_CURRENT: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]:
    COLOR_TAB_TEXT_100[TabColors.mainPart_primaryColor] +
    COLOR_TAB_BORDER_50_ON_HOVER[TabColors.mainPart_primaryColor],
    [TabColors.mainPart_secondaryColor]:
    COLOR_TAB_TEXT_100[TabColors.mainPart_secondaryColor] +
    COLOR_TAB_BORDER_50_ON_HOVER[TabColors.mainPart_secondaryColor],
    [TabColors.secondaryPart_primaryColor]:
    COLOR_TAB_TEXT_100[TabColors.secondaryPart_primaryColor] +
    COLOR_TAB_BORDER_50_ON_HOVER[TabColors.secondaryPart_primaryColor],
    [TabColors.secondaryPart_secondaryColor]:
    COLOR_TAB_TEXT_100[TabColors.secondaryPart_secondaryColor]  +
    COLOR_TAB_BORDER_50_ON_HOVER[TabColors.secondaryPart_secondaryColor],
} as const;

export const COLOR_TAB_HEADER_BUTTON_BLACK_AND_WHITE =
    " text-theme-OnBg dark:text-theme-OnBg-dark  " +
    " hover:text-theme-hoverNeutral dark:hover:text-theme-hoverNeutral-dark " +
    COLOR_BORDER_BLACK_AND_WHITE;

const TabHeaderCore: FC<TabHeaderCoreProps> = (
    {
        isCurrent,
        colorKind,
        ...props
    }) => {

    const colorTabCurrent = colorOrDefault(colorKind, COLOR_TAB_HEADER_BUTTON_CURRENT, COLOR_TAB_HEADER_BUTTON_BLACK_AND_WHITE);
    const hoverNotCurrent = colorOrDefault(colorKind, COLOR_TAB_HEADER_BUTTON_NOT_CURRENT, COLOR_TAB_HEADER_BUTTON_BLACK_AND_WHITE);
    return (
        <button
            {...props}
            aria-current={isCurrent ? "page" : undefined}
            className={
                `${isCurrent ? colorTabCurrent
                    : ` border-transparent text-theme-disabled ${hoverNotCurrent}`}
                whitespace-nowrap border-b-2 px-1 pb-2 text-sm font-medium
             ${props.className}`}>
            {props.children}
        </button>
    );
};


export default TabHeaderCore;
