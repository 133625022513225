import { FC } from "react";

/**
 * The welcome image displayed on large screen with VetCore logo
 * @constructor
 */
const WelcomeScreenImage: FC = () => {
    return (
        <div className="hidden xl:w-[50%] xl:relative xl:block">
            <picture>
                <source srcSet="/johannes-andersson.webp" type="image/webp"/>
                <img src="/johannes-andersson.jpg"
                     alt=""
                     className="absolute inset-0 h-full w-full object-cover"
                     loading="lazy"/>
            </picture>

            { /* VET CORE SOLUTION TEXT AND ICON*/}
            <div className="absolute left-1/2 top-1/4 transform -translate-x-1/2 text-center flex flex-row items-center">
                <div className="relative flex rounded-full h-20 w-20 p-1 justify-center items-center bg-cover bg-mainPart-primary100">
                    <img
                        src="/vcs_no_border.svg"
                        alt="VetCoreSolution Logo"
                        className="mx-auto w-auto"
                    />
                </div>

                <h2 className="ml-8 text-5xl font-bold whitespace-nowrap flex-1 text-mainPart-primary100">
                    VetCore Solution
                </h2>

            </div>

            <a href="https://unsplash.com/fr/@thejoltjoker"
               target="_blank"
               rel="noopener noreferrer"
               className="absolute bottom-2 right-2 text-white text-sm">
                Johannes Andersson
            </a>
        </div>
    );
};

export default WelcomeScreenImage;
