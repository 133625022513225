/*
All Routes for the application
 */

export const Root_Path = "/";
export const Financial_Path = "/financial";
export const Hospitalization_Path = "/hospitalization";
export const Accounts_Path = "/accounts";
export const SignIn_Path = "/signin";
export const Subscriptions_Path = "/subscriptions";
export const SignUp_Path = "/signup";
export const CustomerDashboard_Path = "/dashboard";
export const CustomerRecord_Path = "/dashboard/customer";
export const AnimalRecord_Path = "/dashboard/animal";
export const Products_Path = "/products";
export const Planning_Path = "/planning";
export const Settings_Path = "/settings";
export const Help_Path = "/help";
export const Appointment_Path = "/appointment";
export const WaitingRoom_Path = "/waiting_room";
export const Statistics_Path = "/statistics";
export const details_subPath = "/details";

type Params = [RouteParam, string | number];

/**
 * All available route parameters
 */
export enum RouteParam {
    Operation = "op",
    Id = "id",
}

// Creating a map where the key is the string value of the enum and the value is the enum itself
const routeParamMap: Map<string, RouteParam> = new Map(
    Object.values(RouteParam).map((value) => [value, value as RouteParam])
);


// url foo is only used to create a 'valid' url to concatenate params
const urlFoo = "https://foo";

export function concatenateHrefAndParameters(pathname: string, ...params: Params[]): string {
    const url = new URL(pathname, urlFoo);
    params.forEach(([key, value]) => {
        url.searchParams.append(key, String(value));
    });
    return url.toString().replace(urlFoo, "");
}

/**
 * Extracts the base path from a given URL or path string and maps it to a value from a provided Map.
 * @param map - A Map where keys represent base paths and values represent mapped components.
 * @param path - The input path or URL to be processed.
 * @returns The mapped value from the Map if a match is found, otherwise undefined.
 */
export function getUrlBaseMappedValue<T>(map: Map<string, T>, path: string): T | undefined {
    // Create a URL object to handle both absolute URLs and relative paths
    const url = new URL(path, urlFoo); // Arbitrary base to handle relative paths
    // Extract the path without query parameters or fragments and remove trailing slashes
    const normalizedPath = url.pathname.replace(/\/+$/, "");
    // Check for a matching key in the map
    return map.get(normalizedPath);
}

/**
 * Extracts route parameters from a given URL and maps them to their corresponding `RouteParam` enum.
 * WARNING : It filters empty values
 *
 * @param href - The URL from which to extract route parameters.
 * @returns A `Map<RouteParam, string>` containing the matched route parameters and their values.
 *
 * The `Map` structure inherently filters out duplicates, as it only retains the most recent entry
 * for any given key. As a consequence: if a parameter appears multiple times in the query string,
 * only the last occurrence is preserved in the result.
 */
export function extractRouteParam(href: string) : Map<RouteParam, string> {
    // Create a URL object to handle both absolute URLs and relative paths
    const url = new URL(href, urlFoo); // Arbitrary base to handle relative paths
    const searchParams = new URLSearchParams(url.search);

    const result = new Map<RouteParam, string>()
    for (const searchParam of searchParams) {
        // FILTER EMPTY PARAM value to avoid polluting following workflow
        if(searchParam[1] !== ""){
            // Check for a matching key in the route map
            const route = routeParamMap.get(searchParam[0]);
            if(route){
                result.set(route, searchParam[1])
            }
        }
    }
    return result;
}
