
export interface NotificationVcs {
    title: string;
    importance: NotificationLevel;
    content: string;
    onRemove: () => void;
};

export enum NotificationLevel {
    Low = 0,
    Medium = 1,
    High = 2
}

export const getNotificationsColor = (level: NotificationLevel | null): string => {
   switch (level){
       case NotificationLevel.Low:
       case null:
           return "text-theme-disabled dark:text-theme-disabled-dark";
       case NotificationLevel.Medium:
           return "text-notification-bon dark:text-notification-bon-dark";
       case NotificationLevel.High:
           return "text-notification-error dark:text-notification-error-dark";
   }
};

export const getMaxNotificationLevel = (notifications: NotificationVcs[]): NotificationLevel | null => {
    let maxLevel : NotificationLevel | null = null;

    for (const notification of notifications) {
        if (maxLevel == null || notification.importance > maxLevel) {
            maxLevel = notification.importance;
        }
    }

    return maxLevel;
};
