import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../stores/sliceNames.ts";
import ITabIndexedState, {getOrDefault, removeTabState, updateTabState} from "../../ui/tab_panels/tabIndexedState.ts";

interface IFinancialCounter {
    tabUuid: string;
    currentCount: number;
}

export interface IFinancialState {
    count: number;
}

const counterSelector = (state: ITabIndexedState<IFinancialState>, tabUuid: string) => getOrDefault(state, tabUuid).count;

const initialState: ITabIndexedState<IFinancialState> = {
    initialState: {count: 0},
    tabStates: []
};

const financialSlice = createSlice({
    name: SliceNames.FINANCIAL,
    initialState: initialState,
    reducers: {
        // WARNING : Register this action in registeredRemoveTabsSlices in tabNotifierMiddleware.ts
        cleanFinancialTabs : (state, action: PayloadAction<string>) => {
            removeTabState(state, action.payload);
        },
        incrementFinancial: (state, action: PayloadAction<IFinancialCounter>) => {
            updateTabState(state, action.payload.tabUuid, o => ({
                ...o,
                count: action.payload.currentCount,
            }));
        }
    },
    selectors: {
        counterFinancial: counterSelector,
    }
});

export const {incrementFinancial, cleanFinancialTabs} = financialSlice.actions;
export const {counterFinancial} = financialSlice.selectors;

const financialSliceReducer = financialSlice.reducer;
export default financialSliceReducer;
