import { useTranslation } from 'react-i18next';

/**
 * Group all available translations files to provide Hook and limit typo errors
 * // DO NOT FORGET TO ADD IT IN i18n.js (search for "add ALL translations files")
 */
export enum TranslationFile {
    Subscription = 'subscription',
    SignIn = 'sign_in',
    Validations = 'validations',
    Translation = 'translation',
    Panels = 'panels',
    Navigation = "navigation",
    Errors = "errors_messages",
    Account = "account",
    Calendar= "calendar",
    Customers= "customers",
}

export const useTranslationFile = (file: TranslationFile) => useTranslation(file.toString());
