import React from "react";
import ITabDefinition from "../../pages/tabDefinition.ts";
import {useAppSelector} from "../../stores/reduxHooks.ts";
import {getActiveLeftPartId} from "./panelSlice.ts";
import {createLeftTabDefinition} from "../../main.tsx";
import {useLocation} from "react-router-dom";

/**
 * Interface for page wrapper: it takes a potentially undefined tabDefinition and if
 * undefined, it auto-assign the main left part in TabWrapper component
 */
export interface ITabWrapperDefinitionPageProps {
    tabDefinition?: ITabDefinition

    /**
     * The JSW that will be loaded by the tab wrapper
     */
    Jsx: React.FC<ITabDefinition>
}

/**
 * The wrapper check if the ITabDefinition is undefined and if so, it auto-assign the main left part
 */
const TabWrapper: React.FC<ITabWrapperDefinitionPageProps> = ({Jsx, tabDefinition}) => {
    // mandatory to call react hook at component level
    const leftTabId = useAppSelector(getActiveLeftPartId);
    const location = useLocation();
    // if tabDefinition is undefined, it means that we are using the MAIN panel navigation (on LEFT) and so, we must set the expected tabDefinition. If defined, we use it.
    const tabDefinitionReal = tabDefinition == undefined
        ? createLeftTabDefinition({tabUuid: leftTabId, href: `${location.pathname}${location.search}`})
        : tabDefinition;
    return <Jsx {...tabDefinitionReal} />;
};

export default TabWrapper;
