import React, {forwardRef} from "react";
import clsx from "clsx";
import * as Headless from "@headlessui/react";
import {TabColors} from "../colors/colorConfiguration.tsx";


const dateTypes = ['date', 'datetime-local', 'month', 'time', 'week']
type DateType = (typeof dateTypes)[number]

const COLOR_CLASSES_INPUT_CORE: Record<TabColors, string> = {
    [TabColors.mainPart_primaryColor]: "after:focus-within:ring-mainPart-primary100 after:focus-within:dark:ring-mainPart-primary100-dark",
    [TabColors.mainPart_secondaryColor]:"after:focus-within:ring-mainPart-secondary100 after:focus-within:dark:ring-mainPart-secondary100-dark",
    [TabColors.secondaryPart_primaryColor]:"after:focus-within:ring-secondaryPart-primary100 after:focus-within:dark:ring-secondaryPart-primary100-dark",
    [TabColors.secondaryPart_secondaryColor]:"after:focus-within:ring-secondaryPart-secondary100 after:focus-within:dark:ring-secondaryPart-secondary100-dark",
} as const;

export const InputCore = forwardRef(function Input(
    {
        colorKind,
        className,
        ...props
    }: {
        colorKind? : TabColors
        className?: string
        type?: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | DateType
    } & Omit<Headless.InputProps, 'className'>,
    ref: React.ForwardedRef<HTMLInputElement>
) {
    const ringColor = `${colorKind === undefined 
        ? `after:focus-within:ring-theme-border after:focus-within:dark:ring-theme-border-dark` 
        : `${COLOR_CLASSES_INPUT_CORE[colorKind]}`}`;
    return (
        <span
            data-slot="control"
            className={clsx([
                className,
                // Basic layout
                'relative block w-full',
                // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
                'before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-theme-bgBase dark:before:bg-theme-bgBase-dark before:shadow ',
                // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
                'dark:before:hidden',
                // Focus ring
                'after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent after:focus-within:ring-2 ' +
                ringColor,
                // Disabled state
                'has-[[data-disabled]]:opacity-30 before:has-[[data-disabled]]:shadow-none',
                // Invalid state
                'before:has-[[data-invalid]]:shadow-notification-error before:has-[[data-invalid]]:dark:shadow-notification-error',
            ])}
        >
      <Headless.Input
          ref={ref}
          {...props}
          className={clsx([
              // Date classes
              props.type &&
              dateTypes.includes(props.type) && [
                  '[&::-webkit-datetime-edit-fields-wrapper]:p-0',
                  '[&::-webkit-date-and-time-value]:min-h-[1.5em]',
                  '[&::-webkit-datetime-edit]:inline-flex',
                  '[&::-webkit-datetime-edit]:p-0',
                  '[&::-webkit-datetime-edit-year-field]:p-0',
                  '[&::-webkit-datetime-edit-month-field]:p-0',
                  '[&::-webkit-datetime-edit-day-field]:p-0',
                  '[&::-webkit-datetime-edit-hour-field]:p-0',
                  '[&::-webkit-datetime-edit-minute-field]:p-0',
                  '[&::-webkit-datetime-edit-second-field]:p-0',
                  '[&::-webkit-datetime-edit-millisecond-field]:p-0',
                  '[&::-webkit-datetime-edit-meridiem-field]:p-0',
              ],
              // Basic layout
              'relative block w-full appearance-none rounded-lg px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing[3])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]',
              // Typography
              'text-base/6 text-theme-OnBg placeholder:text-theme-disabled/80 dark:placeholder:text-theme-disabled-dark/50 sm:text-sm/6 dark:text-theme-OnBg-dark',
              // Border
              'border border-theme-border data-[hover]:border-theme-disabled/50 dark:border-theme-border-dark data-[hover]:dark:border-theme-disabled-dark/50',
              // Background color
              ` ${props.disabled ? 'bg-theme-disabled/30 dark:bg-theme-disabled-dark/80' : 'bg-theme-bgVariant dark:bg-theme-bgVariant-dark'}`,
              // 'bg-theme-bgVariant dark:bg-theme-bgVariant-dark',
              // Hide default focus styles
              'focus:outline-none ',
              // Invalid state
              'data-[invalid]:border-notification-error/80 data-[invalid]:data-[hover]:border-notification-error ' +
              'data-[invalid]:dark:border-notification-error-dark/80 data-[invalid]:data-[hover]:dark:border-notification-error-dark ',
              // Disabled state
              'data-[disabled]:border-theme-border dark:data-[hover]:data-[disabled]:border-theme-border-dark data-[disabled]:dark:border-theme-border-dark data-[disabled]:dark:bg-theme-bgContrast-dark/50',
              'focus:ring-0 focus:ring-inset',
              // System icons
              'dark:[color-scheme:dark]',
          ])}
      />
    </span>
    )
})

export default InputCore;
