import {FC} from "react";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import ButtonCore from "../../ui/controls/buttonCore.tsx";
import {COLOR_PANEL_KIND_PRIMARY_COLORS, COLOR_PANEL_KIND_SECONDARY_COLORS, COLOR_TAB_TEXT_100, PanelKindProps} from "../../ui/colors/colorConfiguration.tsx";
import DivCore from "../../ui/controls/divCore.tsx";
import InputCore from "../../ui/controls/InputCore.tsx";

const ClinicInfoPart: FC<PanelKindProps> = ({panelKind}) => {
    const primaryColor = COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind];
    const secondaryColor = COLOR_PANEL_KIND_SECONDARY_COLORS[panelKind];
    const primaryTextColor = COLOR_TAB_TEXT_100[primaryColor];
    const {t} = useTranslationFile(TranslationFile.Account);
    return (
        <DivCore className="mx-auto max-w-7xl px-0 @md:px-2 @2xl:px-6">
            <div className="divide-y divide-theme-border dark:divide-theme-border-dark">
                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 @2xl:grid-cols-3">
                    <div>
                        <h2 className={`text-base/7 font-semibold ${primaryTextColor}`}>
                            {t('clinicInfo')}
                        </h2>
                        <p className="mt-1 text-sm text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark font-medium">
                            {t('clinicInfoDescription')}
                        </p>
                    </div>

                    <form className="@2xl:col-span-2">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 @xl:max-w-xl @xl:grid-cols-6">

                            <div className="col-span-full">
                                <label htmlFor="clinic-name" className="block text-sm font-semibold">
                                    {t('clinicName')}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    className="mt-2"
                                    id="clinic-name"
                                    name="clinic-name"
                                    type="text"
                                />
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="address" className="block text-sm font-semibold">
                                    {t('address')}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    className="mt-2"
                                    id="address"
                                    name="address"
                                    type="text"
                                    autoComplete="street-address"
                                />
                            </div>

                            <div className="@xl:col-span-3">
                                <label htmlFor="zipCode" className="block text-sm font-semibold">
                                    {t('zipCode')}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    className="mt-2"
                                    id="zipCode"
                                    name="zipCode"
                                    type="number"
                                />
                            </div>

                            <div className="@xl:col-span-3">
                                <label htmlFor="city" className="block text-sm font-semibold">
                                    {t('city')}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    className="mt-2"
                                    id="city"
                                    name="city"
                                    type="text"
                                />
                            </div>

                            <div className="@xl:col-span-3">
                                <label htmlFor="telephone" className="block text-sm font-semibold">
                                    {t('telephone')}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    className="mt-2"
                                    id="telephone"
                                    name="telephone"
                                    type="tel"
                                    autoComplete="tel"
                                />
                            </div>

                            <div className="@xl:col-span-3">
                                <label htmlFor="website" className="block text-sm font-semibold">
                                    {t('website')}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    className="mt-2"
                                    id="website"
                                    name="website"
                                    type="text"
                                />
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="contact" className="block text-sm font-semibold">
                                    {t('contactEmail')}
                                </label>
                                <InputCore
                                    colorKind={primaryColor}
                                    className="mt-2"
                                    id="contact"
                                    name="contact"
                                    type="text"
                                />
                            </div>
                        </div>

                        <div className="mt-8 flex gap-3">
                            <ButtonCore
                                className="min-w-24"
                                type="submit">
                                {t('reset')}
                            </ButtonCore>
                            <ButtonCore colorKind={secondaryColor}
                                        className="min-w-24"
                                        type="submit">
                                {t('save')}
                            </ButtonCore>
                        </div>
                    </form>
                </div>
            </div>
        </DivCore>
    );
};

export default ClinicInfoPart;

