import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../stores/sliceNames.ts";
import ITabIndexedState, {getOrDefault, removeTabState, updateTabState} from "../../ui/tab_panels/tabIndexedState.ts";

import {CalendarDisplayMode} from "./calendar/CalendarDisplayMode.tsx";

interface IPlanningCounter {
    tabUuid: string;
    selectedCalendarMode: CalendarDisplayMode;
}

export interface IPlanningState {
    selectedCalendarMode: CalendarDisplayMode;
}

const selectedCalendarModeSelector =
    (state: ITabIndexedState<IPlanningState>, tabUuid: string) => getOrDefault(state, tabUuid).selectedCalendarMode;

const initialState: ITabIndexedState<IPlanningState> = {
    initialState: {selectedCalendarMode: CalendarDisplayMode.Weekly},
    tabStates: []
};

const planningSlice = createSlice({
    name: SliceNames.PLANNING,
    initialState: initialState,
    reducers: {
        // WARNING : Register this action in registeredRemoveTabsSlices in tabNotifierMiddleware.ts
        cleanPlanningTabs : (state, action: PayloadAction<string>) => {
            removeTabState(state, action.payload);
        },
        updateSelectedCalendarMode: (state, action: PayloadAction<IPlanningCounter>) => {
            updateTabState(state, action.payload.tabUuid, o => {
                o.selectedCalendarMode = action.payload.selectedCalendarMode;
            });
        }
    },
    selectors: {
        getSelectedCalendarMode: selectedCalendarModeSelector,
    }
});

export const {updateSelectedCalendarMode, cleanPlanningTabs} = planningSlice.actions;
export const {getSelectedCalendarMode} = planningSlice.selectors;

const planningSliceReducer = planningSlice.reducer;
export default planningSliceReducer;
