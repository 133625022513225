import {TranslationFile, useTranslationFile} from "../hooks/translations.ts";
import {Constants} from "./helpers/constants.ts";

export const useFormValidation = () => {
    const {t} = useTranslationFile(TranslationFile.Validations);

    const validateEmail = (value: string) => {
        if (!value) {
            return t("email_required");
        }
        if (value.length >= Constants.MAX_EMAIL_SIZE) {
            return t("email_max_length_reached", {maxEmailSize: Constants.MAX_EMAIL_SIZE});
        }
        return true;
    };

    const validatePassword = (value: string) => {
        if (!value) {
            return t("password_required");
        }
        if (value.length >= Constants.MAX_PASSWORD_SIZE) {
            return t("password_max_length_reached", {maxSize: Constants.MAX_PASSWORD_SIZE});
        }
        return true;
    };

    const validateFirstName = (value: string) => {
        if (!value) {
            return t("firstName_required");
        }
        if (value.length >= Constants.MAX_FIRST_NAME_SIZE) {
            return t("firstName_max_length_reached", {maxSize: Constants.MAX_FIRST_NAME_SIZE});
        }
        return true;
    };

    const validateLastName = (value: string) => {
        if (!value) {
            return t("lastName_required");
        }
        if (value.length >= Constants.MAX_LAST_NAME_SIZE) {
            return t("lastName_max_length_reached", {maxSize: Constants.MAX_LAST_NAME_SIZE});
        }
        return true;
    };

    return {validateEmail, validatePassword, validateFirstName, validateLastName};
};
