import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../stores/sliceNames.ts";
import ITabIndexedState, {getOrDefault, removeTabState, updateTabState} from "../../ui/tab_panels/tabIndexedState.ts";

interface IHospitalizationCounter {
    tabUuid: string;
    currentCount: number;
}

export interface IHospitalizationState {
    count: number;
}

const counterSelector = (state: ITabIndexedState<IHospitalizationState>, tabUuid: string) => getOrDefault(state, tabUuid).count;

const initialState: ITabIndexedState<IHospitalizationState> = {
    initialState: {count: 0},
    tabStates: []
};

const hospitalizationSlice = createSlice({
    name: SliceNames.HOSPITALIZATION,
    initialState: initialState,
    reducers: {
        // WARNING : Register this action in registeredRemoveTabsSlices in tabNotifierMiddleware.ts
        cleanHospitalizationTabs : (state, action: PayloadAction<string>) => {
            removeTabState(state, action.payload);
        },
        incrementHospitalization: (state, action: PayloadAction<IHospitalizationCounter>) => {
            updateTabState(state, action.payload.tabUuid, o => {
                o.count = action.payload.currentCount;
            });
        }
    },
    selectors: {
        counterHospitalization: counterSelector,
    }
});

export const {incrementHospitalization, cleanHospitalizationTabs} = hospitalizationSlice.actions;
export const {counterHospitalization} = hospitalizationSlice.selectors;

const hospitalizationSliceReducer = hospitalizationSlice.reducer;
export default hospitalizationSliceReducer;
