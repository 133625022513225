import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../stores/reduxHooks.ts";
import {addToWaitingRoom, getWaitingCustomers} from "../../pages/waiting_room/waitingRoomSlice.ts";
import {CiClock2} from "react-icons/ci";
import {ButtonCoreNoBorder} from "../controls/buttonCore.tsx";
import {TabColors} from "../colors/colorConfiguration.tsx";
import {focusOrCreateSecondaryTab} from "../tab_panels/panelSlice.ts";
import {Destinations} from "./destinations.ts";

const WaitingRoomIndicator: React.FC = () => {

    const dispatch = useAppDispatch();
    const customers = useAppSelector(getWaitingCustomers);

    useEffect(() => {
        // TODO => ADD MISC CLIENT only to foo badge usage
        dispatch(addToWaitingRoom({
            name: "Jean claude",
            animal: "Rex",
            veterinary: "Estelle",
            customerUuid: "unique-id",
            arrival: {hours: 9, minutes: 0}}));
        dispatch(addToWaitingRoom({
            name: "Théodore",
            animal: "Médor",
            veterinary: "Pierre",
            customerUuid: "unique-id_2",
            arrival: {hours: 9, minutes: 0}}));
    }, [dispatch]); // Les dépendances assurent que l'effet est exécuté seulement au montage


    return (
        <ButtonCoreNoBorder
            onClick={() => dispatch(focusOrCreateSecondaryTab(Destinations.WaitingRoom))}
            colorKind={TabColors.mainPart_primaryColor}
            type="button" className="relative p-1">
            <span className="sr-only">View notifications</span>
            <div className="relative inline-block">
                <CiClock2 className={`h-8 w-8 text-mainPart-primary100 dark:text-mainPart-primary100-dark `} aria-hidden="true"/>
                {customers.length > 0 &&
                    <span className="absolute top-0 right-0 -mt-2 -mr-2
                     bg-mainPart-primary100 dark:bg-mainPart-primary100-dark
                     text-mainPart-primaryLight dark:text-mainPart-primaryLight-dark
                     text-xs font-bold rounded-full h-6 w-6 flex
                     items-center justify-center">
                        {customers.length}
                    </span>}

            </div>
        </ButtonCoreNoBorder>
    );
};

export default WaitingRoomIndicator;
