import {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "../i18n.js";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ReduxProvider} from "./lib/providers/ReduxProvider.tsx";
import Accounts from "./pages/accounts/account.tsx";
import Planning from "./pages/planning/planning.tsx";
import Settings from "./pages/settings/settings.tsx";
import Statistics from "./pages/statistics/statistics.tsx";
import ErrorPage from "./error-page.tsx";
import {
    Financial_Path,
    Accounts_Path,
    Hospitalization_Path,
    CustomerDashboard_Path,
    Products_Path,
    Planning_Path,
    Root_Path,
    Settings_Path,
    SignIn_Path,
    Statistics_Path,
    Subscriptions_Path,
    Help_Path,
    SignUp_Path,
    Appointment_Path,
    WaitingRoom_Path,
    getUrlBaseMappedValue,
    AnimalRecord_Path,
    CustomerRecord_Path
} from "./routes.ts";
import SignIn from "./pages/signin/signIn.tsx";
import Subscription from "./pages/auth_subscriptions/subscriptions.tsx";
import {SubscriptionDetails} from "./pages/auth_subscriptions/subscriptionDetails.tsx";
import ITabDefinition from "./pages/tabDefinition.ts";
import TabWrapper from "./ui/tab_panels/TabWrapper.tsx";
import Hospitalization from "./pages/hospitalization/hospitalization.tsx";
import Financial from "./pages/financial/financial.tsx";
import Products from "./pages/products/products.tsx";
import Help from "./pages/help/help.tsx";
import {SignUp} from "./pages/signup/signUp.tsx";
import ThemeProvider from "./ui/colors/ThemeProvider.tsx";
import WaitingRoom from "./pages/waiting_room/waitingRoom.tsx";
import Appointment from "./pages/appointments/appointment.tsx";
import {PanelKind} from "./ui/tab_panels/panelKind.ts";
import CustomersDashboard from "./pages/customers/dashboard/customerDashboard.tsx";
import AnimalRecord from "./pages/customers/animalRecord/animalRecord.tsx";
import CustomerRecord from "./pages/customers/customerRecord/customerRecord.tsx";
import {Tooltip} from "react-tooltip";
import {Constants} from "./lib/helpers/constants.ts";

// eslint false positive
// eslint-disable-next-line no-unused-vars
const tabsRoutesMap: Map<string, (tabDef?: ITabDefinition) => JSX.Element> = new Map([
    [Financial_Path, (tabDef) => <TabWrapper Jsx={Financial} tabDefinition={tabDef}/>],
    [Accounts_Path, (tabDef) => <TabWrapper Jsx={Accounts} tabDefinition={tabDef}/>],
    [Hospitalization_Path, (tabDef) => <TabWrapper Jsx={Hospitalization} tabDefinition={tabDef}/>],
    [SignIn_Path, (tabDef) => <TabWrapper Jsx={SignIn} tabDefinition={tabDef}/>],
    [SignUp_Path, (tabDef) => <TabWrapper Jsx={SubscriptionDetails} tabDefinition={tabDef}/>],
    [CustomerDashboard_Path, (tabDef) => <TabWrapper Jsx={CustomersDashboard} tabDefinition={tabDef}/>],
    [AnimalRecord_Path, (tabDef) => <TabWrapper Jsx={AnimalRecord} tabDefinition={tabDef}/>],
    [CustomerRecord_Path, (tabDef) => <TabWrapper Jsx={CustomerRecord} tabDefinition={tabDef}/>],
    [Products_Path, (tabDef) => <TabWrapper Jsx={Products} tabDefinition={tabDef}/>],
    [Planning_Path, (tabDef) => <TabWrapper Jsx={Planning} tabDefinition={tabDef}/>],
    [Settings_Path, (tabDef) => <TabWrapper Jsx={Settings} tabDefinition={tabDef}/>],
    [Help_Path, (tabDef) => <TabWrapper Jsx={Help} tabDefinition={tabDef}/>],
    [Appointment_Path, (tabDef) => <TabWrapper Jsx={Appointment} tabDefinition={tabDef}/>],
    [Statistics_Path, (tabDef) => <TabWrapper Jsx={Statistics} tabDefinition={tabDef}/>],
    [WaitingRoom_Path, (tabDef) => <TabWrapper Jsx={WaitingRoom} tabDefinition={tabDef}/>]
]);

/**
 * Creates a JSX element representing the definition of a secondary tab based on the provided `href` and `tabUuid`.
 *
 * This function retrieves a callback function from the `tabsRoutesMap`.
 * If no valid tabWrapper creation callback is found, it logs an error and returns a default message.
 * If a valid callback is found, it invokes the callback to create and return the right-hand panel component.
 *
 * @param href - The URL or route identifier used to find the corresponding right tab component.
 * @param tabUuid - A unique identifier for the tab to be created.
 * @returns A JSX element representing the right tab content or an error message if no valid mapping is found.
 */
export function createSecondaryTab(input: { href: string, tabUuid: string }): JSX.Element {
    const createTabRightCallback = getUrlBaseMappedValue(tabsRoutesMap, input.href);
    if (createTabRightCallback === undefined) {
        console.log(`Error: No right tab found for activeTab .href [${input.href}]`);
        return <>NO RIGHT TAB REFERENCE...</>;
    }
    // retrieve the component to display on right by calling the mapped function :
    return createTabRightCallback({
        tabUuid: input.tabUuid,
        href: input.href,
        panelKind: PanelKind.Right,
    });
}

export function createLeftTabDefinition(input: { href: string, tabUuid: string }): ITabDefinition {
    return {
        tabUuid: input.tabUuid,
        href: input.href,
        panelKind: PanelKind.Left
    };
}

const router = createBrowserRouter([
    {path: SignIn_Path, element: <SignIn/>},
    {path: SignUp_Path, element: <SignUp/>},
    {path: Subscriptions_Path, element: <Subscription/>},
    {
        path: Root_Path,
        element: <App/>,
        errorElement: <ErrorPage/>,
        // generate children from tabs route
        children: Array.from(tabsRoutesMap, ([path, element]) => ({
            path,
            element: element() // do not define ITabDefinition from navigation => <TabWrapper> will set it to left when called
        })),
    },
]);

createRoot(document.getElementById("root")!).render(
    <StrictMode>
        {/*set up the redux store provider for the entire app */}
        <ReduxProvider>
            <ThemeProvider>
                <RouterProvider router={router}/>
                <Tooltip
                    id={Constants.GLOBAL_TOOLTIP_ID}
                    // Apply a high z-index to ensure tooltip will be above others element
                    style={{zIndex: 100}}/>
            </ThemeProvider>
        </ReduxProvider>
    </StrictMode>
);
