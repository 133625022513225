import {useMemo} from "react";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";

export interface ISubInfo {
    name: string;
    type: SubscriptionType;
    price: IPricingSub;
    description: string;
    features: string[];
}

export interface IPricingSub {
    monthly: number;
    annually: number;
    currency: string;
}

export function getAmount(pricing: IPricingSub, frequency: Frequency): string {
    switch (frequency) {
        case Frequency.Monthly:
            return `${pricing.currency}${pricing.monthly.toFixed(2)}`;
        case Frequency.Annually:
            return `${pricing.currency}${pricing.annually.toFixed(2)}`;
    }
};

export interface ITimeFrequency {
    kind: Frequency;
    label: string;
    priceSuffix: string;
}

export function GetFrequencies(): Frequency[] {
    return [Frequency.Monthly, Frequency.Annually];
}

export function GetFrequenciesInfo(): Map<Frequency, ITimeFrequency> {
    const {t} = useTranslationFile(TranslationFile.Subscription);

    return useMemo(() => new Map<Frequency, ITimeFrequency>([
        [Frequency.Monthly, {
            kind: Frequency.Monthly,
            label: t("monthly"),
            priceSuffix: t("monthly_price_suffix")
        }],
        [Frequency.Annually, {
            kind: Frequency.Annually,
            label: t("annually"),
            priceSuffix: t("annually_price_suffix")
        }]
    ]), [t]);
}

/**
 * Centralize access to subscription information. It may be local or comes from a backend call
 */
export function GetSubscriptionInfo(): ISubInfo[] {
    const {t} = useTranslationFile(TranslationFile.Subscription);

    return useMemo(() => [
        {
            name: t("offer_initial_header"),
            type: SubscriptionType.Initial,
            price: {monthly: 50, annually: 540, currency: "€"},
            description: t("offer_initial_description"),
            features: [
                t("offer_initial_feature_1"),
                t("offer_initial_feature_2"),
                t("offer_initial_feature_3"),
                t("offer_feature_no_data_sharing"),
            ],
        },
        {
            name: t("offer_basic_header"),
            type: SubscriptionType.Basic,
            price: {monthly: 70, annually: 756, currency: "€"},
            description: t("offer_basic_description"),
            features: [
                t("offer_basic_feature_1"),
                t("offer_basic_feature_2"),
                t("offer_basic_feature_3"),
                t("offer_basic_feature_4"),
                t("offer_basic_feature_5"),
                t("offer_basic_feature_6"),
                t("offer_feature_no_data_sharing"),
            ],
        },
        {
            name: t("offer_premium_header"),
            type: SubscriptionType.Premium,
            price: {monthly: 160, annually: 1728, currency: "€"},
            description: t("offer_premium_description"),
            features: [
                t("offer_premium_feature_1"),
                t("offer_basic_feature_2"),
                t("offer_basic_feature_3"),
                t("offer_basic_feature_4"),
                t("offer_basic_feature_5"),
                t("offer_basic_feature_6"),
                t("offer_feature_no_data_sharing"),
            ],
        },
    ], [t]);
}

export enum SubscriptionType {
    Initial = "initial",
    Basic = "basic",
    Premium = "premium"
}

export enum Frequency {
    Monthly = "monthly",
    Annually = "annually",
}
