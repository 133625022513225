import {COLOR_TAB_TEXT_AND_TEXT_HOVER, COLOR_TEXT_ON_BG, colorOrDefault, TabColors} from "../colors/colorConfiguration.tsx";
import {AnchorHTMLAttributes, forwardRef} from "react";
import {Link} from "../catalyst_ui/link.tsx";


interface LinkCoreProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    tabColor?: TabColors;
    href: string;
}

export const LinkCore = forwardRef<HTMLAnchorElement, LinkCoreProps>(
    ({tabColor, href, ...props}, ref) => {
        const colorClasses = colorOrDefault(tabColor, COLOR_TAB_TEXT_AND_TEXT_HOVER, COLOR_TEXT_ON_BG);
        return (
            <Link
                ref={ref}
                href={href}
                {...props}
                className={`font-bold leading-6 ${colorClasses} ${props.className}`}>
                {props.children}
            </Link>
        );
    }
);

export default LinkCore;
