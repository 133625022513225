import {FC} from "react";
import {TranslationFile, useTranslationFile} from "../../../hooks/translations.ts";
import {CalendarDisplayMode} from "./CalendarDisplayMode.tsx";
import {TabColors} from "../../../ui/colors/colorConfiguration.tsx";
import TabHeaderCore from "../../../ui/controls/TabHeaderCore.tsx";

const availableCalendarModes = [
    {mode: CalendarDisplayMode.Daily, translationKey: "Daily"},
    {mode: CalendarDisplayMode.Weekly, translationKey: "Weekly"},
    {mode: CalendarDisplayMode.Monthly, translationKey: "Monthly"},
];

interface CalendarModeSelectorProps {
    selectedMode: CalendarDisplayMode,
    color: TabColors,
    onSelectedModeChanged: (newSelectedMode: CalendarDisplayMode) => void,
}

const CalendarModeSelector: FC<CalendarModeSelectorProps> = (
    {
        selectedMode,
        onSelectedModeChanged,
        color,
    }) => {
    const {t} = useTranslationFile(TranslationFile.Calendar);
    return (
        <nav aria-label="Tabs" className="-mb-px flex space-x-4 mx-2 @6xl:mx-8">
            {availableCalendarModes.map((tab) => {
                let name = "";
                switch (tab.mode) {
                    case CalendarDisplayMode.Daily:
                        name = t(tab.translationKey);
                        break;
                    case CalendarDisplayMode.Weekly:
                        name = t(tab.translationKey);
                        break;
                    case CalendarDisplayMode.Monthly:
                        name = t(tab.translationKey);
                        break;
                }
                return (
                    <TabHeaderCore
                        colorKind={color}
                        key={tab.translationKey}
                        onClick={() => onSelectedModeChanged(tab.mode)}
                        isCurrent={tab.mode === selectedMode}>
                        {name}
                    </TabHeaderCore>
                );
            })}
        </nav>
    );
};

export default CalendarModeSelector;
