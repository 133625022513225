import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../stores/sliceNames.ts";

const localStorageIsDarkThemeKey = "isDarkThemeVcsKey"; // the string key used to retrieve local storage

const getInitialTheme = (): boolean => {
    const savedTheme = localStorage.getItem(localStorageIsDarkThemeKey);
    if (savedTheme !== null) {
        return JSON.parse(savedTheme) === true; // parse the string and ensure it's a boolean
    }
    return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
};

interface ISettingsState {
    isDarkTheme: boolean;
}

const initialState: ISettingsState = {
    isDarkTheme: getInitialTheme(),
};


const settingsSlice = createSlice({
    name: SliceNames.SETTINGS,
    initialState: initialState,
    reducers: {
        setIsDarkTheme: (state, action: PayloadAction<boolean>) => {
            state.isDarkTheme = action.payload;
            localStorage.setItem(localStorageIsDarkThemeKey, JSON.stringify(action.payload));
        },
    },
    selectors: {
        selectIsDarkTheme: (state) => state.isDarkTheme,
    },
});

export const {setIsDarkTheme} = settingsSlice.actions;
export const {selectIsDarkTheme} = settingsSlice.selectors;
const settingsReducer = settingsSlice.reducer;
export default settingsReducer;
