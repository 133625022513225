import SideBarNavigation from "./ui/navigation/sidebarNavigation.tsx";
import {selectIsLoggedIn} from "./pages/signin/signInSlice.ts";
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {SignIn_Path, SignUp_Path} from "./routes.ts";
import React, {lazy, Suspense, useEffect, useLayoutEffect} from "react";
import {applyScrollBarColors} from "./ui/colors/tailwindColor.ts";
import useBreakpoint from "./hooks/useBreakpoint.ts";
import {useAppDispatch, useAppSelector} from "./stores/reduxHooks.ts";
import {getActiveLeftPart, restoreFromCompactTabsSetup, setCompactTabsSetup} from "./ui/tab_panels/panelSlice.ts";
import ErrorAlert from "./ui/errors/errorAlert.tsx";

// move Resizable Split pane in an import as it is not mandatory on all cases
const ResizableSplitPane = lazy(() => import("./ui/tab_panels/layout_desktop/ResizableSplitPane.tsx"));

const BottomTabPanel = lazy(() => import("./ui/tab_panels/layout_phone/bottomTabPanel.tsx"));
// path that should remain accessible even if not logged in
const unsignedAccessPaths = new Set<string>([SignIn_Path, SignUp_Path]);

function App() {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const navigate = useNavigate();
    const location = useLocation(); // Utilisé pour obtenir l'URL actuelle

    useEffect(() => {
        applyScrollBarColors();
    }, [/* TODO => link to color theme mode */]);

    useEffect(() => {
        if (!isLoggedIn && !unsignedAccessPaths.has(location.pathname)) {
            console.log("navigate to sign in unlogged user");
            navigate(SignIn_Path);
        }
    }, [isLoggedIn, location.pathname, navigate]);

    if (isLoggedIn) {
        return (
            <>
                <ErrorAlert/>
                <SideBarNavigation>
                    <LoggedInContent/>
                </SideBarNavigation>
            </>
        );
    }
    // not logged in => return null
    return null;
}

const LoggedInContent: React.FC = () => {
    const isDesktop = useBreakpoint(1024);
    const activeLeftPart = useAppSelector(getActiveLeftPart);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // to avoid ambiguity on tabs selection changed,  we move all right tabs to the left when switching to desktop
    useEffect(() => {
        if (isDesktop) {
            dispatch(restoreFromCompactTabsSetup());
        } else {
            dispatch(setCompactTabsSetup());
        }
    }, [dispatch, isDesktop]);

    useLayoutEffect(() => {
        // When the left active tab change => navigate to the destination if not already done
        navigate(activeLeftPart.relatedHRef);
    }, [activeLeftPart, dispatch, navigate]);

    if (isDesktop) {
        return (
            <Suspense fallback={<div className="bg-gray-100 h-full w-full"/>}>
                <ResizableSplitPane/>
            </Suspense>
        );
    } else {
        return (
            <Suspense fallback={<div className="bg-gray-100 h-full w-full"/>}>
                <BottomTabPanel/>
            </Suspense>
        );
    }
};

export default App;
