import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import SliceNames from "../../stores/sliceNames.ts";
import GetApiUrlFromDockerEnvVariable from "../../api/apiUrlHelper.ts";

interface ApiState {
    isConnectedToBackend : boolean;
    apiCentralServerUrl : string;
    apiProxyServerUrl : string;
}

// get the initial server url from docker compose environment variable
const serverUrl = GetApiUrlFromDockerEnvVariable();

// Define the initial state using NavigationState type
const initialState: ApiState = {
    isConnectedToBackend: false,
    apiCentralServerUrl: serverUrl,
    apiProxyServerUrl: serverUrl,
};

// createSlice use Redux Toolkit and Immer to allow mutating code inside reducers
const apiStatusSlice = createSlice({
    name: SliceNames.APIS_STATUS,
    initialState,
    reducers: {
        updateApiProxyServerUrl: (state, url: PayloadAction<string>) => {
            state.apiProxyServerUrl = url.payload;
        },
        pingBackendSuccess: (state) => {
            state.isConnectedToBackend = true;
        },
        pingBackendFailure: (state, _action: PayloadAction<string>) => {
            state.isConnectedToBackend = false;
            // TODO use message?
        },
    }
})

export const {pingBackendSuccess, pingBackendFailure, updateApiProxyServerUrl} = apiStatusSlice.actions
const apiStatusReducer = apiStatusSlice.reducer
export default apiStatusReducer
