import {Root_Path} from "../../routes.ts";
import {ErrorMessage, Field, Label} from "../../ui/catalyst_ui/fieldset.tsx";
import {SubmitHandler, useForm} from "react-hook-form";
import {useState} from "react";
import InputPassword from "../../ui/controls/InputPassword.tsx";
import {useNavigate} from "react-router-dom";
import {TabColors} from "../../ui/colors/colorConfiguration.tsx";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import {useFormValidation} from "../../lib/validations.ts";
import {createUserAsync} from "../../api/apiUsers.ts";
import {useAppDispatch} from "../../stores/reduxHooks.ts";
import FormsHelper, {IFormInputs} from "./FormsHelper.ts";
import DivCore, {DivCoreCard} from "../../ui/controls/divCore.tsx";
import ButtonCore from "../../ui/controls/buttonCore.tsx";
import CheckboxInputCore from "../../ui/controls/checkboxInputCore.tsx";
import WelcomeScreenImage from "../../ui/controls/welcomeScreenImage.tsx";
import {CaptchaMessage, getReCaptchaKey, useRecaptcha} from "../../lib/ReCaptchaV3.tsx";
import InputCore from "../../ui/controls/InputCore.tsx";

export function SignUp() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const {t} = useTranslationFile(TranslationFile.SignIn);
    const {validateEmail, validatePassword, validateFirstName, validateLastName} = useFormValidation();

    // Hook reCAPTCHA with the public site key
    const { executeRecaptcha, recaptchaReady } = useRecaptcha(getReCaptchaKey());

    // react-hook-form :
    const {register, handleSubmit, watch, formState: {errors}} = useForm<IFormInputs>({
        mode: "onChange"
    });
    const password = watch("password");
    const dispatch = useAppDispatch();
    const navigateBack = () => navigate(-1);

    const [isLoading, setIsLoading] = useState(false);

    const onCreateUserSubmitted: SubmitHandler<IFormInputs> = async (data) => {
        setIsLoading(true);
        try {
            if (!recaptchaReady) {
                // TODO BETTER => wait for a time
                setErrorMessage("sign_up_error_reCAPTCHA_notReady");
                return;
            }
        }
        catch(error) {
            setErrorMessage(`reCAPTCHA error: ${error}`);
        }

        try {
            const token = await executeRecaptcha('submit');
            console.log(`executeRecaptcha Done: token = ${token}`);

            const formatedData = FormsHelper.formatFormData(data);
            await createUserAsync(
                dispatch,
                formatedData.email,
                formatedData.password,
                formatedData.first_name,
                formatedData.last_name,
                formatedData.remember_me,
                token
            );
            navigate(Root_Path);
        } catch {
            setErrorMessage(t("sign_up_error_message"));
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <>
            <DivCore className="flex flex-row h-full bg-theme-bgContrast dark:bg-theme-bgContrast-dark">
                <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img
                            src="/vcs_no_border.svg"
                            alt="VetCoreSolution Logo"
                            className="mx-auto h-10 w-auto"
                        />
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[600px]">
                        <DivCoreCard>
                            <form className="w-full md:max-w-lg lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl mx-auto"
                                  onSubmit={handleSubmit(onCreateUserSubmitted)}>
                                <div className="space-y-12">
                                    <div className="border-b border-theme-border dark:border-theme-border-dark pb-12">
                                        <h2 className="font-semibold leading-7 text-2xl sm:text-3xl ">Personal Information</h2>
                                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                            <div className="col-span-full">
                                                <Field className="mb-4" disabled={isLoading}>
                                                    <Label>Email</Label>
                                                    <InputCore
                                                        colorKind={TabColors.mainPart_primaryColor}
                                                        type="email"
                                                        invalid={errors.email !== undefined}
                                                        autoComplete="email"
                                                        {...register("email", {validate: validateEmail})}
                                                    />
                                                    {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
                                                </Field>

                                                <Field className="mb-4" disabled={isLoading}>
                                                    <Label>Password</Label>
                                                    <InputPassword
                                                        colorKind={TabColors.mainPart_primaryColor}
                                                        autoComplete="new-password"
                                                        invalid={errors.password !== undefined}
                                                        {...register("password", {validate: validatePassword})} />
                                                    {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}
                                                </Field>

                                                <Field className="mb-4" disabled={isLoading}>
                                                    <Label>Password confirmation</Label>
                                                    <InputPassword
                                                        colorKind={TabColors.mainPart_primaryColor}
                                                        {...register("password_confirmation", {
                                                        validate: o => o === password || "Les mots de passe ne correspondent pas"
                                                    })} />
                                                    {errors.password_confirmation && <ErrorMessage>{errors.password_confirmation.message}</ErrorMessage>}
                                                </Field>
                                            </div>

                                            <div className="sm:col-span-3">
                                                <Field className="col-span-full mb-2" disabled={isLoading}>
                                                    <Label> First name</Label>
                                                    <InputCore
                                                        colorKind={TabColors.mainPart_primaryColor}
                                                        autoComplete="given-name"
                                                        type="text"
                                                        {...register("first_name", {validate: validateFirstName})} invalid={errors.first_name !== undefined}/>
                                                    {errors.first_name && <ErrorMessage>{errors.first_name.message}</ErrorMessage>}
                                                </Field>
                                            </div>

                                            <div className="sm:col-span-3">
                                                <Field className="col-span-full mb-2" disabled={isLoading}>
                                                    <Label> Last name</Label>
                                                    <InputCore
                                                        colorKind={TabColors.mainPart_primaryColor}
                                                        autoComplete="family-name"
                                                        type="text" {...register("last_name", {validate: validateLastName})} invalid={errors.last_name !== undefined}/>
                                                    {errors.last_name && <ErrorMessage>{errors.last_name.message}</ErrorMessage>}
                                                </Field>
                                            </div>
                                        </div>

                                        <CheckboxInputCore
                                            disabled={isLoading}
                                            id="remember-me"
                                            defaultChecked
                                            tabColor={TabColors.mainPart_primaryColor}
                                            text={t("remember_me")}
                                            className="mt-4"
                                            {...register("remember_me")}/>
                                    </div>

                                </div>

                                <div className="mt-6 flex items-center gap-x-6">

                                    <div className="flex flex-1 text-sm ">
                                        <CaptchaMessage/>
                                    </div>
                                    <ButtonCore type="button" className=" ring-theme-bgContrast"
                                                onClick={navigateBack}>
                                        Cancel
                                    </ButtonCore>
                                    <ButtonCore
                                        colorKind={TabColors.mainPart_primaryColor}
                                        type="submit"
                                    >
                                        Validate
                                    </ButtonCore>
                                </div>
                                {errorMessage && <h2 className="mt-6 text-center text-xl font-bold leading-9 tracking-tight text-notification-error dark:text-notification-error-dark">
                                    {errorMessage}
                                </h2>}
                            </form>
                        </DivCoreCard>
                    </div>
                </div>

                <WelcomeScreenImage/>
            </DivCore>
        </>
    );
}
