/**
 * Return the value of the VITE_API_SERVER_URL define in docker-compose (with a stupidly complex mechanism). If not found(dev mode), fallback to localhost
 * @constructor
 */
export default function GetApiUrlFromDockerEnvVariable(): string {
    const apiUrl = window.APP_CONFIG?.backendUrl;
    if(!apiUrl){
        const fallbackUrl = "https://localhost:5250";
        console.warn(`VITE_API_SERVER_URL not found. fallback to : ${fallbackUrl}`);
        return  fallbackUrl
    }
    console.log(`api ${apiUrl}`);
    return apiUrl;
}
