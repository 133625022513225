
export interface IAppointment {
    start: Date;
    end: Date;
    customerUuid: string | undefined;
    responsibleUuid: string | undefined;
}

export class AppointmentCalendar implements IAppointment {
    start: Date;
    end: Date;
    customerUuid: string | undefined;
    responsibleUuid: string | undefined;
    color: string;
    description: string;

    constructor(
        customerUuid: string | undefined,
        responsibleUuid: string | undefined,
        color : string,
        description : string,
        start: Date,
        end: Date) {
        this.customerUuid = customerUuid;
        this.responsibleUuid = responsibleUuid;
        this.color = color;
        this.description = description;
        this.start = start;
        this.end = end;
    }
}
