import * as Headless from '@headlessui/react'
import React, { forwardRef } from 'react'
import { Link as ReactRouterLink } from "react-router-dom";

export const Link = forwardRef(function Link(
  props: { href: string } & React.ComponentPropsWithoutRef<'a'>,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  return (
    <Headless.DataInteractive>
        <ReactRouterLink to={props.href} {...props} ref={ref} />
        {/*
        NOTE : The is also a :
        <ReactRouterLink to={props.href} {...props} ref={ref} state={{ some: "value" }} />
        that allow to pass state that could be retrieved though
        let { state } = useLocation();
        in the target page
        */}

    </Headless.DataInteractive>
  )
})
