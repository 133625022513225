import {SideNavigationBarLink} from "./sideNavigationBarLink.tsx";
import React from "react";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import {NavLink} from "react-router-dom";
import {isInDevelopment} from "../../lib/utilsVcs.ts";
import DivCore from "../controls/divCore.tsx";
import {CiCalendar, CiCircleQuestion, CiDollar, CiMedicalCase, CiShoppingCart, CiUser, CiWavePulse1} from "react-icons/ci";
import {Destinations} from "./destinations.ts";

interface SidebarComponentProps {
    onNavigationMainMenu: (item: SideNavigationBarLink) => void;
}

/**
 * Bottom navigation links separated form others
 */
const bottomNavigationLinks = [
    {destination: Destinations.Help, icon: CiCircleQuestion},
];

/**
 * Main navigation links
 */
const navigationLinks = [
    {destination: Destinations.Planning, icon: CiCalendar},
    {destination: Destinations.CustomerDashboard, icon: CiUser},
    {destination: Destinations.Hospitalization, icon: CiMedicalCase},
    {destination: Destinations.Financial, icon: CiDollar},
    {destination: Destinations.Products, icon: CiShoppingCart},
    {destination: Destinations.Statistics, icon: CiWavePulse1},
    {destination: Destinations.Help, icon: CiCircleQuestion},
];

const SidebarComponent: React.FC<SidebarComponentProps> = ({onNavigationMainMenu}) => {

    return (
        <DivCore className={`flex h-full flex-col w-full
        drop-shadow-lg dark:shadow-xl dark:drop-shadow-xl
        dark:border-2 dark:border-opacity-35 dark:border-theme-border-dark`}>
            <div className="relative flex flex-col shrink-0 items-center
             justify-center mt-4">
                <img
                    className="h-12 w-auto"
                    src="/vcs_no_border.svg"
                    alt="VetCoreSolution Logo"
                />
                {isInDevelopment && (
                    <h4 className="absolute -bottom-5 font-bold text-notification-error text-xl">DEV</h4>
                )}
            </div>
            {/* adjustable space above icons */}
            <div className="flex-grow min-h-[60px]"></div>

            <nav className="flex flex-col flex-grow">
                <ul role="list" className="flex flex-col h-full">
                    {navigationLinks.map((item) => (
                        <li key={item.destination.relatedHRef} className="flex-grow">
                            <NavigationItem
                                onNavigationMainMenu={onNavigationMainMenu}
                                item={item}
                            />
                        </li>
                    ))}
                </ul>
            </nav>

            {/* adjustable space bellow icons */}
            <div className="flex-grow min-h-[20px]"></div>

            <div className="mb-12">
                <ul role="list">
                    {bottomNavigationLinks.map((item) => {
                        return (
                            <li key={item.destination.relatedHRef}>
                                <NavigationItem
                                    onNavigationMainMenu={onNavigationMainMenu}
                                    item={item}/>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </DivCore>
    );
};

interface NavigationItemProps {
    item: SideNavigationBarLink;
    onNavigationMainMenu: (item: SideNavigationBarLink) => void;
}

const NavigationItem: React.FC<NavigationItemProps> = ({item, onNavigationMainMenu}) => {
    const {t} = useTranslationFile(TranslationFile.Navigation);
    return <NavLink
        to={item.destination.relatedHRef}
        onClick={() => onNavigationMainMenu(item)}
        className={({ isActive }) =>
            `group flex flex-col h-full justify-center py-1 hover:bg-theme-hoverNeutral dark:hover:bg-theme-hoverNeutral-dark
            border-theme-border dark:border-theme-border-dark
            border-y-2 hover:border-opacity-20 dark:hover:border-opacity-40
        ${isActive ? 
                'text-mainPart-primary100 dark:text-mainPart-primary100-dark ' +
                'bg-theme-bgContrast dark:bg-theme-bgContrast-dark font-bold ' +
                'border-opacity-50 dark:border-opacity-70' 
                : 'border-opacity-0 dark:border-opacity-0'}
        transition duration-500 ease-out
        `}>
        {({isActive}) => (
            <div className="flex flex-col items-center">
                <div
                    className={
                        `flex items-center justify-center 
                         h-10 w-10`}>
                    <item.icon
                        className={`h-7 w-7 shrink-0 
                           ${isActive
                            ? "stroke-1 text-mainPart-primary100 dark:text-mainPart-primary100-dark "
                            : ``}`}
                        aria-hidden="true"
                    />
                </div>
                <span className="text-xs font-medium">{t(item.destination.titleTranslationKey)}</span>
            </div>
        )}
    </NavLink>;
};

export default SidebarComponent;
