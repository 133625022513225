import { all, fork } from 'redux-saga/effects';
import apiStatusSagas from "./apiStatus/apiStatusSagas.ts";

// Root saga that aggregate all branches
export default function* rootSaga() {
    // call all sagas branches :
    yield all([
        fork(apiStatusSagas),
        // fork(featureBSaga), TODO
        // ...
    ]);
}
