import {Account} from "../generated/Account.ts";
import {ApiConfig, RequestParams} from "../generated/http-client.ts";
import {
    CreateUserDto,
    JwtTokenDto, LoginDto,
    NewSubscriptionInfoDto,
    RefreshDto,
    UserSingleEstablishmentDto,
} from "../generated/data-contracts.ts";
import {ICookieTokenConfig} from "../LoginDataHolder.ts";
import {IUserInfos} from "../../pages/signin/signInSlice.ts";

export interface IAccount {
    baseUrl: string;
    login(data: LoginDto, params?: RequestParams): Promise<JwtTokenDto>;

    logout(params?: RequestParams): Promise<void>;

    refreshToken(data: RefreshDto, params?: RequestParams): Promise<JwtTokenDto>;

    createUser(data: CreateUserDto, params?: RequestParams): Promise<JwtTokenDto>;

    createEstablishmentSubscription(data: NewSubscriptionInfoDto, params?: RequestParams): Promise<number>;

    getAllUsersByEstablishment(params?: RequestParams): Promise<UserSingleEstablishmentDto[]>;

    assignUserToEstablishment(query: { userIdToAdd: string }, params?: RequestParams): Promise<number>;

    userinfo(params?: RequestParams): Promise<IUserInfos>;

    updateEstablishmentSubscription(data: NewSubscriptionInfoDto, params?: RequestParams): Promise<number>;
}

/**
 * Wrapper of Account generated code
 */
export class WrapperAccount<SecurityDataType = unknown> implements IAccount {
    private readonly wrappedAccount: Account<SecurityDataType>;
    readonly baseUrl: string;
    readonly loginData: ICookieTokenConfig;

    constructor(loginData: ICookieTokenConfig, apiConfig: ApiConfig<SecurityDataType> = {}) {
        this.wrappedAccount = new Account(apiConfig);
        this.loginData = loginData;
        this.baseUrl = this.wrappedAccount.baseUrl;
    }

    login = async (data: LoginDto, params: RequestParams = {}): Promise<JwtTokenDto> =>
        this.wrappedAccount.loginCreate(data, this.loginData.setParamsOnLoginOnly(data.withHttpOnlyCookie, params)
        );

    createUser = async (data: CreateUserDto, params: RequestParams = {}): Promise<JwtTokenDto> =>
        this.wrappedAccount.createUserCreate(data, this.loginData.setParamsOnLoginOnly(data.withHttpOnlyCookie, params));

    logout = async (params: RequestParams = {}): Promise<void> =>
        this.wrappedAccount.logoutCreate(params);

    refreshToken = async (data: RefreshDto, params: RequestParams = {}): Promise<JwtTokenDto> =>
        this.wrappedAccount.refreshtokenCreate(data, params);

    createEstablishmentSubscription = async (data: NewSubscriptionInfoDto, params: RequestParams = {}): Promise<number> =>
        this.wrappedAccount.createEstablishmentSubscriptionCreate(data, this.loginData.withAuthentication(params));

    getAllUsersByEstablishment = async (params: RequestParams = {}): Promise<UserSingleEstablishmentDto[]> =>
        this.wrappedAccount.getAllUsersByEstablishmentList(this.loginData.withAuthentication(params));

    assignUserToEstablishment = async (query?: { userIdToAdd?: string }, params: RequestParams = {}): Promise<number> =>
        this.wrappedAccount.assignusertoestablishmentList(query, this.loginData.withAuthentication(params));

    userinfo = async (params: RequestParams = {}): Promise<IUserInfos> => {
        const res = await this.wrappedAccount.userinfoList(this.loginData.withAuthentication(params));
        return {
            userFirstName: res.firstName,
            userLastName: res.lastName,
            userColor: res.color,
        };
    };

    updateEstablishmentSubscription = async (data: NewSubscriptionInfoDto, params: RequestParams = {}): Promise<number> =>
        this.wrappedAccount.updateestablishmentsubscriptionList(data, params);
}
