import {FC} from "react";
import {TranslationFile, useTranslationFile} from "../../hooks/translations.ts";
import ButtonCore from "../../ui/controls/buttonCore.tsx";
import {COLOR_PANEL_KIND_PRIMARY_COLORS, COLOR_PANEL_KIND_SECONDARY_COLORS, COLOR_TAB_TEXT_100, COLOR_TAB_TEXT_AND_TEXT_HOVER, PanelKindProps} from "../../ui/colors/colorConfiguration.tsx";
import DivCore from "../../ui/controls/divCore.tsx";
import {CiSquarePlus} from "react-icons/ci";

const RelateSitesPart: FC<PanelKindProps> = ({panelKind}) => {
    const primaryColor = COLOR_PANEL_KIND_PRIMARY_COLORS[panelKind];
    const secondaryColor = COLOR_PANEL_KIND_SECONDARY_COLORS[panelKind];
    const primaryTextColor = COLOR_TAB_TEXT_100[primaryColor];
    const {t} = useTranslationFile(TranslationFile.Account);
    return (
        <DivCore className="mx-auto max-w-7xl px-0 @md:px-2 @2xl:px-6">
            <div className="px-4">
                <div className="sm:flex sm:items-center mt-16">
                    <div className="sm:flex-auto">
                        <h2 className={`text-base/7 font-semibold ${primaryTextColor}`}>
                            {t('associatedSites')}
                        </h2>
                        <p className="mt-1 text-sm text-theme-OnBgVariant dark:text-theme-OnBgVariant-dark font-medium">
                            {t('associatedSitesDescription')}
                        </p>
                    </div>
                    <ButtonCore
                        colorKind={secondaryColor}
                        className="flex flex-row">
                        <CiSquarePlus className="h-7 w-7"/>
                        <span className="hidden @2xl:block">{t('addNewRelatedSite')}</span>
                    </ButtonCore>
                </div>
                <div className="mt-8 flow-root ">
                    <div className="mx-0 overflow-x-auto ">
                        <div className="inline-block min-w-full py-2 align-middle overflow-hidden">
                            <table className="w-full divide-y divide-theme-OnBg/60 dark:divide-theme-OnBg-dark/60">
                                <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold pl-0">
                                        {t('siteName')}
                                    </th>
                                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold pl-0 hidden @2xl:table-cell">
                                        {t('address')}
                                    </th>
                                    <th scope="col" className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold">
                                        {t('city')}
                                    </th>
                                    <th scope="col" className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold hidden @lg:table-cell">
                                        {t('postalCode')}
                                    </th>
                                    <th scope="col" className="relative w-1/12 py-3.5 pl-3 pr-0">
                                        <span className="sr-only">{t('edit')}</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-theme-border dark:divide-theme-border-dark">
                                {sites.map((site) => (
                                    <tr key={site.name}>
                                        <td className="py-4 pr-3 text-sm font-medium pl-0">
                                            {site.name}
                                        </td>
                                        <td className="whitespace-nowrap overflow-hidden text-ellipsis px-3 py-4 text-sm hidden @2xl:table-cell">
                                            {site.address}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm">{site.city}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm hidden @lg:table-cell">{site.zipCode}</td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 text-right text-sm font-medium pr-0">
                                            <a href="#" className={`${COLOR_TAB_TEXT_AND_TEXT_HOVER[primaryColor]}`}>
                                                {t('edit')}<span className="sr-only">, {site.name}</span>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </DivCore>
    );
};

export default RelateSitesPart;

const sites = [
    {
        name: "Clinique de Test",
        address: "Place de la mairie",
        city: "Prémery",
        zipCode: "58700"
    },
    {
        name: "Clinique des prunes",
        address: "Lieux dit, les grand noyers",
        city: "Charnoy",
        zipCode: "87250"
    },
    // TODO Load from server
];

