import {FC} from "react";
import {TranslationFile, useTranslationFile} from "../../../hooks/translations.ts";
import {CalendarDisplayMode} from "./CalendarDisplayMode.tsx";
import {DateAndTimeHelper} from "../../../lib/helpers/DateAndTimeHelper.ts";
import {HiChevronLeft, HiChevronRight, HiPlus} from "react-icons/hi2";
import CalendarModeSelector from "./CalendarModeSelector.tsx";
import ButtonCore from "../../../ui/controls/buttonCore.tsx";
import {COLOR_PANEL_KIND_SECONDARY_COLORS} from "../../../ui/colors/colorConfiguration.tsx";
import {PanelKind} from "../../../ui/tab_panels/panelKind.ts";
import {useDispatch} from "react-redux";
import {focusOrCreateSecondaryTab} from "../../../ui/tab_panels/panelSlice.ts";
import {Destinations} from "../../../ui/navigation/destinations.ts";

interface CalendarHeaderBarProps {
    currentLocale: string;
    selectedDay: Date,
    calendarDisplayMode: CalendarDisplayMode;
    onSelectedModeChanged: (newSelectedMode: CalendarDisplayMode) => void,
    panelKind: PanelKind;
}

const CalendarHeaderBar: FC<CalendarHeaderBarProps> = (
    {
        selectedDay,
        currentLocale,
        calendarDisplayMode,
        onSelectedModeChanged,
        panelKind,
    }) => {
    const secondaryColor = COLOR_PANEL_KIND_SECONDARY_COLORS[panelKind];
    const {t} = useTranslationFile(TranslationFile.Calendar);
    const dispatch = useDispatch();

    return (
        <header className="grid grid-cols-1 @xl:flex items-center justify-between
        border-b border-theme-border dark:border-theme-border-dark px-6 pb-2">
            <div className="flex @4xl:grid @7xl:flex grid-cols-1 items-center gap-x-4 mb-4 @4xl:mb-0">
                <h1 className="text-base font-semibold col-span-1 @7xl:pb-0">
                    <time
                        dateTime={`${DateAndTimeHelper.getIsoDateMonth(selectedDay)}`}>
                        {/* January 2028 */
                            DateAndTimeHelper.toFirstLetterInUpperCase(
                                DateAndTimeHelper
                                    .getFormattedMonthYear(selectedDay, currentLocale)
                            )
                        }
                    </time>
                </h1>
                <div className="col-span-1">
                    <TodayAndNextPreviousButton/>
                </div>
            </div>
            <div className="flex flex-1 items-center justify-end">
                <div className="ml-2 @4xl:ml-4 flex items-center">

                    {/* CALENDAR SELECTION MODE (WEEKLY/DAILY/MONTHLY) */}
                    <CalendarModeSelector
                        color={secondaryColor}
                        onSelectedModeChanged={onSelectedModeChanged}
                        selectedMode={calendarDisplayMode}/>

                    <ButtonCore
                        className="flex items-center ml-2 @5xl:ml-4"
                        colorKind={secondaryColor}
                        onClick={() =>
                            dispatch(focusOrCreateSecondaryTab(Destinations.NewAppointment()))}>
                        <HiPlus className="h-6 w-6 @4xl:w-5 @4xl:h-5"/>
                        <span className="hidden @4xl:block">
                        {t("addAppointment")}
                        </span>
                    </ButtonCore>

                </div>
            </div>
        </header>
    );
};


const TodayAndNextPreviousButton: FC = () => {
    const {t} = useTranslationFile(TranslationFile.Calendar);
    return (
        <div className="relative flex rounded-md shadow-sm items-stretch">
            <button
                type="button"
                className="flex h-9 items-center justify-center rounded-l-md border-y border-l border-theme-border dark:border-theme-border-dark
                             pr-1focus:relative w-9 pr-0"
            >
                <span className="sr-only">{t("previousWeek")}</span>
                <HiChevronLeft className="h-5 w-5" aria-hidden="true"/>
            </button>
            <button
                type="button"
                className="border-y border-theme-border dark:border-theme-border-dark px-3.5 text-sm font-semibold focus:relative"
            >
                {t("today")}
            </button>
            <button
                type="button"
                className="flex h-9 items-center justify-center rounded-r-md border-y border-r border-theme-border dark:border-theme-border-dark focus:relative w-9 pl-0 "
            >
                <span className="sr-only">{t("nextWeek")}</span>
                <HiChevronRight className="h-5 w-5" aria-hidden="true"/>
            </button>
        </div>);
};
export default CalendarHeaderBar;
