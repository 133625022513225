import { call, put, takeEvery } from 'redux-saga/effects';
import {ensureBackendReachableAndRetrieveProxyServer} from "../../api/ApiStatusAccessor.ts";
import {handleTypedErrors} from "../../lib/catchErrorHelper.ts";
import {pingBackendFailure, pingBackendSuccess} from "./apiStatusSlice.ts";
import {createAction} from "@reduxjs/toolkit";

// Action creators
export const pingServer = createAction('PING_BACKEND');

// Saga
function* pingBackendSaga() {
    try {
        const response : boolean  = yield call(ensureBackendReachableAndRetrieveProxyServer);
        if (response) {
            yield put(pingBackendSuccess());
        } else {
            yield put(pingBackendFailure('Backend is not responding'));
        }
    } catch (error) {
        const typedError = handleTypedErrors(error);
        yield put(pingBackendFailure(typedError.toString()));
    }
}

export default function* apiStatusSagas() {
    yield takeEvery(pingServer, pingBackendSaga);
}
