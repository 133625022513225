/* eslint-disable no-unused-vars */
/**
 * All navigation key
 */
export enum NavigationKey {
    Planning = "planning",
    CustomersDashboard = "customers",
    Hospitalization = "hospitalization",
    Products = "products",
    Financial = "financial",
    Statistics = "statistics",
    Help = "help",
    Settings = "settings",
    Account = "account",
    Appointment = "appointment",
    WaitingRoom = "waiting_room",
    AnimalRecord = "animal_record",
    CustomerRecord = "customer_record",
}
/* eslint-enable no-unused-vars */
