import {defaultFallBackLocale} from "../navigationLocalized.ts";
import {Time} from "../TypeUtils.ts";

export  class DateAndTimeHelper {
    /**
     * Returns the ISO 8601 formatted date string (YYYY-MM) for a given Date object.
     *
     * @param date - The Date object to format (optional).
     * @returns {string} - The ISO 8601 formatted string in "YYYY-MM" format.
     */
    public static getIsoDateMonth(date: Date): string {
        return date.toISOString().slice(0, 7);
    }

    /**
     * Returns the formatted date string in "Month Year" format based on the provided locale.
     *
     * @param date - The Date object to format.
     * @param locale - The locale string for formatting (e.g., 'fr-FR' for French, 'en-US' for English).
     * @returns {string} - The date formatted as "Month Year" (e.g., "January 2022" or "janvier 2022").
     */
    public static getFormattedMonthYear(date: Date, locale: string = defaultFallBackLocale): string {
        return date.toLocaleDateString(locale, { year: 'numeric', month: 'long' });
    }

    /**
     * Capitalizes the first letter of the provided string.
     *
     * @param text - The string to capitalize.
     * @returns {string} - The string with the first letter in uppercase.
     */
    public static toFirstLetterInUpperCase(text: string): string {
        return text && text[0].toUpperCase() + text.slice(1);
    }

    /**
     * Return the time formated in 09:56, 17:53 or 02:56 PM if use24hFormat is false
     * @param time
     * @param use24hFormat if we want the hour in 24h format or with AM/PM (default to 24h)
     */
    public static displayTime(time: Time, use24hFormat : boolean = true): string {
        const formattedMinutes = time.minutes.toString().padStart(2, '0');

        if (!use24hFormat) {
            let hours = time.hours;
            const period = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12; // Convertit 0h à 12h et 13h-23h au format 1-11h
            const formattedHours = hours.toString().padStart(2, '0');
            return `${formattedHours}:${formattedMinutes} ${period}`;
        } else {
            const formattedHours = time.hours.toString().padStart(2, '0');
            return `${formattedHours}:${formattedMinutes}`;
        }
    }

    /**
     * Returns true if the selected country code use a 24h time format
     * @param countryCode
     */
    public static uses24HourFormat(countryCode: string): boolean {
        try {
            const options: Intl.DateTimeFormatOptions = { hour: 'numeric', hour12: undefined };
            const locale = new Intl.DateTimeFormat(countryCode, options).resolvedOptions();
            return !locale.hour12; // If hour12 is false or undefined, it is 24 hours format
        } catch {
            return true; // default to 24h
        }
    }
}
