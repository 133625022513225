import React, {lazy, Suspense} from "react";
import DivCore from "../../../ui/controls/divCore.tsx";
import {PanelKind} from "../../../ui/tab_panels/panelKind.ts";
import {IAppointment} from "./AppointmentCalendar.ts";
import {TranslationFile, useTranslationFile} from "../../../hooks/translations.ts";
import {CalendarDisplayMode} from "./CalendarDisplayMode.tsx";
import CalendarHeaderBar from "./CalendarHeaderBar.tsx";

export interface IMainCalendarProps {
    currentLocale: string;
    today: Date;
    selectedDay: Date;
    appointments: IAppointment[];
    calendarDisplayMode: CalendarDisplayMode;
    onSelectedModeChanged: (newMode: CalendarDisplayMode) => void;
    panelKind: PanelKind;
}

const MainCalendar: React.FC<IMainCalendarProps> = (calendarProps) => {
    return (
        <DivCore className="flex h-full flex-col">
            <CalendarHeaderBar
                calendarDisplayMode={calendarProps.calendarDisplayMode}
                currentLocale={calendarProps.currentLocale}
                onSelectedModeChanged={calendarProps.onSelectedModeChanged}
                panelKind={calendarProps.panelKind}
                selectedDay={calendarProps.selectedDay}/>
            <LazyLoadCalendarFromSelectedMode  {...calendarProps}/>
        </DivCore>
    );
};

const MonthlyCalendar = lazy(() => import("./parts/MonthlyCalendar.tsx"));
const DailyCalendar = lazy(() => import("./parts/DailyCalendar.tsx"));
const WeeklyCalendar = lazy(() => import("./parts/WeeklyCalendar.tsx"));

/**
 * Do a lazy loading of calendars depending on selected mode
 * @param calendarProps
 * @constructor
 */
const LazyLoadCalendarFromSelectedMode: React.FC<IMainCalendarProps> = (calendarProps) => {
    const {t} = useTranslationFile(TranslationFile.Calendar);
    switch (calendarProps.calendarDisplayMode) {
        case CalendarDisplayMode.Daily:
            return (
                <Suspense fallback={
                    <div className="w-full h-full flex items-center justify-center opacity-70">
                        {t("dailyCalendarLoading")}
                    </div>
                }>
                    <DailyCalendar {...calendarProps} />
                </Suspense>
            );
        case CalendarDisplayMode.Weekly:
            return (
                <Suspense fallback={
                    <div className="w-full h-full flex items-center justify-center opacity-70">
                        {t("weeklyCalendarLoading")}
                    </div>
                }>
                    <WeeklyCalendar {...calendarProps} />
                </Suspense>
            );
        case CalendarDisplayMode.Monthly:
            return (
                <Suspense fallback={
                    <div className="w-full h-full flex items-center justify-center opacity-70">
                        {t("monthlyCalendarLoading")}
                    </div>
                }>
                    <MonthlyCalendar {...calendarProps} />
                </Suspense>
            );
        default:
            throw Error(`All calendarMode should exist : mode ${calendarProps.calendarDisplayMode} should be handled in CalendarFromSelectedMode function`);

    }
};

export default MainCalendar;
