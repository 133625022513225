import {Middleware} from "@reduxjs/toolkit";
import {removeTab} from "../panelSlice.ts";
import {RootState} from "../../../stores/rootState.ts";
import {TabMiddlewareHelpers} from "./tabMiddlewareHelpers.ts";

/**
 * The middleware track some tab operation to clean data:
 *  1. When a remove tab is requested, it cleans this id in each slice that are registered in
 *  2. When the active left tab is navigated, it cleans the previous tab slice:
 *          if leaving the planning we should clean planning data to avoid loading planning previous data
 * @param store
 */
const tabNotifierMiddleware: Middleware<unknown, RootState> = (store) => (next) => (action) => {
    const previousState: RootState = store.getState();
    const result = next(action);
    const nextState: RootState = store.getState();

    // 1. If it is a remove Tab, notify every other registered slice that they should be cleaned from the remove id
    if (removeTab.match(action)) {
        const tabId = action.payload;

        // search either in right or left tabs
        const previousTab =
            previousState.panels.leftPartTabs.find(o => o.id == tabId) ||
            previousState.panels.rightPartTabs.find(o => o.id == tabId);

        TabMiddlewareHelpers.RemoveMatchingSliceFromStore(store.dispatch, previousTab)


    }
    // 2. when the active left tab is navigated, remove previous data:
    TabMiddlewareHelpers.CleanPreviouslyNavigatedLeftTabIfNeeded(previousState, nextState, store.dispatch)

    return result;
};

export default tabNotifierMiddleware;
