import { FC } from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";

interface WarningOperationAlertProps {
    isOpen: boolean;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const WarningConfirmationAlert: FC<WarningOperationAlertProps> = ({ isOpen, message, onConfirm, onCancel }) => {


    return (
        <Dialog open={isOpen} onClose={onCancel} className="relative z-50">
            <DialogBackdrop
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />

            <div className="fixed inset-0 z-10 flex items-center justify-center p-4">
                <DialogPanel
                    className="relative bg-white rounded-lg shadow-xl p-6 text-left max-w-lg w-full"
                >
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                            <HiOutlineExclamationTriangle className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                Attention
                            </DialogTitle>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    {message}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={onConfirm}
                        >
                            Valider
                        </button>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-gray-700 bg-white shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={onCancel}
                        >
                            Annuler
                        </button>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    );
};

export default WarningConfirmationAlert;
